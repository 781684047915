import { Popover, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ILogoutDownArrow, ILogout, IProfileImageSmall } from "../../../../assets";

import useIsAuthenticated from "../../../../hooks/useIsAuthenticated";
import { signoutAction } from "../../../../store/actions";
import { authSelector } from "../../../../store/slice/auth.slice";
import { COLOR_VARIANT, DEVICE } from "../../../../utilities/constants";
import { CountryIcon, LogoutContainer, LogoutDownArrow, LogoutIcon, LogoutOptionsBox, LogoutProfileIcon, ProfileContainer, ProfileContainerPopover, StyledPopover, StyledTypography, UserDetails, UserName, UserNumber } from "./style.components";

const ProfileDropdown = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const { selectedCountry } = useSelector(authSelector);
  const state = useSelector(authSelector);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated, navigate]);

  const handleLogout = async () => {
    handleClose();
    await dispatch(signoutAction());
    localStorage.clear();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <UserDetails onClick={handleClick}>
        <CountryIcon
          src={selectedCountry?.icon}
          alt="selected_country"
        />
          <LogoutContainer>
            <LogoutProfileIcon
              src={IProfileImageSmall}
              alt="down-arrow"
            />
            <ProfileContainer>
              <UserName>{state?.userDetails?.first_name}</UserName>
              <UserNumber>{state?.userDetails?.phone_number}</UserNumber>
            </ProfileContainer>
            <LogoutDownArrow
              src={ILogoutDownArrow}
              alt="down-arrow"
            />
          </LogoutContainer>
      </UserDetails>
      <StyledPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <LogoutOptionsBox
          onClick={handleLogout}
        >
          <ProfileContainerPopover>
            <UserName>{state?.userDetails?.first_name}</UserName>
            <UserNumber>{state?.userDetails?.phone_number}</UserNumber>
          </ProfileContainerPopover>
          <StyledTypography>
            <LogoutIcon src={ILogout} alt="Logout Icon" />
            Logout / Change Country
          </StyledTypography>
        </LogoutOptionsBox>
      </StyledPopover>
    </>
  );
};

export default ProfileDropdown;