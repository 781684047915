import styled from "styled-components";
import { Modal } from '@mui/material';
import { TextField, Typography, FormControl } from "@mui/material";

export const ModalHeader = styled(Typography)`
  color: #0099CC;
  font-size: 24px !important;
  margin: 28px 49px 0 49px !important;
`;
export const ModalBody = styled.div`
  margin: 25px;
  margin-bottom: 0;
  position: relative;
  flex: 1;
  overflow-y: auto;
`;
export const EmptyListMessage = styled.div`
  margin-top: 10px;
  padding: 20px;
  color: red;
  text-align: center;
`;
export const ListWrapper = styled.div``;
export const ListSection = styled.div`
  height: 50px;
  background-color: #ebf7fb;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
`;
export const ListDateTitle = styled.div`
  display: flex;
  flex: 0.4;
  font-weight: bold;
  color: #0099cc;
  font-size: 16px;
`;
export const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ebf7fb;
  padding-left: 20px;

  &:last-child {
    border: none;
  }
`;
export const ListItem = styled.div`
  display: flex;
  flex: 0.2;
  font-size: 14px;
  color: #808080;
  flex: 0.2;

  &:first-child {
    flex: 0.4;
    align-items: center;
  }

  &:nth-child(4),
  &:last-child {
    justify-content: center;
  }
`;
export const ListItemBold = styled.span`
  font-weight: 600;
`;
export const ArrowImage = styled.img``;
export const StyledCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ffffff;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1000;
  padding: 5px;
`;
export const CloseButton = styled.img`
  cursor: pointer;
  display: block;
  max-width: 100%;
`;
export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
`;
export const SaveButton = styled.button`
  background-color: #7aba56;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
  padding: 11px 56px;
  margin: 18px 25px;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ebf7fb;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
`;
export const CheckboxLabel = styled.label`
  display: inline-block;
  position: relative;
  padding-left: 30px; 
  cursor: pointer;
  user-select: none; 
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;

export const StyledBox = styled.span`
  position: relative;
  display: inline-block; 
  height: 20px;
  width: 20px;
  background-color: #D7EFF7;
  border: 1px solid #0099CC;
  border-radius: 5px;

  &::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 10px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
    display: none;
  }

  ${HiddenCheckbox}:checked + & {
    background-color: #0099CC;
    &::before {
      display: block;
    }
  }
`;

export const LoaderWrapper = styled.div`
  margin: 15px;
`;
export const ThumbImage = styled.img`
  width: 82px;
  height: 82px;
  object-fit: cover;
`;
export const ThumbImageWrapper = styled.div`
  width: 82px;
  height: 82px;
  border: 1px solid #D7EFF7;
  border-radius: 5px;
  opacity: 1;
  margin-right: 10px;
  margin-left: 10px;
  padding: 10px;
`;
