import { createSlice } from "@reduxjs/toolkit";
import {
  generateDocAction,
} from "../actions";

const initialState = {
  isLoading: null,
  document: null,
};

const generateDocSlice = createSlice({
  name: "generate-doc",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    //generate document
    builder.addCase(generateDocAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(generateDocAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.document = new Blob([payload], { type: 'application/pdf' });
    });   
    builder.addCase(generateDocAction.rejected, (state) => {
      state.isLoading = false;
      state.document = null;
    });
  },
});

export const generateDocSelector = (state) => state.generateDoc.document;
export default generateDocSlice.reducer;
