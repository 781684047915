import { createSlice } from "@reduxjs/toolkit";
import {
  getDicomFileAction
} from "../actions";

const initialState = {
  isLoading: null,
  dicom: null,
};

const dicomFileSlice = createSlice({
  name: "dicom-file",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    //get consultations
    builder.addCase(getDicomFileAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDicomFileAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.dicom = payload?.result;
    });
    builder.addCase(getDicomFileAction.rejected, (state) => {
      state.isLoading = false;
      state.dicom = null;
    });
  },
});

export const dicomFileSelector = (state) => state.dicomFile.dicom;
export default dicomFileSlice.reducer;
