import { API_ENDPOINT } from "../utilities/constants";
import { API_URL } from "../utilities/constants";
import { apiClient } from "./api-client";

const callHemeBot = async (payload) => {
  try {
    const url = `${API_URL}/chatbot/chat`;
    const response = await apiClient.post(url, payload, false);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export {
  callHemeBot,
};
