import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getFormTypes, getFormTemplate, sendForm, getFormFilled
} from "../../api";
import { setShowStatus, unsetShowStatus } from "../slice/status-slice";

export const getFormTypesAction = createAsyncThunk(
  "get-form-types",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching form types",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getFormTypes();
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching form types",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while fetching form types"
      );
    }
  }
);

export const getFormTemplateAction = createAsyncThunk(
  "get-form-template",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching form template",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getFormTemplate(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching form template",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while fetching form template"
      );
    }
  }
);

export const sendFormAction = createAsyncThunk(
  "send-form",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Sending form...",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await sendForm(data);
      thunkAPI.dispatch(
        setShowStatus({
          message: "Form sent successfully.",
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while sending form",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while sending form");
    }
  }
);

export const getFormFilledAction = createAsyncThunk(
  "get-form-filled",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching filled form",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getFormFilled(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching form",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while fetching form"
      );
    }
  }
);