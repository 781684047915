import { createSlice } from "@reduxjs/toolkit";
import {
  getNotesAudioAction
} from "../actions";

const initialState = {
  isLoading: null,
  getNotesResponse: null,
};

const getNotesAudioSlice = createSlice({
  name: "get-notes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getNotesAudioAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getNotesAudioAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.getNotesResponse = payload;
    });
    builder.addCase(getNotesAudioAction.rejected, (state) => {
      state.isLoading = false;
      state.getNotesResponse = null;
    });
  },
});

export const getNotesAudioSelector = (state) => state?.getNotesObject?.getNotesResponse;
export default getNotesAudioSlice.reducer;
