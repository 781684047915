import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import * as Styled from "./style.components";
import { getFormTypesAction, getObservationTypesAction } from "../../../../store/actions";
import { 
    IBackArrow, INextArrow
  } from "../../../../assets";
import { patientSelector } from "../../../../store/slice/patient.slice";
import { addReminderAction } from "../../../../store/actions";
import { ShimmerPostDetails } from "react-shimmer-effects";
import { MenuItem } from '@mui/material';
import { SnackBar } from "../../../../components/shared";
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FormGroup, FormControlLabel, Checkbox, RadioGroup, Radio, TextField } from '@mui/material';

const formatDate = (date) => {
  // Create a new Date object from the date input
  const newDate = new Date(date);

  // Adjust the date for the timezone offset
  newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());

  // Return the ISO string split at 'T' to get the date part
  return newDate.toISOString().split('T')[0];
};

const formatTime = (time) => {
    const date = new Date(time);
    return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;
};

const AddReminder = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedPatientId } = useSelector(patientSelector);
  const [selectedObservation, setSelectedObservation] = useState({});
  const [observationTypesArray, setObservationTypesArray] = useState([{}]);
  const [reminder, setReminder] = useState({
    sugarLevelTiming: 'Before Meal',
    sugarLevelFrequency: 'Daily'
  });
  const [reminderTimes, setReminderTimes] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [customDates, setCustomDates] = useState(['']);
  const [isLoading, setIsLoading] = useState(false);
  const [isReminderAdded, setIsReminderAdded] = useState(false);
  const [formTypesArray, setFormTypesArray] = useState([{}]);
  const [selectedFormType, setSelectedFormType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    fetchObservationTypes();
  }, [dispatch]);

  useEffect(() => {
    fetchFormTypes();
  },[dispatch]);

  const fetchFormTypes = async () => {
    setIsLoading(true);
    try {
        const response = await dispatch(getFormTypesAction()).unwrap();
        const newFormTypes = response?.result;
        setFormTypesArray(newFormTypes);
    } catch (error) {
        console.error("Failed to fetch form types:", error);
    } finally {
        setIsLoading(false);
    }
  };

  if (isLoading) {
      return <Styled.Container><ShimmerPostDetails card cta variant="SIMPLE" /></Styled.Container>;
  }

  const isValidForm = () => {
    const hasBaseFields = reminder.description && reminder.startDate && reminder.endDate;
    let hasScheduleDetails = false;

    if (reminder.sugarLevelFrequency.toLowerCase() === 'weekly') {
      hasScheduleDetails = reminderTimes.length > 0 && selectedDays.length > 0;
    } else if (reminder.sugarLevelFrequency.toLowerCase() === 'custom') {
      hasScheduleDetails = reminderTimes.length > 0 && customDates.every(date => date);
    } else {
      hasScheduleDetails = reminderTimes.length > 0;
    }

    return hasBaseFields && hasScheduleDetails;
  };

  const fetchObservationTypes = async () => {
    setIsLoading(true);
    try {
        const response = await dispatch(getObservationTypesAction()).unwrap();
        const newObservationTypes = response?.result;
        setObservationTypesArray(newObservationTypes);
        const painLevelObservation = newObservationTypes.find(obs => obs.name === 'Photo');
        if (painLevelObservation) {
            setSelectedObservation(painLevelObservation);
        }
    } catch (error) {
        console.error("Failed to fetch observation types:", error);
    } finally {
        setIsLoading(false);
    }
  };
  
  const handleObservationChange = (observation) => {
    setSelectedObservation(observation);
    if (observation?.name === 'Blood Pressure') {
      setReminder({ ...reminder, sugarLevelTiming: '' });
    }
  };

  if (isLoading) {
    return <ShimmerPostDetails card cta variant="SIMPLE" />;
  }

  if (isReminderAdded) {
    return <Styled.Container><ShimmerPostDetails card cta variant="SIMPLE" /></Styled.Container>;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setReminder({ ...reminder, [name]: value });
};

  const handleTimeChange = (time, index) => {
    // Create a new array by copying existing times
    const updatedTimes = [...reminderTimes];
    // Update the time at the specific index
    updatedTimes[index] = time;
    // Set the updated array back to state
    setReminderTimes(updatedTimes);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsReminderAdded(true);

      // Building the schedules object based on the selected frequency
      let schedules = {};
      if (reminder.sugarLevelFrequency.toLowerCase() === 'weekly') {
        schedules = {
          times: reminderTimes.map(time => formatTime(time)),
          days: selectedDays
        };
      } else if (reminder.sugarLevelFrequency.toLowerCase() === 'custom') {
        schedules = {
          times: reminderTimes.map(time => formatTime(time)),
          custom_dates: customDates.map(date => formatDate(date))
        };
      } else {
        schedules = {
          times: reminderTimes.map(time => formatTime(time))
        };
      }

      let payload = {
        "patient_id": selectedPatientId,
        "description": reminder.description,
        "reminder_type": selectedObservation?.record_type,
        "reminder_type_display_name": selectedObservation?.name,
        "schedule_config": {
            "start_date": formatDate(reminder.startDate),
            "end_date": formatDate(reminder.endDate),
            "frequency": reminder.sugarLevelFrequency.toLowerCase(),
            "schedules": schedules
        }
      };

      // Additional fields for specific types of reminders
      if (selectedObservation?.name === 'Questionnaire') {
        payload.reminder_resource = {
          "resource_key": selectedFormType,
          "resource_type": "FHIR",
          "resource_name": "questionnaire",
          "resource_display_name": "Questionnaire"
        };
      } else if (selectedObservation?.name === 'Blood Sugar') {
        payload.reminder_sub_type = reminder.sugarLevelTiming === 'Before Meal' ? 'pre_meal' : 'post_meal';
      }
  
      const actionResult = await dispatch(addReminderAction(payload));
      const response = actionResult.payload;
      if (response && response.status === "success") {
        setTimeout(() => {
          navigate("/patient-reminders");
        }, 500);
      } else {
        console.error("Failed to add reminder:", actionResult.error);
      }
    } catch (error) {
      console.error("Failed to add reminder:", error);
    } finally {
      setIsReminderAdded(false);
    }
  };
  
  const toggleDay = (day) => {
    setSelectedDays(prevDays => 
      prevDays.includes(day) ? prevDays.filter(d => d !== day) : [...prevDays, day]
    );
  };

  const addMoreDates = () => {
    if (customDates.length < 10) {
      setCustomDates([...customDates, '']);
    } else {
      alert("You can add up to 10 dates only.");
    }
  };
  
  const handleCustomDateChange = (newValue, index) => {
    const newDates = [...customDates];
    newDates[index] = newValue;
    setCustomDates(newDates);
  };

  const handleStartDateChange = (newValue) => {
    setReminder({ ...reminder, startDate: newValue });
    setStartDate(newValue); // Ensure this is a Date object
  };

  const handleEndDateChange = (newValue) => {
    setReminder({ ...reminder, endDate: newValue });
    setEndDate(newValue); // Ensure this is a Date object
  };
  
    return (
      <Styled.Container>
        <Styled.TopHeader>
          <Styled.ArrowBackImage 
            src={IBackArrow} 
            alt="back-arrow" 
            onClick={() => navigate(-1)}
          />
          Add Reminder
        </Styled.TopHeader>
          <Styled.ObservationContainer>
                <Styled.ObservationSelector>
                    {observationTypesArray.map((observation, index) => (
                        <Styled.ObservationTile
                            key={observation?.id}
                            isSelected={selectedObservation?.name === observation?.name}
                            onClick={() => handleObservationChange(observation)}>
                            <Styled.ObservationDetail>
                                <Styled.ObservationThumb src={observation.icon}></Styled.ObservationThumb>
                                <Styled.ObservationName>{observation.name}</Styled.ObservationName>
                            </Styled.ObservationDetail>
                            <Styled.NextArrowIcon src={INextArrow}></Styled.NextArrowIcon>
                        </Styled.ObservationTile>
                    ))}
                </Styled.ObservationSelector>

                <Styled.ObservationEntry>
                    <Styled.ObservationInfo>
                        <Styled.ObservationLogoSmall src={selectedObservation.icon}></Styled.ObservationLogoSmall>
                        <Styled.ObservationNameSmall>{selectedObservation.name}</Styled.ObservationNameSmall>
                    </Styled.ObservationInfo>
                    <Styled.Form onSubmit={handleSubmit}>   
                  {selectedObservation?.name === 'Blood Sugar' && (
                  <Styled.SugarLevelFor>
                    <Styled.SugarLevelForText>
                        Sugar Level For
                    </Styled.SugarLevelForText>
                      <Styled.RadioMainContainer>
                      <RadioGroup
                        name="sugarLevelTiming"
                        value={reminder.sugarLevelTiming}
                        onChange={handleInputChange}
                        row
                      >
                        <FormControlLabel value="Before Meal" control={<Radio />} label="Before Meal" />
                        <FormControlLabel value="After Meal" control={<Radio />} label="After Meal" />
                      </RadioGroup>
                      </Styled.RadioMainContainer>
                  </Styled.SugarLevelFor>
                )}

                {selectedObservation?.name === 'Questionnaire' && (
                  <Styled.SugarLevelFor>
                  <Styled.SugarLevelForText>
                    Select form type
                  </Styled.SugarLevelForText>
                    <Styled.CustomFormControl variant="outlined">
                      <Styled.CustomSelect
                        labelId="form-type-select-label"
                        id="form-type-select"
                        value={selectedFormType}
                        onChange={(e) => setSelectedFormType(e.target.value)}
                        input={<Styled.CustomOutlinedInput label="Select Form Type" />}
                      >
                        <MenuItem value="" disabled>
                          Select Type
                        </MenuItem>
                        {formTypesArray.map((formType) => (
                          <MenuItem key={formType.id} value={formType.id}>
                            {formType.title}
                          </MenuItem>
                        ))}
                      </Styled.CustomSelect>
                    </Styled.CustomFormControl>
                  </Styled.SugarLevelFor>
                )}

                <Styled.DateSelection>
                    <Styled.SelectDateText>
                            Select Start and End Dates
                    </Styled.SelectDateText>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <span style={{ marginRight: '16px' }}>
                      <DatePicker
                        label="From"
                        value={startDate}
                        onChange={handleStartDateChange}
                        renderInput={(params) => <TextField {...params} />}
                        inputFormat="DD/MM/YYYY"
                        clearable
                      />
                      </span>
                      <DatePicker
                        label="To"
                        value={endDate}
                        onChange={handleEndDateChange}
                        renderInput={(params) => <TextField {...params} />}
                        inputFormat="DD/MM/YYYY"
                        clearable
                      />
                    </LocalizationProvider>
                </Styled.DateSelection>
          
                  {/* Frequency Selection */}
                  <Styled.FrequencyText>Frequency</Styled.FrequencyText>
                    <Styled.RadioMainContainer>
                        <Styled.RadioButtonContainer 
                          $isSelected={reminder.sugarLevelFrequency === 'Daily'}
                        >
                          <Styled.RadioButtonLabel>
                            <Styled.RadioButton
                              id="daily"
                              type="radio"
                              name="sugarLevelFrequency"
                              value="Daily"
                              checked={reminder.sugarLevelFrequency === 'Daily'}
                              onChange={handleInputChange}
                            />
                            Daily
                          </Styled.RadioButtonLabel>
                        </Styled.RadioButtonContainer>
                        <Styled.RadioButtonContainer 
                          $isSelected={reminder.sugarLevelFrequency === 'Weekly'}
                        >
                          <Styled.RadioButtonLabel>
                            <Styled.RadioButton
                              id="weekly"
                              type="radio"
                              name="sugarLevelFrequency"
                              value="Weekly"
                              checked={reminder.sugarLevelFrequency === 'Weekly'}
                              onChange={handleInputChange}
                            />
                            Weekly
                          </Styled.RadioButtonLabel>
                        </Styled.RadioButtonContainer>
                        <Styled.RadioButtonContainer 
                          $isSelected={reminder.sugarLevelFrequency === 'Custom'}
                        >
                          <Styled.RadioButtonLabel>
                            <Styled.RadioButton
                              id="custom"
                              type="radio"
                              name="sugarLevelFrequency"
                              value="Custom"
                              checked={reminder.sugarLevelFrequency === 'Custom'}
                              onChange={handleInputChange}
                            />
                            Custom
                          </Styled.RadioButtonLabel>
                        </Styled.RadioButtonContainer>
                    </Styled.RadioMainContainer>

                    {reminder.sugarLevelFrequency === 'Weekly' && (
                      <>
                      <Styled.SelectTimeText>Patient will receive reminders on below days at selected times.</Styled.SelectTimeText>
                      <Styled.DaySelection>
                        <Styled.SelectDayText>Select Days</Styled.SelectDayText>
                        <FormGroup row>
                        {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedDays.includes(day.toLowerCase())}
                                onChange={() => toggleDay(day.toLowerCase())}
                              />
                            }
                            label={day}
                          />
                        ))}
                      </FormGroup>
                      </Styled.DaySelection>
                      </>
                    )}

                    {reminder.sugarLevelFrequency === 'Custom' && (
                      <>
                      <Styled.SelectTimeText>Patient will receive reminders on below days at selected times.</Styled.SelectTimeText>
                      <Styled.CustomContainer>
                        <Styled.SelectDayText>Select Dates</Styled.SelectDayText>
                        <Styled.CustomDateSelection>
                          {customDates.map((date, index) => (
                            <Styled.CustomDateInputWrapper key={index}>
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                  label={`Date ${index + 1}`}
                                  value={date}
                                  onChange={(newValue) => handleCustomDateChange(newValue, index)}
                                  renderInput={(params) => <TextField {...params} />}
                                  minDate={startDate}
                                  maxDate={endDate}
                                />
                              </LocalizationProvider>
                            </Styled.CustomDateInputWrapper>
                          ))}
                          <Styled.AddMoreText onClick={addMoreDates}>Add More</Styled.AddMoreText>
                        </Styled.CustomDateSelection>
                      </Styled.CustomContainer>
                      </>
                    )}
          
                    {reminder.sugarLevelFrequency === 'Daily' && (
                      <Styled.SelectTimeText>Patient will receive reminders on everyday at selected times.</Styled.SelectTimeText>
                    ) }
                    <Styled.TimeContainer>
                      <Styled.TimeText>Select Time</Styled.TimeText>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <TimePicker
                              label="Select Time"
                              value={reminderTimes[0] || null} // Ensure there's a default value if undefined
                              onChange={(newValue) => handleTimeChange(newValue, 0)} // Assuming you're updating the first time slot
                              renderInput={(params) => <TextField {...params} />}
                          />
                      </LocalizationProvider>
                    </Styled.TimeContainer>
            
                    {/* Description */}
                    <Styled.DescriptionText>Description</Styled.DescriptionText>
                    <Styled.DescriptionInput
                      type="text"
                      name="description"
                      onChange={handleInputChange}
                    />
            
                    {/* Submit Button */}
                    <Styled.SubmitButton type="submit" disabled={!isValidForm()}>
                      Add Reminder
                    </Styled.SubmitButton>
                  </Styled.Form>
                </Styled.ObservationEntry>
            </Styled.ObservationContainer>
          <SnackBar />
        </Styled.Container>
    );
};

export default AddReminder;