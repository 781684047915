import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import * as Styled from "./style.components";
import { 
    IBackArrow, INextArrow
} from "../../../assets";
import { ShimmerPostDetails } from "react-shimmer-effects";
import { getFormTypesAction } from "../../../store/actions";
import { SnackBar } from "../../../components/shared";

const ObservationQuestionnaireType = ({ onSelectFormType }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [formTypesArray, setFormTypesArray] = useState([]);

    const tempform = [
        {
            "id": "1fb5b296-4df5-47d8-ba9e-4642b23a7565",
            "title": "STEADI - Older Adult Fall Prevention"
        }
    ]

    useEffect(() => {
        fetchFormTypes();
    },[dispatch]);

    const fetchFormTypes = async () => {
        setIsLoading(true);
        try {
            const response = await dispatch(getFormTypesAction()).unwrap();
            const newFormTypes = response?.result;
            setFormTypesArray(newFormTypes);
        } catch (error) {
            console.error("Failed to fetch form types:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleFormTypeClick = (formType) => {
        onSelectFormType({
            formId: formType.id,
            formType: formType.title,
        });
    };

    if (isLoading) {
        return <ShimmerPostDetails card cta variant="SIMPLE" />;
    }

    return (
        <Styled.Container>
          <Styled.TypeContainer>
            <Styled.TypeText>Select the type</Styled.TypeText>
            <Styled.TypeList>
                {formTypesArray.map((formType) => (
                    <Styled.TypeListText 
                        id={formType?.id}
                        onClick={() => handleFormTypeClick(formType)}
                    >
                        {formType?.title}
                        <Styled.NextArrowIcon src={INextArrow}></Styled.NextArrowIcon>
                    </Styled.TypeListText>
                ))}
            </Styled.TypeList>
          </Styled.TypeContainer>
          <SnackBar />
        </Styled.Container>
    )
}
export default ObservationQuestionnaireType;