import { createSlice } from "@reduxjs/toolkit";
import {
  getPatientProfileAction
} from "../actions";

const initialState = {
  isLoading: null,
  patientProfile: null,
};

const patientProfileSlice = createSlice({
  name: "patient-profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    //get patient profile
    builder.addCase(getPatientProfileAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPatientProfileAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.patientProfile = payload?.data;
    });
    builder.addCase(getPatientProfileAction.rejected, (state) => {
      state.isLoading = false;
      state.patientProfile = null;
    });
  },
});

export const patientProfileSelector = (state) => state.patientProfile.patientProfile;
;
export default patientProfileSlice.reducer;
