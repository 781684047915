import { createSlice } from "@reduxjs/toolkit";
import {
  callHemeBotAction
} from "../actions";

const initialState = {
  isLoading: null,
  answers: null,
};

const hemebotSlice = createSlice({
  name: "answers",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {

    //get answers
    builder.addCase(callHemeBotAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(callHemeBotAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.answers = payload?.data;
    });
    builder.addCase(callHemeBotAction.rejected, (state) => {
      state.isLoading = false;
      state.answers = null;
    });
  },
});

export const hemebotSelector = (state) => state.hemebot?.answers;
export default hemebotSlice.reducer;
export const { resetState } = hemebotSlice.actions;
