import styled from "styled-components";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: #f5fbfc;
  border-radius: 16px;
  padding: 48px;
  width: 1000px; /* Adjusted to match the TextArea width */
  max-width: 1000px;
  display: flex;
  flex-direction: column;
`;

export const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
`;

export const ModalHeader = styled.div`
  color: #09c;
  font-family: "Noto Sans";
  font-size: 24px;
  font-weight: 400;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  color: #666;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin: 0;
`;

export const TextArea = styled.textarea`
  height: 488px;
  resize: none;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1em;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
`;

export const SaveButton = styled.button`
  width: 180px;
  height: 44px;
  background-color: #7aba56;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
`;
