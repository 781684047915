import { useSelector } from "react-redux";
import { authSelector } from "../store/slice/auth.slice";

function useIsAuthenticated() {
  const { userDetails } = useSelector(authSelector);
  if (userDetails?.access_token) {
    return true;
  }
  return false;
}

export default useIsAuthenticated;
