import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SnackBar } from "../../components/shared";
import { ShimmerTable } from "react-shimmer-effects";
import { AddPatientModal } from "../../components/layout";
import useIsAuthenticated from "../../hooks/useIsAuthenticated";
import {
  getPatientsAction,
  addPatientAction,
  getPatientsByNumberAction,
} from "../../store/actions/patient.action";
import { patientSelector, setSelectedPatient } from "../../store/slice/patient.slice";
import {
  ISearch, IPhoneIcon, IProfileImageSmall, IEmailIcon, IDobIcon, IGenderIcon, IAddIconWhite
} from "../../assets";
import {
  Container, HeaderWrapper, Header, HeaderLeft, HeaderRight, SearchWrapper,
  AddButton, ProfileImage,
  PatientContainer, PatientCard, ProfileSection, PatientInfo, PhoneNumber,
  Name, ArrowImage, DetailSection, DetailItem, EmailSection, Email, EmailIcon, EmptyListMessage,
  GenderImage, GenderText, DobImage, DobText, DobDate, DobYears, AddIcon, AddText, AddButtonMobile,
  InnerContainer, EmailText, EmailItem, EmailImage, Tabs, TabButton, UserName
} from "./style.components";
import moment from 'moment';
import { TextField, InputAdornment, Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Tooltip, TablePagination, TableContainer, Card, CardHeader, Skeleton, CardContent } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { KeyboardArrowRight } from "@mui/icons-material";
import { visuallyHidden } from "@mui/utils";
import ShortingArrow from "../../assets/shortingArrow";
import CardViewIcon from "../../assets/cardViewIcon";
import TableViewIcon from "../../assets/tableViewIcon";


const capitalizeFirstLetter = string => {
  if (!string || typeof string !== 'string') return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const createData = (id, first_name, last_name, gender, phone_number, dob, email) => ({ id, first_name, last_name, gender, phone_number, dob, email });

const PatientList = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { patients, searchedPatient } = useSelector(patientSelector);
  const dispatch = useDispatch();
  const [patientData, setPatientData] = useState([]);
  const [showAddPatientModal, setShowAddPatientModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isMobileWidth, setMobileWidth] = useState(false);
  const [activeTab, setActiveTab] = useState("card");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("first_name");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [patientsSortedData, setPatientsSortedData] = useState([]);
  const [isSort, setIsSort] = useState(false);

  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 768) {
        setMobileWidth(true);
      } else {
        setMobileWidth(false);
      }
    }
    handleResize()
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const tabView = localStorage.getItem('patientListView')
    if (tabView === 'table') {
      setActiveTab('table')
    } else {
      setActiveTab('card')
    }

  }, [])

  useEffect(() => {
    if (!isAuthenticated) navigate("/login");
  }, [navigate, isAuthenticated]);

  const fetchAllPatients = useCallback(async () => {
    setIsLoading(true);
    try {
      await dispatch(getPatientsAction());
    } catch (error) {
      console.error("Error fetching patients:", error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  const fetchSearchPatient = async () => {
    setIsLoading(true);
    try {
      if (searchQuery !== "") {
        const action = await dispatch(
          getPatientsByNumberAction({ payload: { search: searchQuery } })
        );
        if (action?.payload?.status === "success") {
          setPatientData(action?.payload?.data?.records);
        } else {
          setPatientData([]);
        }
      } else {
        patients && setPatientData(patients);
      }
    } catch (err) {
      console.log("error occured");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchClick = useCallback(async () => {
    await fetchSearchPatient();
  }, [searchQuery, dispatch]);


  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if (query === "") {
      setPatientData(patients);
    }
  };

  const formulatePatientsData = useCallback(() => {
    if (patients) {
      const data = patients.map(patient => createData(patient.id, patient.first_name, patient.last_name, patient.gender, patient.phone_number, patient.dob, patient.email));
      setPatientData(data);
    }
  }, [patients]);

  useEffect(() => {
    formulatePatientsData();
  }, [patients, formulatePatientsData]);

  useEffect(() => {
    if (isAuthenticated) fetchAllPatients();
  }, [fetchAllPatients]);

  const onAddPatient = async (patientPayload) => {
    setIsLoading(true);
    try {
      setShowAddPatientModal(false);
      const action = await dispatch(addPatientAction(patientPayload));
      const response = action.payload;
      if (response && response.status === "success") {
        setIsLoading(false);
        await fetchAllPatients();
      }
      else {
        console.error("Failed to add patient:", action.error);
      }
    } catch (error) {
      console.error("Failed to add patient:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClear = () => {
    setSearchQuery('');
    setPatientData(patients);
  }

  const handlePatientClick = (id, phone_number, first_name, gender, dob) => {
    dispatch(setSelectedPatient({ id, phone_number, first_name, gender, dob }));
    navigate(`/patient-records?patient_id=${id}`);
  };
  const showClearButton = useMemo(() => searchQuery.length > 0, [searchQuery]);
  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      handleSearchClick();
    }
  }, [handleSearchClick]);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    localStorage.setItem('patientListView', tab)
  };


  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }


  const headCells = [
    {
      id: "first_name",
      numeric: false,
      disablePadding: false,
      label: "Patient Name",
      isSorting: true,
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Email",
      isSorting: false,
    },
    {
      id: "gender",
      numeric: false,
      disablePadding: false,
      label: "Gender",
      isSorting: false,
    },
    {
      id: "age",
      numeric: false,
      disablePadding: false,
      label: "Age",
      isSorting: false,
    },
    {
      id: "phone_number",
      numeric: true,
      disablePadding: false,
      label: "Phone number",
      isSorting: false,
    }

  ];

  const handleRequestSort = (event, property) => {
    setIsSort(true)
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - patientData.length) : 0;



  useEffect(() => {
    let visibleRows = []
   
    if(isSort){
      if (patientData && patientData.length) {
        visibleRows = [...patientData].sort(getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      }
      setPatientsSortedData(visibleRows || []);
    }else{
    if (patientData && patientData.length) {
      visibleRows = [...patientData].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }
    setPatientsSortedData(visibleRows || []);
    }


  }, [order, orderBy, page, rowsPerPage, patientData])


  function EnhancedTableHead(props) {
    const {
      // onSelectAllClick,
      order,
      orderBy,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler =
      (property) => (event) => {
        onRequestSort(event, property);
      };


    return (<>
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "left" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{ fontSize: "14px", fontWeight: "bold" }}
            >
              {headCell.isSorting ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  IconComponent={ShortingArrow}
                >
                  <span>{headCell.label} &nbsp; </span>
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              ) : (
                <span>{headCell.label}</span>
              )}
            </TableCell>
          ))}
          <TableCell sx={{ verticalAlign: "bottom" }} align="right">
          </TableCell>

        </TableRow>
      </TableHead>



    </>
    );
  }



  const PatienTableRow = ({ patientsData }) => {
    return (
      <>
        {patientsData.length && patientsData.map((row, i) => {
          const {
            id,
            first_name,
            last_name,
            gender,
            phone_number,
            dob,
            email,
          } = row;
          return <TableRow
            key={i}
            sx={{
              "& > *": {
                borderBottom: "unset",
                background: '',
                padding: "16px 16px",
              },
              "&:hover": {
                backgroundColor: "#DDEBED !important"
              }
            }}
            // onMouseOver={() => setTableIcons(true)}
            // onMouseLeave={() => setTableIcons(false)}
            onClick={() =>
              handlePatientClick(
                id,
                phone_number,
                first_name,
                gender,
                dob
              )
            }

          >

            <TableCell component="th" scope="row" >
              <Tooltip title={row.first_name || "-"}>
                <UserName style={{ fontSize: "0.875rem", maxWidth: "150px", color: '#000000de' }} >{row.first_name || "-"}</UserName>
              </Tooltip>
            </TableCell>
            <TableCell component="th" scope="row" >
              <Tooltip title={row.email || "-"}>
                <UserName style={{ fontSize: "0.875rem", maxWidth: "200px", color: '#000000de' }} >{row.email || "-"}</UserName>
              </Tooltip>
            </TableCell>
            <TableCell component="th" scope="row" onClick={() => {
            }}>
              <UserName style={{ fontSize: "0.875rem", maxWidth: "140px", color: '#000000de' }} >{row.gender || "-"}</UserName>
            </TableCell>
            <TableCell component="th" scope="row" onClick={() => {
            }}>
              <UserName style={{ fontSize: "0.875rem", maxWidth: "140px", color: '#000000de' }} >
                {row.dob ? moment().diff(moment(row.dob, 'YYYY-MM-DD'), 'years') : 0} Years
              </UserName>
            </TableCell>
            <TableCell component="th" scope="row" onClick={() => {
            }}>
              <UserName style={{ fontSize: "0.875rem", maxWidth: "140px", color: '#000000de' }} >{row.phone_number || "-"}</UserName>
            </TableCell>

            <TableCell
              align="right"
            >
              {/* <Box display={'flex'}>  */}
              <IconButton aria-label="expand row" size="small">
                <KeyboardArrowRight />
              </IconButton>
              {/* </Box> */}
            </TableCell>
          </TableRow>
        })}
      </>
    )
  }


  return (
    <Container>
      {isMobileWidth ? (
        <>
          <div style={{ margin: '0px 20px' }}>
            <HeaderWrapper>
              <Box display="flex" width={"100%"} justifyContent="space-between" alignItems={"center"}>
                <Header>Patients {patientData?.length ? `(${patientData?.length})` : null}</Header>
                <Tabs style={{ marginBottom: '10px', marginRight: '0px' }}>
                  <TabButton
                    onClick={() => handleTabChange("card")}
                    className={activeTab === "card" ? "active" : ""}
                  >
                    <CardViewIcon active={activeTab === "card" ? true : false} />
                  </TabButton>
                  <TabButton
                    onClick={() => handleTabChange("table")}
                    className={activeTab === "table" ? "active" : ""}
                  >
                    <TableViewIcon active={activeTab === "table" ? true : false} />

                  </TabButton>
                </Tabs>
              </Box>

              <Box display="flex" width={"100%"} justifyContent="end">
                <TextField
                  variant="outlined"
                  placeholder="Search..."
                  value={searchQuery}
                  title="Search by name, mobile number or email"
                  onChange={handleSearchChange}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    endAdornment: (
                      <>
                        {showClearButton && (
                          <IconButton onClick={handleClear} edge="end">
                            <ClearIcon />
                          </IconButton>
                        )}
                        <InputAdornment position="end">
                          <SearchIcon sx={{ color: "#7aba57", cursor: "pointer" }} onClick={handleSearchClick} />
                        </InputAdornment>
                      </>
                    ),
                  }}
                  sx={{
                    marginRight: 2,
                    "& .MuiOutlinedInput-root": {
                      height: "45px",
                      background: "#FFF",
                    },
                    "& .MuiInputBase-input": {
                      padding: "0 20px",
                    },
                    width: "100%",
                  }}
                />
                <AddButtonMobile
                  onClick={() => {
                    setShowAddPatientModal(true);
                  }}
                >
                  <AddIcon src={IAddIconWhite}></AddIcon>
                </AddButtonMobile>
              </Box>

            </HeaderWrapper>
          </div>
        </>
      ) : (
        <div style={{ margin: '0px 40px 0px 0px;' }}>
          <HeaderWrapper>
            <Box display="flex" justifyContent="end" alignItems={"center"} width={"30%"}>
              <Header>Patients {patientData?.length ? `(${patientData?.length})` : null}</Header>
              <Tabs>
                <TabButton
                  onClick={() => handleTabChange("card")}
                  className={activeTab === "card" ? "active" : ""}
                >
                  <CardViewIcon active={activeTab === "card" ? true : false} />
                </TabButton>
                <TabButton
                  onClick={() => handleTabChange("table")}
                  className={activeTab === "table" ? "active" : ""}
                >
                  <TableViewIcon active={activeTab === "table" ? true : false} />

                </TabButton>
              </Tabs>
            </Box>
            <Box display="flex" width={"70%"} justifyContent="end" alignItems={"center"}>

              <TextField
                variant="outlined"
                placeholder="Search by name, mobile number or email"
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
                InputProps={{
                  endAdornment: (
                    <>
                      {showClearButton && (
                        <IconButton onClick={handleClear} edge="end">
                          <ClearIcon />
                        </IconButton>
                      )}
                      <InputAdornment position="end">
                        <SearchIcon sx={{ color: "#7aba57", cursor: "pointer" }} onClick={handleSearchClick} />
                      </InputAdornment>
                    </>
                  ),
                }}
                sx={{
                  marginRight: 2,
                  "& .MuiOutlinedInput-root": {
                    height: "45px",
                    background: "#FFF",
                  },
                  "& .MuiInputBase-input": {
                    padding: "0 20px", // Adjusting padding to fit the smaller height
                  },
                  width: "100%",
                }}
              />
              <AddButton
                onClick={() => {
                  setShowAddPatientModal(true);
                }}
              >
                <AddIcon src={IAddIconWhite}></AddIcon>
                <AddText>Add New</AddText>
              </AddButton>
            </Box>
          </HeaderWrapper>
        </div>
      )}
      {isLoading ? (
          <>
          {activeTab == "card" ?
          <Box sx={{ display: 'flex', flexWrap:'wrap', justifyContent: 'center',width:'100vw'}}>
          {Array.from(new Array(12)).map(() =>{
           return <Card sx={{ width: 345, m: 2 }}>
              <CardHeader
                avatar={
                  <Skeleton animation="wave" variant="circular" width={40} height={40} />
                }

                title={
                  <Skeleton
                    animation="wave"
                    height={15}
                    width="70%"
                    style={{ marginBottom: 6 }}
                  />
                }
                subheader={
                  <Skeleton animation="wave" height={15} width="40%" />
                }
              />

              <CardContent>
                <>
                  <Skeleton animation="wave" height={14} style={{ marginBottom: 6 }} />
                  <Skeleton animation="wave" height={15} width="80%" />
                </>

              </CardContent>
            </Card>
          })}
          </Box>
             :
            <Box >
              {Array.from(new Array(20)).map(() =>{
              return  <Skeleton animation="wave"  sx={{ bgcolor: 'grey.200' }} height={30} />
              })}
            </Box>}

        </>

      ) : (
        <>
          {!searchedPatient && patientData?.length > 0 ? (<>
            {activeTab == "card" ?
              <PatientContainer>
                {patientData.map((patient, index) => {
                  const {
                    id,
                    first_name,
                    last_name,
                    gender,
                    phone_number,
                    dob,
                    email,
                  } = patient;
                  return (
                    <PatientCard
                      key={index + 1}
                      onClick={() =>
                        handlePatientClick(
                          id,
                          phone_number,
                          first_name,
                          gender,
                          dob
                        )
                      }
                    >
                      <InnerContainer>
                        <ProfileSection>
                          <ProfileImage src={IProfileImageSmall} alt="ProfileImage" />
                          <PatientInfo>
                            <Name>{capitalizeFirstLetter(first_name)}</Name>
                            {phone_number && (
                              <EmailItem>
                                <EmailImage
                                  src={IPhoneIcon}
                                  alt="phone"
                                ></EmailImage>
                                <EmailText>{phone_number}</EmailText>
                              </EmailItem>
                            )}
                            {email && (
                              <EmailItem>
                                <EmailImage
                                  src={IEmailIcon}
                                  alt="email"
                                ></EmailImage>
                                <EmailText>{email}</EmailText>
                              </EmailItem>
                            )}

                            <>
                              {gender && <DetailItem>
                                <GenderImage src={IGenderIcon} alt="Gender"></GenderImage>
                                <GenderText>{capitalizeFirstLetter(gender)}</GenderText>
                              </DetailItem>}

                              {dob && <DetailItem>
                                <DobImage src={IDobIcon} alt="DOB"></DobImage>
                                <DobText>
                                  <DobDate>{moment(dob).format('DD-MMM-YYYY')}</DobDate>
                                </DobText>
                              </DetailItem>}
                            </>
                          </PatientInfo>
                        </ProfileSection>
                      </InnerContainer>
                    </PatientCard>
                  );
                })}
              </PatientContainer>
              :
              <PatientContainer style={{ background: '#fff', justifyContent: 'flex-start', display: 'flex', padding: '23px' }}>
                <TableContainer
                  sx={{
                    display: 'flex !important',
                    flexDirection: 'column !important',
                    justifyContent: 'flex-start !important',
                    alignItems: 'start !important',
                    '.MuiTablePagination-root': {
                      overflow: 'visible !important',

                    }
                  }}
                >

                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    aria-label="collapsible table"
                    size={dense ? "small" : "medium"}
                  >
                    <EnhancedTableHead
                      // numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={patientData.length}
                    />

                    <TableBody>
                      <PatienTableRow patientsData={patientsSortedData} />
                    </TableBody>
                  </Table>

                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={patientData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </TableContainer>
              </PatientContainer>
            }

          </>


          ) : (
            <EmptyListMessage>No patients data found</EmptyListMessage>
          )}
        </>
      )}
      <AddPatientModal
        open={showAddPatientModal}
        handleClose={() => setShowAddPatientModal(false)}
        handleSave={onAddPatient}
      />
      <SnackBar />
    </Container>
  );
};
export default PatientList;
