import { Box } from "@mui/material";
import { formatTimestamp } from "../../utilities/common";
import styled from "styled-components";

const InfoName = styled.div`
  font-weight: 600;
`;

const HeaderTitle = {
  color: { xs: "#808080", sm: "#000" },
};

const LabInfo = ({ recordInfo }) => {
  return (
    <>
      <Box mt={1}>
        <Box sx={HeaderTitle}>Lab Name / Added by</Box>
        <InfoName>{recordInfo?.performer_details?.name || "-"}</InfoName>
      </Box>
      <Box mt={1}>
        <Box sx={HeaderTitle}>Date & Time</Box>
        <InfoName>{formatTimestamp(recordInfo?.study_info?.study_date) || "-"}</InfoName>
      </Box>
    </>
  );
};

export default LabInfo;
