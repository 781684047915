import { createSlice } from "@reduxjs/toolkit";
import {
  startTranscriptionJobAction
} from "../actions";

const initialState = {
  isLoading: null,
  startTranscriptResponse: null,
};

const startTranscriptSlice = createSlice({
  name: "start-transcript",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(startTranscriptionJobAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(startTranscriptionJobAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.startTranscriptResponse = payload?.data;
    });
    builder.addCase(startTranscriptionJobAction.rejected, (state) => {
      state.isLoading = false;
      state.startTranscriptResponse = null;
    });
  },
});

export const startTranscriptSelector = (state) => state?.startTranscripObject;
export default startTranscriptSlice.reducer;
