import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import * as Styled from "./style.components";
import {
  getFormTypesAction,
  getObservationTypesAction,
} from "../../../../store/actions";
import { IBackArrow, INextArrow } from "../../../../assets";
import { patientSelector } from "../../../../store/slice/patient.slice";
import { addReminderAction } from "../../../../store/actions";
import { ShimmerPostDetails } from "react-shimmer-effects";
import { MenuItem } from "@mui/material";
import { SnackBar } from "../../../../components/shared";

const formatTime = (time) => {
  return time + (time.split(":").length === 2 ? ":00" : "");
};

const ConsultationAddReminder = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { selectedPatientId } = useSelector(patientSelector);
  const [selectedObservation, setSelectedObservation] = useState({});
  const [observationTypesArray, setObservationTypesArray] = useState([{}]);
  const [reminder, setReminder] = useState({
    sugarLevelTiming: "Before Meal",
    sugarLevelFrequency: "Daily",
  });
  const [reminderTimes, setReminderTimes] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [customDates, setCustomDates] = useState([""]);
  const [isLoading, setIsLoading] = useState(false);
  const [isReminderAdded, setIsReminderAdded] = useState(false);
  const [formTypesArray, setFormTypesArray] = useState([{}]);
  const [selectedFormType, setSelectedFormType] = useState("");

  useEffect(() => {
    if (state?.data) {
      const { data } = state;
      const observationData = {
        record_type: data.reminder_type.type,
        name: data.reminder_type.display_name,
      };

      const reminderData = {
        sugarLevelFrequency:
          data.schedule_configuration.frequency.charAt(0).toUpperCase() +
          data.schedule_configuration.frequency.slice(1),
        startDate: data.schedule_configuration?.start_date,
        endDate: data.schedule_configuration?.end_date,
        description: data.description,
        sugarLevelTiming:
          data.reminder_sub_type == "pre_meal" ? "Before Meal" : "After Meal",
      };

      setSelectedFormType(data?.reminder_resource?.resource_key || "");
      setReminder({ ...reminder, ...reminderData });
      setCustomDates(
        data?.schedule_configuration?.schedules?.custom_dates || [""]
      );
      setReminderTimes(data?.schedule_configuration?.schedules?.times || []);
      setSelectedObservation(observationData);

      setSelectedDays(
        state.data?.schedule_configuration?.schedules?.days || []
      );
    }
  }, [state]);

  useEffect(() => {
    fetchObservationTypes();
  }, [dispatch]);

  useEffect(() => {
    fetchFormTypes();
  }, [dispatch]);

  const fetchFormTypes = async () => {
    setIsLoading(true);
    try {
      const response = await dispatch(getFormTypesAction()).unwrap();
      const newFormTypes = response?.result;
      setFormTypesArray(newFormTypes);
    } catch (error) {
      console.error("Failed to fetch form types:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <ShimmerPostDetails card cta variant="SIMPLE" />;
  }

  const fetchObservationTypes = async () => {
    setIsLoading(true);
    try {
      const response = await dispatch(getObservationTypesAction()).unwrap();
      const newObservationTypes = response?.result;
      setObservationTypesArray(newObservationTypes);
      if (!state?.data?.reminder_type) {
        const painLevelObservation = newObservationTypes.find(
          (obs) => obs.name === "Photo"
        );
        if (painLevelObservation) {
          setSelectedObservation(painLevelObservation);
        }
      }
    } catch (error) {
      console.error("Failed to fetch observation types:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleObservationChange = (observation) => {
    console.log("observation", observation);
    setSelectedObservation(observation);
    if (observation?.name === "Blood Pressure") {
      setReminder({ ...reminder, sugarLevelTiming: "" });
    }
  };

  if (isLoading) {
    return <ShimmerPostDetails card cta variant="SIMPLE" />;
  }

  if (isReminderAdded) {
    return <ShimmerPostDetails card cta variant="SIMPLE" />;
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    if (name === "reminderTimes") {
      let times = [...reminderTimes];
      times[index] = value;
      setReminderTimes(times);
    } else {
      setReminder({ ...reminder, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsReminderAdded(true);

      let payload = {
        schedule_configuration: {
          start_date: reminder.startDate,
          end_date: reminder.endDate,
          frequency: reminder.sugarLevelFrequency.toLowerCase(),
          schedules: {
            times: reminderTimes.filter(Boolean).map(formatTime),
            ...(reminder.sugarLevelFrequency.toLowerCase() === "weekly" && {
              days: selectedDays,
            }),
            ...(reminder.sugarLevelFrequency.toLowerCase() === "custom" && {
              custom_dates: customDates.filter(Boolean),
            }),
          },
        },
        patient_id: selectedPatientId,
        description: reminder.description,

        reminder_type: {
          type: selectedObservation?.record_type,
          display_name: selectedObservation?.name,
        },
      };

      if (selectedObservation?.name === "Questionnaire") {
        payload = {
          ...payload,
          reminder_resource: {
            resource_key: selectedFormType,
            resource_type: "FHIR",
            resource_name: "questionnaire",
            resource_display_name: "Questionnaire",
          },
        };
      }

      // Adding the reminder_sub_type field if observation is Blood Sugar
      if (selectedObservation?.name === "Blood Sugar") {
        payload = {
          ...payload,
          reminder_sub_type:
            reminder.sugarLevelTiming === "Before Meal"
              ? "pre_meal"
              : "post_meal",
        };
      }
      const editIndex = state?.index;

      let existingReminders = localStorage.getItem("reminders")
        ? JSON.parse(localStorage.getItem("reminders"))
        : [];

      let localreminders = [...existingReminders];
      if (state?.data && editIndex > -1) {
        localreminders.splice(editIndex, 1, payload);
        localStorage.setItem("reminders", JSON.stringify(localreminders));
        navigate(-1);
      } else {
        localreminders.push(payload);
        localStorage.setItem("reminders", JSON.stringify(localreminders));
        navigate(-1);
      }

      //  const actionResult = await dispatch(addReminderAction(payload));
      //   const response = actionResult.payload;
      //   if (response && response.status === "success") {
      //    navigate("/patient-track");
      //   }
      //   else {
      //     console.error("Failed to add reminder:", actionResult.error);
      //   }
    } catch (error) {
      console.error("Failed to add reminder:", error);
    } finally {
      setIsReminderAdded(false);
    }
  };

  const toggleDay = (day) => {
    setSelectedDays((prevDays) =>
      prevDays.includes(day)
        ? prevDays.filter((d) => d !== day)
        : [...prevDays, day]
    );
  };

  const addMoreDates = () => {
    if (customDates.length < 10) {
      setCustomDates([...customDates, ""]);
    } else {
      alert("You can add up to 10 dates only.");
    }
  };

  const handleCustomDateChange = (e, index) => {
    const newDates = [...customDates];
    newDates[index] = e.target.value;
    setCustomDates(newDates);
  };

  return (
    <Styled.Container>
      <Styled.TopHeader>
        <Styled.ArrowBackImage
          src={IBackArrow}
          alt="back-arrow"
          onClick={() => navigate(-1)}
        />
        {state?.data && state?.index > -1 ? "Update Reminder" : "Add Reminder"}
      </Styled.TopHeader>
      <Styled.ObservationContainer>
        <Styled.ObservationSelector>
          {console.log("selectedObservation", selectedObservation)}
          {observationTypesArray.map((observation, index) => (
            <Styled.ObservationTile
              key={observation?.id}
              isSelected={selectedObservation?.name === observation?.name}
              onClick={() => handleObservationChange(observation)}
            >
              <Styled.ObservationDetail>
                <Styled.ObservationThumb
                  src={observation.icon}
                ></Styled.ObservationThumb>
                <Styled.ObservationName>
                  {observation.name}
                </Styled.ObservationName>
              </Styled.ObservationDetail>
              <Styled.NextArrowIcon src={INextArrow}></Styled.NextArrowIcon>
            </Styled.ObservationTile>
          ))}
        </Styled.ObservationSelector>

        <Styled.ObservationEntry>
          <Styled.ObservationInfo>
            <Styled.ObservationLogoSmall
              src={selectedObservation.icon}
            ></Styled.ObservationLogoSmall>
            <Styled.ObservationNameSmall>
              Add {selectedObservation.name}
            </Styled.ObservationNameSmall>
          </Styled.ObservationInfo>
          <Styled.Form onSubmit={handleSubmit}>
            {selectedObservation?.name === "Blood Sugar" && (
              <Styled.SugarLevelFor>
                <Styled.SugarLevelForText>
                  Sugar Level For
                </Styled.SugarLevelForText>
                <Styled.RadioMainContainer>
                  <Styled.RadioButtonContainer
                    $isSelected={reminder.sugarLevelTiming === "Before Meal"}
                  >
                    <Styled.RadioButtonLabel>
                      <Styled.RadioButton
                        id="beforeMeal"
                        type="radio"
                        name="sugarLevelTiming"
                        value="Before Meal"
                        checked={reminder.sugarLevelTiming === "Before Meal"}
                        onChange={handleInputChange}
                      />
                      Before Meal
                    </Styled.RadioButtonLabel>
                  </Styled.RadioButtonContainer>
                  <Styled.RadioButtonContainer
                    $isSelected={reminder.sugarLevelTiming === "After Meal"}
                  >
                    <Styled.RadioButtonLabel>
                      <Styled.RadioButton
                        id="afterMeal"
                        type="radio"
                        name="sugarLevelTiming"
                        value="After Meal"
                        checked={reminder.sugarLevelTiming === "After Meal"}
                        onChange={handleInputChange}
                      />
                      After Meal
                    </Styled.RadioButtonLabel>
                  </Styled.RadioButtonContainer>
                </Styled.RadioMainContainer>
              </Styled.SugarLevelFor>
            )}

            {selectedObservation?.name === "Questionnaire" && (
              <Styled.SugarLevelFor>
                <Styled.SugarLevelForText>
                  Select form type
                </Styled.SugarLevelForText>
                <Styled.CustomFormControl variant="outlined">
                  <Styled.CustomSelect
                    labelId="form-type-select-label"
                    id="form-type-select"
                    value={selectedFormType}
                    onChange={(e) => setSelectedFormType(e.target.value)}
                    input={
                      <Styled.CustomOutlinedInput label="Select Form Type" />
                    }
                  >
                    <MenuItem value="" disabled>
                      Select Type
                    </MenuItem>
                    {formTypesArray.map((formType, index) => (
                      <MenuItem key={formType.id} value={formType.id}>
                        {formType.title}
                      </MenuItem>
                    ))}
                  </Styled.CustomSelect>
                </Styled.CustomFormControl>
              </Styled.SugarLevelFor>
            )}

            <Styled.DateSelection>
              <Styled.SelectDateText>
                Select Start and End Dates
              </Styled.SelectDateText>
              <Styled.DateContainer>
                <Styled.DateLabel>
                  From:
                  <Styled.DateInput
                    defaultValue={reminder.startDate}
                    name="startDate"
                    onChange={handleInputChange}
                  />
                </Styled.DateLabel>
                <Styled.DateLabel>
                  To:
                  <Styled.DateInput
                    defaultValue={reminder.endDate}
                    name="endDate"
                    onChange={handleInputChange}
                  />
                </Styled.DateLabel>
              </Styled.DateContainer>
            </Styled.DateSelection>

            {/* Frequency Selection */}
            <Styled.FrequencyText>Frequency</Styled.FrequencyText>
            <Styled.RadioMainContainer>
              <Styled.RadioButtonContainer
                $isSelected={reminder.sugarLevelFrequency === "Daily"}
              >
                <Styled.RadioButtonLabel>
                  <Styled.RadioButton
                    id="daily"
                    type="radio"
                    name="sugarLevelFrequency"
                    value="Daily"
                    checked={reminder.sugarLevelFrequency === "Daily"}
                    onChange={handleInputChange}
                  />
                  Daily
                </Styled.RadioButtonLabel>
              </Styled.RadioButtonContainer>
              <Styled.RadioButtonContainer
                $isSelected={reminder.sugarLevelFrequency === "Weekly"}
              >
                <Styled.RadioButtonLabel>
                  <Styled.RadioButton
                    id="weekly"
                    type="radio"
                    name="sugarLevelFrequency"
                    value="Weekly"
                    checked={reminder.sugarLevelFrequency === "Weekly"}
                    onChange={handleInputChange}
                  />
                  Weekly
                </Styled.RadioButtonLabel>
              </Styled.RadioButtonContainer>
              {console.log("reminder@@@@", reminder)}
              <Styled.RadioButtonContainer
                $isSelected={reminder.sugarLevelFrequency === "Custom"}
              >
                <Styled.RadioButtonLabel>
                  <Styled.RadioButton
                    id="custom"
                    type="radio"
                    name="sugarLevelFrequency"
                    value="Custom"
                    checked={reminder.sugarLevelFrequency === "Custom"}
                    onChange={handleInputChange}
                  />
                  Custom
                </Styled.RadioButtonLabel>
              </Styled.RadioButtonContainer>
            </Styled.RadioMainContainer>

            {reminder.sugarLevelFrequency === "Weekly" && (
              <>
                <Styled.SelectTimeText>
                  Patient will receive reminders on below days at selected
                  times.
                </Styled.SelectTimeText>
                <Styled.DaySelection>
                  <Styled.SelectDayText>Select Days</Styled.SelectDayText>
                  <Styled.DayContainer>
                    {[
                      "Monday",
                      "Tuesday",
                      "Wednesday",
                      "Thursday",
                      "Friday",
                      "Saturday",
                      "Sunday",
                    ].map((day) => (
                      <Styled.DayCheckboxWrapper key={day}>
                        <Styled.DayCheckbox
                          type="checkbox"
                          checked={selectedDays.includes(day.toLowerCase())}
                          onChange={() => toggleDay(day.toLowerCase())}
                        />
                        {day}
                      </Styled.DayCheckboxWrapper>
                    ))}
                  </Styled.DayContainer>
                </Styled.DaySelection>
              </>
            )}

            {reminder.sugarLevelFrequency === "Custom" && (
              <>
                <Styled.SelectTimeText>
                  Patient will receive reminders on below days at selected
                  times.
                </Styled.SelectTimeText>
                <Styled.CustomContainer>
                  <Styled.SelectDayText>Select Dates</Styled.SelectDayText>
                  <Styled.CustomDateSelection>
                    {customDates.map((date, index) => (
                      <Styled.CustomDateInputWrapper key={index}>
                        <Styled.CustomDateInput
                          type="date"
                          value={date}
                          onChange={(e) => handleCustomDateChange(e, index)}
                        />
                      </Styled.CustomDateInputWrapper>
                    ))}
                    <Styled.AddMoreText onClick={addMoreDates}>
                      Add More
                    </Styled.AddMoreText>
                  </Styled.CustomDateSelection>
                </Styled.CustomContainer>
              </>
            )}

            {reminder.sugarLevelFrequency === "Daily" && (
              <Styled.SelectTimeText>
                Patient will receive reminders on everyday at selected times.
              </Styled.SelectTimeText>
            )}
            <Styled.TimeContainer>
              <Styled.TimeText>Select Time</Styled.TimeText>
              <Styled.TimeSelection>
                <Styled.TimeSelectionRow>
                  <Styled.TimeInputWrapper>
                    <Styled.TimeInput
                      defaultValue={reminderTimes}
                      name="reminderTimes"
                      onChange={(e) => handleInputChange(e, 0)}
                    />
                  </Styled.TimeInputWrapper>
                </Styled.TimeSelectionRow>
              </Styled.TimeSelection>
            </Styled.TimeContainer>

            {/* Description */}
            <Styled.DescriptionText>Description</Styled.DescriptionText>
            <Styled.DescriptionInput
              value={reminder.description}
              type="text"
              name="description"
              onChange={handleInputChange}
            />

            {/* Submit Button */}
            <Styled.SubmitButton type="submit">
              {state?.data && state?.index > -1
                ? "Update Reminder"
                : "Add Reminder"}
            </Styled.SubmitButton>
          </Styled.Form>
        </Styled.ObservationEntry>
      </Styled.ObservationContainer>
      <SnackBar />
    </Styled.Container>
  );
};

export default ConsultationAddReminder;
