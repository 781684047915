import { createSlice } from "@reduxjs/toolkit";
import {
  uploadAudioAction
} from "../actions";

const initialState = {
  isLoading: null,
  audioResponse: null,
};

const uploadAudioSlice = createSlice({
  name: "upload-audio",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(uploadAudioAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(uploadAudioAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.audioResponse = payload?.data;
    });
    builder.addCase(uploadAudioAction.rejected, (state) => {
      state.isLoading = false;
      state.audioResponse = null;
    });
  },
});

export const uploadAudioSelector = (state) => state?.audioObject;
export default uploadAudioSlice.reducer;
