import styled from "styled-components";
import { DEVICE,COLOR_VARIANT } from "../../../../utilities/constants";
import { Box, Popover, Typography } from "@mui/material";

export const UserDetails = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
`;

export const CountryIcon = styled.img`
  height: 20px;
  margin-right: 10px;

  @media ${DEVICE.mobileL} {
    margin-right: 0;
  }
`;

export const LogoutContainer = styled.div`
  font-size: 14px;
  color: #2477B3;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LogoutProfileIcon = styled.img`
  margin-left: 15px;
  margin-right: 12px;
  height: 36px;
  width: 36px;

  @media ${DEVICE.mobileL} {
    margin-left: 10px;
    height: 30px;
    width: 30px;
    margin-right: 0;
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 500px) {
    display: none;
  }
`;

export const ProfileContainerPopover = styled.div`
  flex-direction: row;
  align-items: flex-start;
  padding-bottom: 5px;
  border-bottom: 1px solid #F5FBFC;
  display: none;

  @media (max-width: 500px)  {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 5px;
    border-bottom: 1px solid #F5FBFC;
  }
`;

export const UserName = styled.div`
  font-size: 16px;
  color: #000000;

  @media ${DEVICE.mobileL} {
    font-size: 11px;
    margin-right: 7px;
  }
`;

export const UserNumber = styled.div`
  font-size: 13px;
  color: #808080;
  @media ${DEVICE.mobileL} {
    font-size: 11px;
    margin-right: 7px;
  }
`;

export const LogoutDownArrow = styled.img`
  height: 5px;
  margin-left: 15px;
  @media ${DEVICE.mobileL} {
    margin-left: 5px;
  }
`;

export const LogoutIcon = styled.img`
  width: 20px;
  margin-right: 7px;
  @media ${DEVICE.mobileL} {
    width: 15px;
    margin-right: 5px;
  }
`;

export const LogoutOptionsBox = styled( Box )`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${COLOR_VARIANT};
  padding: 5px 15px;
  cursor: pointer;
  flex-direction: column;

  @media ${DEVICE.mobileL} {
    align-items: flex-start;
  }
`;

export const StyledPopover = styled( Popover )`
  color: #808080;
  font-size: 16px;
  border-radius: 8px;

  @media ${DEVICE.mobileL} {
    font-size: 11px;
    display: flex;
    align-items: center
  }
`;

export const StyledTypography = styled( Typography )`
  color: #808080;
  font-size: 16px;
  
  @media ${DEVICE.mobileL} {
    margin-top: 5px !important;
    display: flex;
    align-items: center;
    font-size: 11px !important;
  }
`;
