import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  showStatus: false,
  severity: "warning",
  message: "Something went wrong",
  autoHide: true,
  autoHideIn: 3000,
};

const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    setShowStatus: (state, action) => {
      state.showStatus = true;
      state.severity = action.payload.severity;
      state.message = action.payload.message;
      if (action.payload.autoHide) state.autoHide = false;
      else state.autoHide = true;
    },
    unsetShowStatus: (state) => {
      state.showStatus = false;
    },
  },
});

export const { setShowStatus, unsetShowStatus } = statusSlice.actions;
export const statusSelector = (state) => state.status;
export default statusSlice.reducer;
