import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Switch from '@mui/material/Switch';
import { ShimmerPostDetails } from "react-shimmer-effects";
import {
  Container,
  Header,
  HeaderLeft,
  HeaderWrapper,
  HeaderRight,
  AddButton,
  RemindersContainer,
  SugarLevelContainer,
  Title,
  ToggleButtonContainer,
  DateText,
  TitleContainer,
  DetailsContainer,
  SugarIcon,
  ContentContainer,
  ContentText,
  TimeContainer,
  Frequency,
  Text,
  Value,
  Days,
  Time,
  EmptyListMessage,
} from "./style.components";
import { patientSelector } from "../../../store/slice/patient.slice";
import { getRemindersAction, updateReminderAction } from "../../../store/actions";
import '../../../utilities/customCalendar.css'
import { SnackBar } from "../../../components/shared";

const formatDate = (inputDate) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    const date = new Date(inputDate);
    const day = date.getDate().toString().padStart(2, '0');
    const monthIndex = date.getMonth();
    const year = date.getFullYear().toString().substring(2);
  
    return `${day}-${months[monthIndex]}-${year}`;
}

const formatCustomDates = (customDates) => {
    return customDates.map(dateString => {
      const date = new Date(dateString);
      return `${date.getDate()}-${date.toLocaleString('en-GB', { month: 'short' })}-${date.getFullYear()}`;
    }).join(', ');
}  

const convertTo12HourFormat = (timeString) => {
    const date = new Date(`1970-01-01 ${timeString}`);
    return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
}

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const formatDays = (daysArray, frequency) => {
    if (frequency.toLowerCase() === 'daily') {
        return 'Everyday';
    }
    return daysArray.map(day => day.charAt(0).toUpperCase() + day.slice(1, 3)).join(', ');
}

const PatientTrack = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { selectedPatientId } = useSelector(patientSelector);
    const [remindersArray, setRemindersArray] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchReminders();
    }, [dispatch]);

    const fetchReminders = async () => {
        setIsLoading(true);
        try {
            const response = await dispatch(getRemindersAction(selectedPatientId)).unwrap();
            const newReminders = response.data;
            setRemindersArray(newReminders);
        } catch (error) {
            console.error("Failed to fetch observation types:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddReminderClick = () => {
        navigate("/add-reminder", {
            state: { id: selectedPatientId },
        });
    };

    const handleChange = useCallback(async (reminderId, currentState) => {
        try {
            const newState = !currentState;
            const payload = {
                "is_reminder_active": newState,
            };
            const actionResult = await dispatch(updateReminderAction({ reminder_id: reminderId, payload }));
            const response = actionResult.payload;
            if (response && response.status === "success") {
                fetchReminders();
            }
            else {
                console.error("Failed to update reminder:", actionResult.error);
            }
        } catch (error) {
            console.error("Error updating reminder:", error);
        }
    }, [dispatch]);

    if (isLoading) {
        return <Container><ShimmerPostDetails card cta variant="SIMPLE" /></Container>;
    }

    return (
        <Container>
            <HeaderWrapper>
                <HeaderLeft>
                    <Header>Reminders</Header>
                </HeaderLeft>
                <HeaderRight>
                    <AddButton onClick={handleAddReminderClick}>
                        Add Reminder
                    </AddButton>
                </HeaderRight>
            </HeaderWrapper>
            <RemindersContainer>
                {remindersArray.length > 0 ? (
                remindersArray.map((reminder, index) => (
                <SugarLevelContainer key={index+1}>
                    <TitleContainer>
                        <Title>{reminder?.reminder_type_display_name}</Title>
                        <ToggleButtonContainer>
                            <Switch
                                checked={reminder?.is_active}
                                onChange={() => handleChange(reminder?.reminder_id, reminder?.is_active)}
                                color="primary"
                            />
                        </ToggleButtonContainer>
                    </TitleContainer>     
                    <DateText>{formatDate(reminder?.start_date)} to {formatDate(reminder?.end_date)}</DateText>
                    <DetailsContainer>
                        <SugarIcon src={reminder?.icon}></SugarIcon>
                        <ContentContainer>
                            {reminder?.reminder_type === "blood_sugar" && (
                                <ContentText>{reminder?.reminder_sub_type === 'post_meal' ? "After Meal" : "Before Meal"}</ContentText>
                            )}
                            {reminder?.reminder_type === "questionnaire" && (
                                <ContentText>{reminder?.reminder_resource?.resource_title}</ContentText>
                            )}
                            <ContentText>{reminder?.reminder_description}</ContentText>
                            <TimeContainer>
                                <Frequency>
                                    <Text>Frequency</Text>
                                    <Value>{capitalizeFirstLetter(reminder?.frequency)}</Value>
                                </Frequency>
                                {reminder?.frequency === "custom" ? (
                                    <Days>
                                        <Text>Dates</Text>
                                        <Value>{formatCustomDates(reminder?.schedule?.custom_dates)}</Value>
                                    </Days>
                                ):(
                                    <Days>
                                        <Text>Days</Text>
                                        <Value>{formatDays(reminder?.schedule?.days, reminder?.frequency)}</Value>
                                    </Days>
                                )}
                                <Time>
                                    <Text>Time</Text>
                                    <Value>
                                        {reminder?.schedule?.times
                                        .map(time => convertTo12HourFormat(time))
                                        .join(', ')}
                                    </Value>
                                </Time>
                            </TimeContainer>
                            {/* <ButtonContainer>
                                <Edit onClick={handleAddReminderClick}>Edit</Edit>
                                <Delete>Delete</Delete>
                            </ButtonContainer> */}
                        </ContentContainer>
                    </DetailsContainer>
                </SugarLevelContainer>
                ))
                ) :
                (<EmptyListMessage>No reminders found</EmptyListMessage>)}
            </RemindersContainer>
            <SnackBar />
        </Container>
    )
};

export default PatientTrack;
