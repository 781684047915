import styled from "styled-components";
import {
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
} from "../../../utilities/constants"; 
import { Typography } from "@mui/material";

export const Container = styled.div`
  border-radius: 12px;
  background-color: #F5FBFC;
  opacity: 1;
  min-height: calc(100vh - 72px);
  margin-bottom: 0;
  overflow-y: auto;
`;
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  align-items: center;
  justify-content: space-between;
`;
export const Header = styled.div`
  font-size: 24px;
  color: #000000;
  font-weight: 600;
`;
export const HeaderLeft = styled.div`
  display: flex;
  flex: 0.4;
`;
export const HeaderRight = styled.div`
  display: flex;
  flex: 0.6;
  justify-content: flex-end;
  align-items: center;
`;
export const AddButton = styled.button`
  padding: 10px;
  width: 150px;
  height: 40px;
  background: #7aba56 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #ffffff;
  outline: none;
  border-style: hidden;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
`;

export const RemindersContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  grid-template-columns: repeat(2, 1fr);
  justify-content: flex-start;
  gap: 24px;
`;

export const SugarLevelContainer = styled.div`
  flex: 1;
  border-radius: 12px;
  background-color: #FFFFFF;
  max-width: 515px;
  height: auto;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #2FAED7;
  padding: 10px 16px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

export const Title = styled.div`
  color: #FFFFFF;
  font-size: 16px;
`;

export const DateText = styled.p`
  font-size: 14px;
  color: #0099CC;
  margin: 24px 0 18px 24px;
`;

export const ToggleButtonContainer = styled.div`
  margin-left: auto;
  color: #FFFFFF;
  font-size: 13px;
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 24px;
  margin-bottom: 15px;
`;

export const SugarIcon = styled.img`
  padding: 12px;
  background-color: #EBF7FB;
  border-radius: 8px;
  width: 74px;
  height: 74px;
`;

export const ContentContainer = styled.div`
  margin-left: 24px;
  margin-right: 24px
`;

export const ContentText = styled.div`
  font-size: 16px;
  color: #000000;
  margin-bottom: 10px;
`;

export const TimeContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Text = styled.div`
  font-size: 13px;
  color: #808080;
  margin-bottom: 3px;
`

export const ScheduleText = styled.div`
  font-size: 20px;
  color: #808080;
  margin-top: 20px;
`

export const Value = styled.div`
  font-size: 16px;
  color: #000000;
`;

export const Frequency = styled.div`
  margin-right: 15px;
`;

export const Days = styled.div`
  margin-right: 15px;
`;

export const Time = styled.div`
  width: 90px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
`;

export const Edit = styled.button`
  margin-right: 15px;
  padding: 6px 20px;
  background-color: #7ABA56;
  border-radius: 6px;
  border: none;
  font-size: 14px;
  color: #FFFFFF;
  cursor: pointer;
`;

export const Delete = styled.button`
  padding: 6px 20px;
  background-color: #ED4A4A;
  border-radius: 6px;
  border: none;
  font-size: 14px;
  color: #FFFFFF;
  cursor: pointer;
`;

export const ListAddTempWrapper = styled.div`
    margin-bottom: 20px;
`;

export const ModalHeader = styled(Typography)`
  color: #0099CC;
  font-size: 24px !important;
  margin: 28px 0 0 30px !important;
`;

export const ModalBody = styled.div`
  margin: 25px 0 0 35px;
  position: relative;
  flex: 1;
  overflow-y: auto;
`;

export const CloseButton = styled.img`
  cursor: pointer;
  display: block;
  max-width: 100%;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
`;

export const SaveButton = styled.button`
  background-color: #7aba56;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
  padding: 11px 56px;
  margin: 18px 25px;
`;

export const NoButton = styled.button`
  background-color: #ED4A4A;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
  padding: 11px 56px;
  margin: 18px 25px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
`;

export const DeleteText = styled.div`
  font-size: 16px;
  color: #000000;
  font-weight: 800;
`

export const RadioMainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const RadioButtonContainer = styled.div`
  border: 1px solid #92D4E9;
  font-size: 16px;
  color: ${(props) => props.$isSelected ? '#000000' : '#808080'};
  border-radius: 12px;
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  background-color: ${(props) => props.$isSelected ? '#EBF7FB' : '#FFFFFF'};
`;

export const RadioButtonLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  // Add more styles as needed
`;

export const RadioButton = styled.input.attrs({ type: 'radio' })`
  cursor: pointer;
  accent-color: #0099CC;
  opacity: 0; // hide the radio button visually
  width: 0; // reduce the width to zero
  height: 0; // reduce the height to zero
  position: absolute; // take it out of the normal flow
  margin: 0;
  padding: 0;
`;

export const SugarLevelFor = styled.div`
  margin-bottom: 15px;
  width: 50%;
`;

export const SugarLevelForText = styled.div`
  font-size: 13px;
  color: #808080;
  margin-bottom: 10px;
`;

const sharedInputStyles = `
    border-radius: 12px;
    border: 1px solid #92D4E9;
    padding: 10px;
    margin-bottom: 16px;
    font-size: 13px;
`;

export const Input = styled.input`
    flex: 1;
    ${sharedInputStyles}
`;

export const InputUnitWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 60%;
`;

export const UnitLabel = styled.span`
  position: absolute;
  right: 10px;
  margin-bottom: 16px;
  margin-top: 10px;
  font-size: 13px;
`;

export const EmptyListMessage = styled.div`
  margin-top: 10px;
  padding: 20px;
  color: red;
  text-align: center;
`;