import { Box, CardMedia } from "@mui/material";
import {
  CardDetailsWrapper,
  ListItemDate,
  ThumbImage,
  ReportType,
  ReportTypeWrapper,
  ContentType,
  ThumbImageWrapper,
} from "../../../containers/view-patient/patient-records/style.components";
import styled from "styled-components";
import DropdwonThreeDotMenu from "../DropdownThreeDot";
import {
  formatTimestamp,
  removeSpecialCharacters,
} from "../../../utilities/common";
import { useSelector } from "react-redux";

const ListItemTopReport = styled.div`
  color: #404040;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 15px;
  flex: 1 1 calc(20% - 24px);
  max-width: 140px;
  padding-left: 5px;
`;

const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
  border: none;
  max-width: 160px;
  height: auto;
`;

const getFileType = (contentType) => {
  if (!contentType) return "";

  const fileTypes = {
    "application/pdf": "PDF",
    "image/jpeg": "JPEG",
    "image/jpg": "JPG",
    "image/png": "PNG",
  };

  return fileTypes[contentType] || "";
};

const SingleReport = ({ row }) => {
  const thumbnails = useSelector((state) => state?.thumbnail?.thumbnails);

  return (
    <Box
      sx={{
        background: "#FFF",
        padding: "1rem",
        borderRadius: "1rem",
        border: "0.5rem solid #D7EFF7",
      }}
      display="flex"
      justifyContent="center"
    >
      <ListItemWrapper style={{ cursor: "auto" }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          mb={1}
        >
          <ListItemTopReport
            title={
              removeSpecialCharacters(row?.patient_name) ||
              removeSpecialCharacters(row?.name) ||
              row?.title
            }
          >
            {removeSpecialCharacters(row?.patient_name) ||
              removeSpecialCharacters(row?.name) ||
              row?.title}
          </ListItemTopReport>
          <DropdwonThreeDotMenu menuItems={[]} />
        </Box>
        <ThumbImageWrapper>
          <CardMedia
            component="img"
            height="120"
            image={(thumbnails && thumbnails[row?.id]) || row?.icon}
            alt={"records-thumbnail"}
            sx={{
              objectFit: "contain",
              padding: "10px 5px",
              boxSizing: "border-box",
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = row?.icon;
            }}
          />
          <ReportTypeWrapper>
            <ReportType>
              {" "}
              {row?.test_type ||
                row?.modality ||
                row?.record_type.charAt(0).toUpperCase() +
                  row?.record_type.slice(1)}
            </ReportType>
            <ContentType>
              {" "}
              {row?.record_type === "scan"
                ? "DCM"
                : getFileType(row?.content_type)}
            </ContentType>
          </ReportTypeWrapper>
        </ThumbImageWrapper>
        <CardDetailsWrapper>
          <ListItemDate>
            {(row?.study_date && formatTimestamp(row.study_date)) ||
              (row?.upload_date && formatTimestamp(row.upload_date))}
          </ListItemDate>
        </CardDetailsWrapper>
        {row?.status === "RESTRICTED" && (
          <Box
            sx={{
              color: "#CC8552",
              backgroundColor: "#FFF",
              border: "2px solid #CC8552",
              padding: "10px",
              borderRadius: "5px",
              fontSize: "16px",
            }}
          >
            {row?.status}
          </Box>
        )}
      </ListItemWrapper>
    </Box>
  );
};

export default SingleReport;
