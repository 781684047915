import { createSlice } from "@reduxjs/toolkit";
import { generateOTPAction, signoutAction, verifyOTPAction, getSupportedCountriesAction, accessLoginAction } from "../actions";

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  userDetails: null,
  isOTPVerified: null,
  isLoggedOut: null,
  supportedCountries: null,
  selectedCountry: null,
  datastores: []
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    // generate otp
    builder.addCase(generateOTPAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(generateOTPAction.fulfilled, (state) => {
      state.isLoading = false;
      state.isAuthenticated = true;
    });
    builder.addCase(generateOTPAction.rejected, (state) => {
      state.isLoading = false;
      state.isAuthenticated = false;
    });
    // verify otp
    builder.addCase(verifyOTPAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(verifyOTPAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.datastores = payload?.result?.datastores || [];
    });
    builder.addCase(verifyOTPAction.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(accessLoginAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(accessLoginAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isOTPVerified = true;
      state.userDetails = payload?.practitioner || null;
      state.selectedCountry = payload?.practitioner?.country;
    });
    builder.addCase(accessLoginAction.rejected, (state) => {
      state.isLoading = false;
      state.isOTPVerified = false;
    });
    // signout
    builder.addCase(signoutAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(signoutAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isLoggedOut = true;
      state.isAuthenticated = false;
      state.userDetails = null;
      state.isOTPVerified = null;
      state.selectedCountry = null;
      state.supportedCountries = null;
    });
    builder.addCase(signoutAction.rejected, (state) => {
      state.isLoading = false;
      state.isLoggedOut = false;
    });
    builder.addCase(getSupportedCountriesAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getSupportedCountriesAction.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.supportedCountries = payload.data;
      }
    );
    builder.addCase(getSupportedCountriesAction.rejected, (state) => {
      state.isLoading = false;
      state.supportedCountries = null;
    });
  },
});

export const authActions = authSlice.actions;
export const authSelector = (state) => state.auth;
export default authSlice.reducer;
