import { useEffect } from "react";
import { useStore } from "react-redux";
import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from "react-router-dom";

import { useSelector } from "react-redux";
import { setupAxiosInstance } from "../api/api-client";
import {
  PatientList,
  Login,
  Root,
  ComplianceRoot,
  PatientProfile,
  PatientConsultations,
  PatientRecords,
  PatientTrack,
  PatientRoot,
  QuestionnaireType,
  QuestionnaireForm,
  QuestionnaireList,
  ObservationQuestionnaireForm,
  PatientTodos,
  SeeMoreRecords,
} from "../containers/";
import { authSelector } from "../store/slice/auth.slice";
import ConsultationAddReminder from "../containers/view-patient/patient-consultations/consultations-add-reminder";
import { AddReminder, PatientEncounters, NewEncounter, AddObservation, AddPacs } from "../containers/view-patient";
import Terms from "../containers/terms/Terms"
import DeepgramLiveTranscription from "../components/audio/DeepgramLiveTranscription";
import InfoSection from "../components/InfoSection";
import ShareReport from "../components/shared/ShareReport";
import { SendRecords, AddRecords } from "../containers/";
import PatientEncounterHealthData from "../containers/view-patient/patient-consultations/PatientEncounterHealthData.jsx";
import TextEditor from "../components/shared/text-editor/index.jsx";

const router = createBrowserRouter([
  {
    path: "/compliance",
    element: <ComplianceRoot />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/terms-conditions",
    element: <Terms/>,
  },
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <PatientList />,
      },
      {
        path: "home",
        element: <PatientList />,
      },
      {
        path:"templates",
        element:<div style={{
          width: "80%",
          alignSelf: "center",
          margin: "0 auto",
        }}><TextEditor isReviewModal={false}/></div>
      },
    ],
  },
  {
    path: "/",
    element: <PatientRoot />,
    children: [
      {
        index: true,
        element: <PatientProfile />,
      },
      {
        path: "patient-profile",
        element: <PatientProfile />,
      },
      {
        path: "patient-consultations",
        element: <PatientConsultations />,
      },
      {
        path: "patient-records",
        element: <PatientRecords />,
      },
      {
        path: "patient-records/all-records",
        element: <SeeMoreRecords />,
      },
      {
        path: "patient-records/scan-info",
        element: <InfoSection />,
      },
      {
        path: "patient-records/share",
        element: <ShareReport />,
      },
      {
        path: "patient-reminders",
        element: <PatientTrack />,
      },
      {
        path: "patient-todos",
        element: <PatientTodos />,
      },
      {
        path: "encounters",
        element: <PatientEncounters />,
      },
      {
        path: "encounters-health-data",
        element: <PatientEncounterHealthData />,
      },
      {
        path: "new-encounter",
        element: <NewEncounter />,
      },
      {
        path: "add-reminder",
        element: <AddReminder />,
      },
      {
        path: "new-encounter/consultation/add-reminder",
        element: <ConsultationAddReminder/>,
      },
      {
        path: "patient-records/questionnaire-type",
        element: <QuestionnaireType />,
      },
      {
        path: "patient-records/questionnaire-list/questionnaire-type",
        element: <QuestionnaireType />,
      },
      {
        path: "patient-records/questionnaire-list",
        element: <QuestionnaireList />,
      },
      {
        path: "patient-records/questionnaire-type/questionnaire-form",
        element: <QuestionnaireForm />,
      },
      {
        path: "patient-records/questionnaire-list/questionnaire-type/questionnaire-form",
        element: <QuestionnaireForm />,
      },
      {
        path: "patient-records/add-observation",
        element: <AddObservation />,
      },
      {
        path: "patient-records/add-observation/observation-questionnaire-form",
        element: <ObservationQuestionnaireForm />,
      },
      {
        path: "patient-records/add-records",
        element: <AddRecords />,
      },
      {
        path: "patient-records/add-records/send-records",
        element: <SendRecords />,
      },
      {
        path: "patient-records/add-pacs",
        element: <AddPacs />,
      },
      {
        path: "new-encounter/add-records",
        element: <AddRecords />,
      },
      {
        path: "new-encounter/add-records/send-records",
        element: <SendRecords />,
      },
      {
        path: "patient-records/scan-info/add-records",
        element: <AddRecords />,
      },
      {
        path: "patient-records/scan-info/add-records/send-records",
        element: <SendRecords />,
      },
      {
        path: "/deepgram",
        element: <DeepgramLiveTranscription />
      }
    ],
  },
]);

const RootRouter = () => {
  const { isOTPVerified, userDetails } = useSelector(authSelector);
  const store = useStore();
  setupAxiosInstance(store);
  useEffect(() => {
    if (isOTPVerified && userDetails?.access_token) {
      redirect("/");
    } else {
      redirect("/login");
    }
  }, [isOTPVerified, userDetails]);

  return <RouterProvider router={router} />;
};

export default RootRouter;
