import React, { useState, useEffect } from "react";
import * as Styled from "./style.components";

const TextExpandModal = ({ open, fieldName, value, onClose, onSave }) => {
  const [textValue, setTextValue] = useState(value);
  
  useEffect(() => {
    if (open) {
      setTextValue(value);
    }
  }, [open, value]);

  if (!open) return null;

  return (
    <Styled.ModalOverlay>
      <Styled.ModalContent>
        <Styled.ModalHeaderContainer>
          <Styled.ModalHeader>{fieldName}</Styled.ModalHeader>
          <Styled.CloseButton onClick={onClose}>×</Styled.CloseButton>
        </Styled.ModalHeaderContainer>
        <Styled.TextArea
          value={textValue}
          onChange={(e) => setTextValue(e.target.value)}
          placeholder={`Enter ${fieldName.toLowerCase()}...`}
        />
        <Styled.ModalFooter>
          <Styled.SaveButton onClick={() => onSave(textValue)}>Save</Styled.SaveButton>
        </Styled.ModalFooter>
      </Styled.ModalContent>
    </Styled.ModalOverlay>
  );
};

export default TextExpandModal;
