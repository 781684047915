import { Box, Card, CardMedia, Grid, Typography } from "@mui/material";
import styled from "styled-components";
import DropdownThreeDotMenu from "../../shared/DropdownThreeDot";
import {
  formatTimestamp,
  removeSpecialCharacters,
} from "../../../utilities/common";

const CardHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  margin-bottom: 10px;
  padding-left: 2px;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    word-break: break-all;
    width: 100%;
    min-height: 18px;
  }
  .menubtn {
    position: absolute;
    z-index: 99;
    right: 0;
    top: 0;
  }
`;
const ListItem = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 10px 2px;
  cursor: pointer;
  border: none;
  position: relative;
  font-size: 11px;
  color: #808080;
`;

const getFileType = (contentType) => {
  if (!contentType) return "";

  const fileTypes = {
    "application/pdf": "PDF",
    "image/jpeg": "JPEG",
    "image/jpg": "JPG",
    "image/png": "PNG",
  };

  return fileTypes[contentType] || "";
};

const RecordItem = ({
  row,
  fetchUrlForFile,
  menuItems,
  setStoreData,
  reportType,
  isShowDropdown,
  index,
  thumbnailData,
  isNotShowDropdown=false,
  width=130,
}) => {
  return (
    <Grid
      item
      sx={{ width: width, cursor: "pointer" }}
      key={`${reportType}-${index}-${row?.id}`}
      onClick={fetchUrlForFile}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ paddingBottom: "2px" }}
      >
        <CardHeader
          title={
            removeSpecialCharacters(row?.patient_name) ||
            removeSpecialCharacters(row?.name) ||
            row?.title
          }
        >
          <span>
            {" "}
            {removeSpecialCharacters(row?.patient_name) ||
              removeSpecialCharacters(row?.name) ||
              row.title}
          </span>
        </CardHeader>
        {isShowDropdown && (
          <DropdownThreeDotMenu
            menuItems={menuItems}
            data={row}
            setStoreData={setStoreData}
            recordType={reportType}
          />
        )}
      </Box>
      <Card
        sx={{
          backgroundColor: "#ebf7fb",
          boxShadow: "none",
          borderRadius: "8px",
        }}
      >
        <CardMedia
          component="img"
          height="120"
          image={(thumbnailData && thumbnailData[row?.id]) || row?.icon}
          alt={"records-thumbnail"}
          sx={{
            objectFit: "contain",
            padding: "10px 5px",
            boxSizing: "border-box",
          }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = row?.icon;
          }}
        />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              backgroundColor: "#2faed7;",
              color: "white",
              width: "100%",
              padding: "6px 8px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "80px",
              fontSize: "12px",
            }}
            title={row?.test_type || row?.modality}
          >
            {row?.test_type ||
              row?.modality ||
              reportType.charAt(0).toUpperCase() + reportType.slice(1)}
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              backgroundColor: "#0099cc",
              color: "white",
              padding: "6px 8px",
              fontSize: "12px",
              width: "30%",
            }}
          >
            {reportType === "scan" ? "DCM" :  getFileType(row?.content_type)}
          </Box>
        </Box>
      </Card>
      <div>
        <ListItem>
          {" "}
          {(row?.study_date && formatTimestamp(row.study_date)) ||
            (row?.upload_date && formatTimestamp(row.upload_date))}
        </ListItem>
      </div>
    </Grid>
  );
};

export default RecordItem;
