import styled from "styled-components";
import { TextField, Typography, FormControl } from "@mui/material";
import {
  DEVICE,
  FONT_FAMILY_GENERAL,
} from "../../../utilities/constants";

export const StyledTextField = styled(TextField)`
  && {
    background-color: #EBF7FB;
    border-radius: 8px;
    width: 100%;
    border: none;
    font-size: 16px;
    .MuiOutlinedInput-input {
      border: none;
      font-size: 16px;
      @media(max-width:650px){
        font-size: 14px;
      }
    .MuiOutlinedInput-root {
      fieldset,
      &.Mui-focused fieldset {
        border: none;
      }
    }
  }
`;

export const StyledLabel = styled.div`
  color: #808080;
  font-size: 16px;
  margin-bottom: 8px;
  margin-top: 16px;
`;

export const MobileNumWrapper = styled.div`
  display: flex;
`;

export const ModalHeader = styled(Typography)`
  color: #0099CC;
  font-size: 18px !important;
  margin-bottom: 40px !important;
`;

export const ModalBody = styled.div`
  padding-right: 32px;
`;

export const StyledFormControl = styled(FormControl)`
  .MuiInput-underline:after, .MuiInput-underline:before {
    display: none;
  }

  .MuiInputBase-input {
    border-radius: 8px;
    background-color: #EBF7FB;
    @media(max-width:650px){
      font-size: 14px;
    }
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
`;
export const CloseButton = styled.img`
  cursor: pointer;
`;
export const CloseButtonWrapper = styled.div`
  justify-content: flex-end;
  align-items: center;
  display: flex;
  background: transparent;
`;
export const SaveButton = styled.button`
  width: 150px;
  height: 40px;
  background-color: ${(props) => (props.disabled ? '#cccccc' : '#7aba56')}; /* Grey color when disabled */
  color: ${(props) => (props.disabled ? '#666666' : 'white')}; /* Darker text color when disabled */
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')}; /* Default cursor when disabled */
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')}; /* Disable click events when button is disabled */
`;

export const CustomSelect = styled.select({
  fontFamily: FONT_FAMILY_GENERAL,
  backgroundColor: "#EBF7FB",
  fontSize: "16px",
  color: "#000000",
  border: "none",
  borderRadius: "8px",
  height: "41px",
  flexGrow: 1,
  paddingLeft: "10px",
  marginRight: "10px",
  option: {
    width: "30px",
  },
  [`@media ${DEVICE.mobileL}`]: {
    fontSize: "12px",
  },
});
