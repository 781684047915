import React, { useState, useEffect } from 'react';
import { IHemeCloudLogo, IHamburgerIcon,ILogoWhite,hamburgerMenuIcon  } from "../../../assets";
import ProfileDropdown from "./profile-dropdown";
import SideNav from "./side-nav";
import { LogoImage, LogoImageCont, StickyHeader, HamburgerIconCont, NavTabs } from "./style.components";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelector } from "../../../store/slice/auth.slice";

const TABS = [
  {
    label: "Patients",
    path: "/home",
  },
  {
    label: "Templates",
    path: "/templates",
  }
];

const FixedHeader = ({HamburgerMenuToggle}) => {

  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { selectedCountry } = useSelector(authSelector);
  const { userDetails } = useSelector(authSelector);

  const showTabs = location.pathname === "/" || location.pathname === "/home" || location.pathname === "/templates";

  const isHome = location.pathname === "/" || location.pathname === "/home";

  useEffect(() => {
    const userName = `${userDetails?.first_name}${+ " " + userDetails?.last_name ? userDetails?.last_name : ""}`;
    //@ts-ignore
    window["SI_API"]?.hidePopup();
    //@ts-ignore
    window?.["setDetails"](
      userName || "",
      userDetails?.email || "",
      "Query? Let us know how can we help you?"
    )
  });

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <>
      <StickyHeader>
        <LogoImageCont>
          <LogoImage src={IHemeCloudLogo} onClick={() => navigate("/home")} />
        </LogoImageCont>
        {
          showTabs && (
            <NavTabs>
              {TABS.map((tab, i) => {
                return (
                  <NavLink
                    key={tab.path}
                    style={({ isActive, isPending }) => {
                      return {
                        textDecoration: "none",
                        color: `${isActive ? '#FFFFFF' : '#000000'}`,
                        fontSize: "16px",
                        backgroundColor: `${isActive ? '#0099CC' : '#F5FBFC'}`,
                        borderRadius: `${isActive ? '8px' : '0'}`,
                        padding: "14px 16px",
                      };
                    }}
                    to={tab.path}
                  >
                    <div>{tab.label}</div>
                  </NavLink>
                );
              })}
            </NavTabs>
          )
        }
        <ProfileDropdown />
        {!isHome && (
          <HamburgerIconCont onClick={toggleSidebar}>
            <img src={IHamburgerIcon} alt="Menu" />
          </HamburgerIconCont>
        )}
        <SideNav isOpen={isSidebarOpen} closeSidebar={closeSidebar} />
      </StickyHeader>
    </>
  );
};

export default FixedHeader;
