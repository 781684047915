import styled from "styled-components";
import { TableRow as MuiTableRow } from "@mui/material";

// Media query breakpoints
const breakpoints = {
  mobile: "max-width: 600px",
  tablet: "max-width: 900px",
  desktop: "min-width: 901px",
};

export const Container = styled.div`
  border-radius: 12px;
  background-color: #F5FBFC;
  opacity: 1;
  min-height: calc(100vh - 72px);
  margin-bottom: 0;
  overflow-y: auto;

  @media (${breakpoints.mobile}) {
    margin: 16px 24px;
    min-height: calc(100vh - 50px);
  }

  @media (${breakpoints.tablet}) {
    margin: 20px 32px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  @media (${breakpoints.mobile}) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
  }
`;

export const Header = styled.div`
  font-size: 24px;
  color: #000000;

  @media (${breakpoints.mobile}) {
    font-size: 18px;
  }
`;

export const HeaderLeft = styled.div`
  display: flex;
  flex: 0.4;

  @media (${breakpoints.mobile}) {
    flex: 1;
    justify-content: space-between;
    width: 100%;
  }
`;

export const HeaderRight = styled.div`
  display: flex;
  flex: 0.6;
  justify-content: flex-end;
  align-items: center;

  @media (${breakpoints.mobile}) {
    justify-content: flex-start;
    margin-top: 10px;
    flex: 1;
    width: 100%;
  }
`;

export const AddButton = styled.button`
  padding: 10px;
  width: 150px;
  height: 40px;
  background: #7aba56 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #ffffff;
  margin-bottom: 20px;
  outline: none;
  border-style: hidden;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;

  @media (${breakpoints.mobile}) {
    width: 120px;
    height: 35px;
    font-size: 14px;
  }
`;

export const EditButton = styled.button`
  color: #7aba57;
  font-size: 14px;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 15px;

  @media (${breakpoints.mobile}) {
    margin-right: 10px;
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: #ebf7fb 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 5px 12px;
  width: 400px;
  height: 35px;
  margin-top: 10px;
  border-style: hidden;

  @media (${breakpoints.mobile}) {
    width: 100%;
    margin-top: 5px;
  }

  @media (${breakpoints.tablet}) {
    width: 300px;
  }
`;

export const ProfileImage = styled.img`
  margin-right: 10px;
  width: 50px;
  height: 50px;

  @media (${breakpoints.mobile}) {
    width: 40px;
    height: 40px;
  }
`;

export const EmptyListMessage = styled.div`
  margin-top: 10px;
  padding: 20px;
  color: red;
  text-align: center;

  @media (${breakpoints.mobile}) {
    font-size: 14px;
    padding: 15px;
  }
`;

export const EditDeleteWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;

  @media (${breakpoints.mobile}) {
    justify-content: flex-start;
  }
`;

export const FullNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  @media (${breakpoints.mobile}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const CaseNumber = styled.span`
  color: #000000;
  font-size: 16px;
  margin-right: 10px;
  font-weight: bold;

  @media (${breakpoints.mobile}) {
    font-size: 14px;
  }
`;

export const UpdatedDate = styled.span`
  color: #808080;
  font-size: 14px;

  @media (${breakpoints.mobile}) {
    font-size: 12px;
  }
`;

export const StatusText = styled.span`
  color: ${props => (props.status === 'Ongoing' ? '#7ABA56' : props.status === 'Finished' ? '#CC8552' : '#000000')};
  margin-left: 5px;

  @media (${breakpoints.mobile}) {
    font-size: 12px;
  }
`;

export const EncountersText = styled.span`
  color: #0099cc;
  font-size: 14px;
  margin-left: 5px;

  @media (${breakpoints.mobile}) {
    font-size: 12px;
  }
`;

export const EncountersCount = styled.span`
  background-color: #d7eff7;
  font-size: 14px;
  color: #0099cc;
  border-radius: 5px;
  border: 1px solid;
  outline: none;
  border-style: hidden;
  padding: 7px 12px;

  @media (${breakpoints.mobile}) {
    padding: 5px 10px;
    font-size: 12px;
  }
`;

export const CustomTableRow = styled(MuiTableRow)`
  background-color: #ffffff;
  border: none;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: pointer;

  @media (${breakpoints.mobile}) {
    flex-direction: column;
    margin-bottom: 12px;
    margin-top: 12px;
  }
`;

export const ActionImage = styled.img`
  margin-right: 5px;
`;