import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Grid, Button, Box } from "@mui/material";
import hexToRgba from "hex-to-rgba";
import moment from "moment";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { GlobalWorkerOptions } from "pdfjs-dist";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShimmerContentBlock } from "react-shimmer-effects";
import {
  getRecords,
  getChartRecords,
  changeStatusEncounter,
  getStatusOfEncounters,
} from "../../../../api/records.api";
import { SnackBar } from "../../../../components/shared";
import { getRecordTypeAction } from "../../../../store/actions";
import {
  getReportURL,
  getThumbnails,
} from "../../../../store/actions/records.action";
import { recordTypeSelector } from "../../../../store/slice/record-type.slice";
import {
  Container,
  EmptyListMessage,
  Header,
  HeaderWrapper,
  ListDateTitle,
  ListItemDate,
  ListItemWrapper,
  ListWrapper,
  LoaderWrapper,
  StyledCloseButton,
  StyledImage,
  StyledModal,
  StyledModalContent,
  ListItemTop,
  ListSectionQuestionnaire,
  ListDateTitlQuestionnaire,
  SeeMoreWrapper,
  SeeMoreText,
  NextArrow,
} from "./style.components";
import { INextArrow } from "../../../../assets";
import Breadcrumb from "../../../../components/layout/Breadcrumb";
import RecordItem from "../../../../components/layout/RecordItem/RecordItem";
import { setSelectedPatient } from "../../../../store/slice/patient.slice";
import ConfirmationBox from "../../../../components/InfoSection/ConfirmationBox";

const workerUrl = `//${window.location.host}/pdf.worker.min.js`;
GlobalWorkerOptions.workerSrc = workerUrl;

const OBSERVATION_RECORDS = [
  "blood_pressure",
  "blood_sugar",
  "pain_level",
  "temperature",
  "weight",
];

const PatientEncounterHealthData = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const patientId = useSelector((state) => state.patient.selectedPatientId);
  const selectedPatientId = searchParams.get("patient_id") || patientId;
  const [open, setOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [recordTypesArray, setRecordTypesArray] = useState([]);
  const recordTypes = useSelector((state) => recordTypeSelector(state));
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [itemsToShow, setItemsToShow] = useState(
    getItemsToShow(window.innerWidth)
  );
  const screenWidth = window.innerWidth;
  const isMobileWidth = screenWidth <= 768;
  const [overallLoading, setOverallLoading] = useState(true);
  const [storeData, setStoreData] = useState(null);
  const [isloadingThumbnail, setIsLoadingThumbnail] = useState(false);
  const thumbnails = useSelector((state) => state?.thumbnail?.thumbnails);
  const [tempAccordionData, setTempAccrodionData] = useState({});
  const [encounterId, setEncounterId] = useState(null);
  const [encounterStatus, setEncountersStatus] = useState(null);
  const [observationRecordTypes, setObservationRecordTypes] = useState([]);
  const [observationData, setObservationData] = useState({});
  const [issStatusChangeLoading, setIsStatusChangeLoading] = useState(false);
  const [isShowChangeStatus, setShowChangeStatus] = useState(false);
  const [consultationId, setConsultationId] = useState(null);

  function getItemsToShow(width) {
    if (width >= 1050) {
      return 5;
    } else if (width >= 1005) {
      return 3;
    } else if (width >= 850) {
      return 2;
    } else if (width >= 767) {
      return 1;
    } else if (width >= 650) {
      return 3;
    } else if (width >= 506) {
      return 2;
    } else {
      return 1;
    }
  }

  useEffect(() => {
    setEncounterId(location?.state?.id);
    setConsultationId(location?.state?.consultationId);
  }, [location]);

  const handleHomeNavigation = (event) => {
    event.preventDefault();
    navigate("/");
  };

  const breadCrubLinks = [
    {
      label: "Home",
      onClick: handleHomeNavigation,
    },
    {
      label: "Consulatations",
      onClick: () => {
        navigate(`/patient-consultations?patient_id=${selectedPatientId}`);
      },
    },
    {
      label: `Case No. ${consultationId}`,
      onClick: () => {
        navigate(`/encounters`, { state: { id: consultationId } });
      },
    },
    {
      label: `Encounter No. ${encounterId}`,
    },
  ];

  useEffect(() => {
    function handleResize() {
      const newItemsToShow = getItemsToShow(window.innerWidth);
      if (newItemsToShow !== itemsToShow) {
        setItemsToShow(newItemsToShow);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  const fetchReportUrl = async (filePath) => {
    try {
      const payload = {
        file_path: filePath,
      };
      const response = await dispatch(getReportURL({ payload: payload }));
      if (response?.payload?.status === "success") {
        return response?.payload?.data;
      } else {
        return "";
      }
    } catch (error) {
      console.log("error occured while fetching url");
      return "";
    }
  };

  const fetchStatusOfEncounter = async () => {
    try {
      const response = await getStatusOfEncounters({
        patient_id: selectedPatientId,
        episode_of_care_id: consultationId,
        encounter_id: encounterId,
      });
      if (response?.status === "success") {
        setEncountersStatus(response?.data?.status);
      } else {
        return "";
      }
    } catch (error) {
      console.log("error occured while fetching url");
      return "";
    }
  };

  useEffect(() => {
    if (encounterId && consultationId) fetchStatusOfEncounter();
  }, [encounterId, consultationId]);

  useEffect(() => {
    if (!recordTypes?.length) dispatch(getRecordTypeAction(selectedPatientId));
  }, [selectedPatientId, dispatch]);

  useEffect(() => {
    const fetchAllRecords = async (isPageRefresh = false) => {
      recordTypes.forEach(async ({ record_type }) => {
        try {
          await fetchRecords(record_type, false, isPageRefresh);
        } catch (error) {
          console.error(`Error fetching records for ${record_type}:`, error);
        }
      });
    };
    if (encounterId) fetchAllRecords(false);
  }, [recordTypesArray, selectedPatientId, encounterId]);

  const getObservationRecords = useCallback(
    async (r) => {
      const response = await getChartRecords(r, selectedPatientId);
      const result = response?.result;
      let dataset = [];
      result?.datasets?.forEach((item) => {
        let data = [];
        item?.data?.forEach((values) => {
          data.push({
            y: values?.value,
            x: moment(values.timestamp).format("YYYY-MM-DD HH:mm"),
          });
        });
        dataset.push({
          label: item.label,
          borderColor: item.color,
          data,
          backgroundColor: hexToRgba(item.color, "0.5"),
        });
      });
      observationData[r] = dataset;
      setObservationData(observationData);
    },
    [observationData, selectedPatientId]
  );

  useEffect(() => {
    if (recordTypes && recordTypes?.length) {
      setRecordTypesArray(recordTypes);
    }
  }, [recordTypes]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchThumbnailImages(true);
    }, 300000);

    return () => clearInterval(interval);
  }, [dispatch]);

  const initialState = recordTypesArray?.reduce((acc, { record_type }) => {
    acc[record_type] = "loading";
    return acc;
  }, {});
  const [loadedAccordions, setLoadedAccordions] = useState(initialState);

  const extractIdAndFilePath = () => {
    const result = [];
    Object.keys(tempAccordionData)?.length &&
      Object.keys(tempAccordionData)?.forEach((category) => {
        if (
          category === "scans" ||
          category === "questionnaire" ||
          category === "questionnaire"
        )
          return;
        tempAccordionData[category]?.data.length &&
          tempAccordionData[category]?.data.forEach((item) => {
            if (item.id && item.file_path) {
              result.push({
                id: item.id,
                file_path: item.file_path,
                record_type: category,
              });
            }
          });
      });
    return result;
  };

  const fetchThumbnailImages = async (isResetThumbnail = false) => {
    const data = extractIdAndFilePath();
    const filePaths = data
      .filter(
        (item) =>
          item?.file_path && (isResetThumbnail || !thumbnails?.[item.id])
      )
      .map((item) => ({
        file_path: item.file_path,
        fhir_id: item.id,
      }));
    filePaths.length &&
      dispatch(
        getThumbnails({
          file_objects: filePaths,
        })
      ).then(() => {
        setIsLoadingThumbnail(!isloadingThumbnail);
      });
  };

  useEffect(() => {
    if (
      tempAccordionData &&
      Object.keys(tempAccordionData).length > 0 &&
      Object.keys(tempAccordionData).length === recordTypesArray.length
    ) {
      fetchThumbnailImages(true);
    }
  }, [tempAccordionData]);

  useEffect(() => {
    dispatch(setSelectedPatient({ id: selectedPatientId }));
  }, []);

  useEffect(() => {
    const isAnyLoading = Object.values(loadedAccordions).some(
      (status) => status === "loading"
    );
    setOverallLoading(isAnyLoading);
  }, [loadedAccordions]);

  const fetchRecords = (recordType, isLoad = false, isPageRefresh = false) => {
    return new Promise(async (resolve, reject) => {
      if (loadedAccordions[recordType] && !isLoad) return resolve();
      !isPageRefresh &&
        setLoadedAccordions((prev) => ({ ...prev, [recordType]: "loading" }));
      if (selectedPatientId) {
        try {
          const action = await getRecords({
            record_type: recordType,
            patient_id: patientId,
            encounter_id: encounterId,
            limit: 5,
          });
          if (action?.status === "success") {
            setTempAccrodionData((prev) => ({
              ...prev,
              [recordType]: { data: action?.data, total: action?.total },
            }));
            setLoadedAccordions((prev) => ({
              ...prev,
              [recordType]: "loaded",
            }));
          }
          resolve();
        } catch (error) {
          console.error("Error fetching records:", error);
          setLoadedAccordions((prev) => ({ ...prev, [recordType]: "error" }));
          reject(error);
        }
      } else {
        resolve();
      }
    });
  };

  const showEmptyMessage = Object.values(tempAccordionData).every(
    (value) => Array.isArray(value?.data) && value?.data.length === 0
  );

  const handleListItemClick = async (record) => {
    if (record?.record_type === "scans") {
      const url = isMobileWidth
        ? record?.mobile_viewer_url
        : record?.dicom_viewer_url;
      const newWindow = window.open("", "_blank");
      if (newWindow) newWindow.location.href = url;
    } else if (record?.record_type === "questionnaire") {
      navigate("questionnaire-type/questionnaire-form", {
        state: { filled_form_id: record.id },
      });
    } else if (record?.name === "Notes") {
      return;
    } else if (
      record?.record_type !== "questionnaire" ||
      record?.record_type !== "scans" ||
      record?.record_type !== "notes"
    ) {
      const url = await fetchReportUrl(record?.file_path);
      url && setSelectedRecord({ ...record, url: url });
      setOpen(true);
    }
  };

  const closeModal = () => {};

  const handleScanClick = (fhirId) => {
    navigate("scan-info", {
      state: {
        studyFhirId: fhirId,
      },
    });
  };

  const renderListSection = (recordType, name) => {
    return (
      <ListDateTitle>
        {name}{" "}
        {tempAccordionData[recordType]?.total
          ? `(${tempAccordionData[recordType].total})`
          : ""}
      </ListDateTitle>
    );
  };

  const renderQuestionnaireSection = (index) => {
    const convertedData =
      tempAccordionData &&
      tempAccordionData["questionnaire"]?.data?.length &&
      tempAccordionData["questionnaire"].data.flatMap((item) =>
        item.questionnaire_response_ids.map((response) => ({
          ...response,
          parent_id: item.id,
          name: item.title,
          upload_date: response.last_updated,
        }))
      );
    return (
      <ListWrapper key={index}>
        <ListSectionQuestionnaire>
          <ListDateTitlQuestionnaire>
            Questionnaire{" "}
            {convertedData?.length ? `(${convertedData.length})` : null}
          </ListDateTitlQuestionnaire>
        </ListSectionQuestionnaire>
        <Grid container gap={2} mt={4}>
          {tempAccordionData &&
            tempAccordionData["questionnaire"]?.data?.length &&
            convertedData
              ?.slice(0, itemsToShow)
              .map((row, formIndex) => (
                <RecordItem
                  row={row}
                  fetchUrlForFile={() => {
                    const rowWithRecordType = {
                      ...row,
                      record_type: "questionnaire",
                    };
                    handleListItemClick(rowWithRecordType);
                  }}
                  menuItems={[]}
                  reportType={"questionnaire"}
                  isShowDropdown={false}
                  index={index}
                />
              ))
              .concat(
                tempAccordionData["questionnaire"]?.total > itemsToShow && (
                  <ListItemWrapper key="see-more">
                    <ListItemTop style={{ visibility: "hidden" }}>
                      hidden
                    </ListItemTop>
                    <SeeMoreWrapper
                      onClick={() => navigate("questionnaire-list")}
                    >
                      <SeeMoreText>See More</SeeMoreText>
                      <NextArrow src={INextArrow} />
                    </SeeMoreWrapper>
                    <ListItemDate style={{ visibility: "hidden" }}>
                      hidden
                    </ListItemDate>
                    <ListItemDate style={{ visibility: "hidden" }}>
                      hidden
                    </ListItemDate>
                  </ListItemWrapper>
                )
              )}
        </Grid>
      </ListWrapper>
    );
  };

  const renderSection = (record_type, name) => {
    // Adjust this to match how you render each section of your application
    switch (record_type) {
      case "scans":
        return (
          <Grid container gap={2} mt={4}>
            {tempAccordionData[record_type]?.data?.length > 0 &&
              tempAccordionData[record_type]?.data
                ?.slice(0, itemsToShow)
                ?.map((row, index) => (
                  <RecordItem
                    reportType={"scan"}
                    fetchUrlForFile={() => handleScanClick(row?.id)}
                    setStoreData={setStoreData}
                    isShowDropdown={false}
                    menuItems={[]}
                    row={row}
                    index={index}
                  />
                ))
                .concat(
                  tempAccordionData[record_type]?.total > itemsToShow && (
                    <ListItemWrapper key="see-more">
                      <ListItemTop style={{ visibility: "hidden" }}>
                        hidden
                      </ListItemTop>
                      <SeeMoreWrapper
                        onClick={() => {
                          navigate("all-records", {
                            state: {
                              recordType: record_type,
                              recordName: name,
                              patientID: selectedPatientId,
                            },
                          });
                        }}
                      >
                        <SeeMoreText>See More</SeeMoreText>
                        <NextArrow src={INextArrow} />
                      </SeeMoreWrapper>
                      <ListItemDate style={{ visibility: "hidden" }}>
                        hidden
                      </ListItemDate>
                      <ListItemDate style={{ visibility: "hidden" }}>
                        hidden
                      </ListItemDate>
                    </ListItemWrapper>
                  )
                )}
          </Grid>
        );
      case "blood_pressure":
        return (
          <Grid container gap={2} mt={4}>
            {tempAccordionData[record_type]?.data?.length > 0 &&
              (() => {
                const components =
                  tempAccordionData[record_type]?.data[0]?.components || [];

                const systolic =
                  components.find((c) => c.name === "Systolic")?.value || "0";
                const diastolic =
                  components.find((c) => c.name === "Diastolic")?.value || "0";

                const pulse = components.find((c) => c.name === "Pulse");

                const commonBoxStyles = {
                  display: "flex",
                  borderRadius: 1,
                  border: "2px solid #F0F0F0",
                  padding: 1,
                  minWidth: 150,
                  height: 30,
                  alignItems: "center",
                  gap: 1,
                };

                return (
                  <Box display="flex" gap={2} alignItems="center">
                    <Box sx={commonBoxStyles}>
                      <img
                        src={tempAccordionData[record_type]?.data[0]?.icon}
                        height={35}
                        alt="icon"
                      />
                      <Box sx={{ fontSize: "18px", fontWeight: 600 }}>
                        {`${systolic}/${diastolic} mmHg`}
                      </Box>
                    </Box>

                    {/* Box for Pulse */}
                    {pulse && (
                      <Box sx={{ ...commonBoxStyles }}>
                        <img
                          src={tempAccordionData[record_type]?.data[0]?.icon}
                          height={35}
                          alt="icon"
                        />
                        <Box sx={{ fontSize: "18px", fontWeight: 600 }}>
                          {`${pulse.value} ${pulse.unit}`}
                        </Box>
                      </Box>
                    )}
                  </Box>
                );
              })()}
          </Grid>
        );
      case "temperature":
      case "blood_sugar":
      case "pain_level":
      case "weight":
        return (
          <Grid container gap={2} mt={4}>
            {tempAccordionData[record_type]?.data?.length > 0 &&
              tempAccordionData[record_type]?.data[0]?.components?.map(
                (row, index) => (
                  <Box
                    display="flex"
                    sx={{
                      borderRadius: 1,
                      border: "2px solid #F0F0F0",
                      padding: 1,
                      minWidth: 150,
                    }}
                    alignItems="center"
                    key={`box-${record_type}-${index}`}
                    gap={1}
                  >
                    <img
                      src={tempAccordionData[record_type]?.data[0]?.icon}
                      height={35}
                    />
                    <Box sx={{ fontSize: "18px", fontWeight: 600 }}>
                      {`${row?.value}  ${row?.unit}`}
                    </Box>
                  </Box>
                )
              )}
          </Grid>
        );
      default:
        return (
          <Grid container gap={2} mt={4}>
            {tempAccordionData[record_type]?.data?.length > 0
              ? tempAccordionData[record_type].data
                  ?.slice(0, itemsToShow)
                  ?.map((row, index) => (
                    <RecordItem
                      reportType={record_type}
                      fetchUrlForFile={() => handleListItemClick(row)}
                      setStoreData={setStoreData}
                      menuItems={[]}
                      isShowDropdown={false}
                      row={row}
                      index={index}
                      thumbnailData={thumbnails}
                    />
                  ))
                  .concat(
                    tempAccordionData[record_type]?.total > itemsToShow && (
                      // Additional ListItemWrapper for the "See More" functionality
                      <ListItemWrapper key="see-more">
                        <ListItemTop style={{ visibility: "hidden" }}>
                          hidden
                        </ListItemTop>
                        <SeeMoreWrapper
                          onClick={() => {
                            navigate("all-records", {
                              state: {
                                recordType: record_type,
                                recordName: name,
                                patientID: selectedPatientId,
                              },
                            });
                          }}
                        >
                          <SeeMoreText>See More</SeeMoreText>
                          <NextArrow src={INextArrow} />
                        </SeeMoreWrapper>
                        <ListItemDate style={{ visibility: "hidden" }}>
                          hidden
                        </ListItemDate>
                        <ListItemDate style={{ visibility: "hidden" }}>
                          hidden
                        </ListItemDate>
                      </ListItemWrapper>
                    )
                  )
              : null}
          </Grid>
        );
    }
  };

  const renderSectionsWithLoadingIndicator = () => {
    const questionnaireDataLoaded =
      !isLoading &&
      tempAccordionData &&
      tempAccordionData["questionnaire"]?.data?.length > 0;
    return (
      <>
        {tempAccordionData &&
          !overallLoading &&
          recordTypesArray.map(({ record_type, name }) => {
            if (record_type === "questionnaire") return null;

            const sectionData = tempAccordionData[record_type]?.data;
            const isLoading = loadedAccordions[record_type] === "loading";
            const hasData = sectionData && sectionData?.length > 0;

            return (
              <React.Fragment key={`record_type-${name}`}>
                {!isLoading && hasData && tempAccordionData && (
                  <ListWrapper>
                    {renderListSection(record_type, name)}
                    <div>{renderSection(record_type, name)}</div>
                  </ListWrapper>
                )}
              </React.Fragment>
            );
          })}
        {questionnaireDataLoaded && renderQuestionnaireSection()}

        {overallLoading && (
          <LoaderWrapper>
            <Container>
              <ShimmerContentBlock
                title
                text
                cta
                thumbnailWidth={82}
                thumbnailHeight={82}
              />
            </Container>
          </LoaderWrapper>
        )}
      </>
    );
  };

  const handleEncounterClose = async () => {
    const payload = {
      patient_id: selectedPatientId,
      encounter_id: encounterId,
      set_to_close: true,
    };
    try {
      await changeStatusEncounter({ payload });
      setEncountersStatus("close");
    } catch (err) {
      console.log("error occured while changing status of encounter");
    }
    setShowChangeStatus(false);
    setIsStatusChangeLoading(false);
  };

  return (
    <Container>
      <Breadcrumb links={breadCrubLinks} />
      <>
        <HeaderWrapper>
          <Header>Encounter {encounterId}</Header>
          {encounterStatus === "open" && (
            <Button
              sx={{
                color: "#FFF",
                background: "#CC8552",
                "&:hover": {
                  background: "#CC8552",
                  color: "#FFF",
                },
              }}
              variant="contained"
              onClick={() => setShowChangeStatus(true)}
            >
              Close Encounter
            </Button>
          )}

          {encounterStatus === "closed" && (
            <Button
              sx={{
                color: "#CC8552",
                border: "1px solid #CC8552",
                background: "#FFF",
                "&:hover": {
                  color: "#CC8552",
                  background: "#FFF",
                },
                "&.Mui-disabled": {
                  opacity: 1,
                  background: "#FFF",
                  color: "#CC8552",
                  border: "1px solid #CC8552",
                },
              }}
              variant="outlined"
              disabled
            >
              Status: Encounter closed
            </Button>
          )}
        </HeaderWrapper>

        {encounterStatus === "open" && (
          <Box
            sx={{ padding: "20px", borderRadius: "0.8rem", background: "#FFF" }}
          >
            This encounter is open.
          </Box>
        )}

        {showEmptyMessage && !overallLoading ? (
          <EmptyListMessage>No records found</EmptyListMessage>
        ) : (
          <>{renderSectionsWithLoadingIndicator()}</>
        )}
      </>
      <ConfirmationBox
        title={"Are you Sure?"}
        description={"You want to close the encounter."}
        closeHandler={() => {
          setIsStatusChangeLoading(false);
          setShowChangeStatus(false);
        }}
        open={isShowChangeStatus}
        onConfirm={handleEncounterClose}
        isLoding={issStatusChangeLoading}
        status={"closed"}
      />
      <StyledModal open={open} onClose={closeModal}>
        <StyledModalContent>
          <StyledCloseButton onClick={() => setOpen(false)}>
            &times;
          </StyledCloseButton>
          {selectedRecord &&
            (["image/jpeg", "image/png"].includes(
              selectedRecord.content_type
            ) || selectedRecord?.record_type === "image" ? (
              <StyledImage
                src={selectedRecord?.url}
                alt={selectedRecord?.name}
              />
            ) : (
              <Worker workerUrl={workerUrl}>
                <Viewer fileUrl={selectedRecord?.url} />
              </Worker>
            ))}
        </StyledModalContent>
      </StyledModal>
      <SnackBar />
    </Container>
  );
};

export default PatientEncounterHealthData;
