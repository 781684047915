import styled from "styled-components";

export const LoaderDiv = styled.div((props) => ({
  width: "100%",
  height: props.height ? props.height : "300px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}));
