import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Styled from "./style.components";
import { 
    IBackArrow
} from "../../../assets";
import { ShimmerPostDetails } from "react-shimmer-effects";
import { getFormFilledAction, getFormTemplateAction, sendFormAction, getRecordsAction } from "../../../store/actions";
import { patientSelector } from "../../../store/slice/patient.slice";
import { SnackBar } from "../../../components/shared";
import ObservationQuestionnaireType from "../observation-questionnaire-type";

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'short' });
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};

const ObservationQuestionnaireForm = ({ formType, onBack }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [formTemplateArray, setFormTemplateArray] = useState([]);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
    const { selectedPatientId } = useSelector(patientSelector);
    const [formInputs, setFormInputs] = useState({});
    const [selectedOption, setSelectedOption] = useState({});

    useEffect(() => {
        if (formType) {
            fetchFormTemplate();
        }
    }, [formType, dispatch]);     

    // useEffect(() => {
    //     if (formTemplateArray.length > 0) {
    //         const initialFormInputs = {};
    //         const initialSelectedOptions = {};
    //         const initialSelectedCheckboxes = {};
    
    //         formTemplateArray.forEach(element => {
    //             if (element.answer_type === 'choice') {
    //                 initialSelectedOptions[element.id] = element.answer[0];
    //             } else if (element.answer_type === 'open-choice') {
    //                 // Initialize checkboxes state based on the answers
    //                 initialSelectedCheckboxes[element.id] = element.answer_option.reduce((acc, option) => {
    //                     acc[option.valueString] = element.answer.includes(option.valueString);
    //                     return acc;
    //                 }, {});
    //             } else if (element.answer_type === 'date') {
    //                 initialFormInputs[element.id] = element.answer[0];
    //             } else {
    //                 initialFormInputs[element.id] = element.answer[0];
    //             }
    //         });
    
    //         setFormInputs(initialFormInputs);
    //         setSelectedOption(initialSelectedOptions);
    //         setSelectedCheckboxes(initialSelectedCheckboxes);
    //     }
    // }, [formTemplateArray]);
    
    const determineIfColumnFlexNeeded = (element) => {
        return (element.answer_type === 'choice' || element.answer_type === 'open-choice') && (
            element.answer_option.some(option => option.valueString && option.valueString.split(' ').length > 1) ||
            new Set(element.answer_option.map(option => option.valueString)).size > 3
        );
    };
    
    const fetchFormTemplate = async () => {
        setIsLoading(true);
        try {
            const response = await dispatch(getFormTemplateAction(formType?.formId)).unwrap();
            setFormTemplateArray(response?.result || []);
        } catch (error) {
            console.error("Failed to fetch form template:", error);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <ShimmerPostDetails card cta variant="SIMPLE" />;
    }

    const handleRadioChange = (id, value) => {
        setSelectedOption(prev => ({
            ...prev,
            [id]: value
        }));
    };
    
    const handleCheckboxChange = (id, value) => {
        setSelectedCheckboxes(prev => ({
            ...prev,
            [id]: {
                ...prev[id],
                [value]: !prev[id]?.[value]
            }
        }));
    };    

    const handleInputChange = (id, value) => {
        setFormInputs(prev => ({
            ...prev,
            [id]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);
        let isFormValid = true;
        const newFormInputs = {};

    for (const element of formTemplateArray) {
        let inputValue;

        switch (element.answer_type) {
            case 'string':
                inputValue = formInputs[element.id]?.trim();
                break;
            case 'choice':
                inputValue = selectedOption[element.id];
                break;
            case 'date':
                // Assuming date inputs are correctly formatted strings
                inputValue = formInputs[element.id];
                break;
            case 'open-choice':
                // For open-choice, you might be handling it differently
                inputValue = Object.keys(selectedCheckboxes[element.id] || {})
                                   .some(key => selectedCheckboxes[element.id][key]);
                break;
            case 'boolean':
                // Boolean values should be directly checked
                inputValue = formInputs[element.id];
                break;
            default:
                inputValue = formInputs[element.id];
        }

        if (element.is_required && (inputValue === undefined || inputValue === '')) {
            console.log('Validation failed for:', element.id);
            isFormValid = false;
        } else {
            newFormInputs[element.id] = inputValue;
        }
    }

    if (!isFormValid) {
        console.log('Form is not valid');
        setIsLoading(false);
        setFormInputs(newFormInputs);
        return;
    }


        try {
            const form_data = formTemplateArray.map(element => {
                let answer = [];
                if (element.answer_type === 'choice') {
                    if (selectedOption[element.id]) {
                        answer = [selectedOption[element.id]];
                    }
                } else if (element.answer_type === 'open-choice') {
                    answer = Object.keys(selectedCheckboxes[element.id] || {})
                                   .filter(key => selectedCheckboxes[element.id][key]);
                } else if (element.answer_type === 'date') {
                    if (formInputs[element.id]) {
                        answer = [formatDate(formInputs[element.id])];
                    }
                } else {
                    if (formInputs[element.id]) {
                        answer = [formInputs[element.id]];
                    }
                }
                return {
                    link_id: element.id,
                    question: element.question,
                    answer_type: element.answer_type,
                    answer
                };
            });
    
            const payload = {
                medical_form_id: formType?.formId,
                patient_id: selectedPatientId,
                form_data
            };
    
            const response = await dispatch(sendFormAction(payload)).unwrap();
            if (response?.status === 'success') {
                await dispatch(
                    getRecordsAction({
                    record_type: "questionnaire",
                    patient_id: selectedPatientId,
                    limit: 5,
                    })
                )
                navigate(`/patient-records?patient_id=${selectedPatientId}`);
            }
        } catch (error) {
            console.error("Failed to submit form:", error);
        } finally {
            setIsLoading(false);
        }
    };
    
    const renderFormElement = (element) => {
        const isColumnFlex = determineIfColumnFlexNeeded(element);
    
        switch (element.answer_type) {
            case 'string':
                return (
                    <Styled.FormElementContainer key={element.id}>
                        <Styled.FormQuestion>{element.question}{element.is_required && " *"}</Styled.FormQuestion>
                            <Styled.InputAnswer 
                                type="text" 
                                required={element.is_required} 
                                onChange={(e) => handleInputChange(element.id, e.target.value)}
                                value={formInputs[element.id] || ''}
                            />
                    </Styled.FormElementContainer>
                );
            
            case 'choice':
                return (
                    <Styled.FormElementContainer key={element.id}>
                        <Styled.FormQuestion>{element.question}{element.is_required && " *"}</Styled.FormQuestion>
                            <Styled.InputContainer columnlex={isColumnFlex}>
                                {element.answer_option.map((option, index) => (
                                    <Styled.RadioContainer 
                                        key={index} 
                                        isSelected={selectedOption[element.id] === option.valueString}
                                        columnflex={isColumnFlex}
                                        onClick={() => handleRadioChange(element.id, option.valueString)}
                                    >
                                        <Styled.RadioAnswer 
                                            type="radio" 
                                            required={element.is_required} 
                                            id={`${element.id}-${option.valueString}`} 
                                            name={element.id} 
                                            value={option.valueString || ''}
                                            onChange={() => handleInputChange(element.id, option.valueString)} 
                                            checked={selectedOption[element.id] === option.valueString}
                                        />
                                        <Styled.RadioLabel htmlFor={`${element.id}-${option.valueString}`}>{option.valueString}</Styled.RadioLabel>
                                    </Styled.RadioContainer>
                                ))}
                            </Styled.InputContainer>
                    </Styled.FormElementContainer>
                );
    
                case 'open-choice':
                    return (
                        <Styled.FormElementContainer key={element.id}>
                            <Styled.FormQuestion>{element.question}{element.is_required && " *"}</Styled.FormQuestion>
                                <Styled.InputContainer columnflex={isColumnFlex}>
                                    {element.answer_option.map((option, index) => (
                                        <Styled.CheckboxContainer 
                                            key={index}
                                            isSelected={selectedCheckboxes[element.id]?.[option.valueString]}
                                            columnflex={isColumnFlex}>
                                            <Styled.CheckboxAnswer 
                                                type="checkbox" 
                                                required={element.is_required} 
                                                id={`${element.id}-${option.valueString}`} 
                                                name={element.id} 
                                                value={option.valueString || ''} 
                                                checked={selectedCheckboxes[element.id]?.[option.valueString]}
                                                onChange={() => handleCheckboxChange(element.id, option.valueString)}
                                            />
                                            <Styled.CheckboxLabel 
                                                htmlFor={`${element.id}-${option.valueString}`}>
                                                {option.valueString}
                                            </Styled.CheckboxLabel>
                                        </Styled.CheckboxContainer>
                                    ))}
                                </Styled.InputContainer>
                        </Styled.FormElementContainer>
                    );
                
    
            case 'date':
                return (
                    <Styled.FormElementContainer key={element.id}>
                        <Styled.FormQuestion>{element.question}{element.is_required && " *"}</Styled.FormQuestion>
                            <Styled.DateInput 
                                type="date" 
                                required={element.is_required}
                                onChange={(e) => handleInputChange(element.id, e.target.value)}
                                value={formInputs[element.id] || ''}
                            />
                    </Styled.FormElementContainer>
                );

            case 'boolean':
                return (
                    <Styled.FormElementContainer key={element.id}>
                        <Styled.FormQuestion>{element.question}{element.is_required && " *"}</Styled.FormQuestion>
                            <Styled.BooleanContainer 
                                isSelected={formInputs[element.id]}
                                columnflex={false}
                            >
                                <Styled.CheckboxAnswer 
                                    type="checkbox" 
                                    id={`boolean-${element.id}`} 
                                    name={element.id} 
                                    required={element.is_required} 
                                    checked={formInputs[element.id] || false}
                                    onChange={(e) => handleInputChange(element.id, e.target.checked)}
                                />
                                <Styled.CheckboxLabel htmlFor={`boolean-${element.id}`}>{element.question}</Styled.CheckboxLabel>
                            </Styled.BooleanContainer>
                    </Styled.FormElementContainer>
                );
    
            default:
                return null;
        }
    };
        

    return(
        <Styled.Container>
            <Styled.TopHeader>
            <Styled.ArrowBackImage 
                src={IBackArrow} 
                alt="back-arrow" 
                onClick={onBack}
            />
            {formType?.formType}
            </Styled.TopHeader>
            <Styled.TypeContainer>
            <Styled.Form onSubmit={handleSubmit}>
                {formTemplateArray.map(element => renderFormElement(element))}
                <Styled.SaveButton type="submit">Save</Styled.SaveButton>
            </Styled.Form>
          </Styled.TypeContainer>
          <SnackBar />
        </Styled.Container>
    )
}

export default ObservationQuestionnaireForm;