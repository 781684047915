import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  RecordsContainer,
  RecordsHead,
  AcceptedFormat,
  Section,
  UploadBox,
  UploadBoxWrapper,
  FileUploadBox,
  CrossIcon,
  InnerUploadContainer,
  PdfText,
  FileName,
  DragText,
  AddButton,
  UploadFirstBox,
} from "./style.components.js";
import { IAddIconLarge, IFileIcon } from "../../assets";
import { Loader } from "../../../src/components/shared";
import { patientSelector } from "../../store/slice/patient.slice";
import {
  uploadRecordsAction,
  sendRecordsAction,
} from "../../store/actions";
import { Stack, IconButton, Box, Modal } from "@mui/material";
import { SnackBar } from "../../components/shared";
import StatusDropdown from "../../components/shared/StatusDropdown";
import CloseIcon from "@mui/icons-material/Close";

const dropdownOptions = [
  { label: "Available", value: "AVAILABLE" },
  { label: "Restricted", value: "RESTRICTED" },
];

const UploadReportModal = ({ open, handleClose, studyFhirId, setIsAddedNewRecords, isAddedNewRecords }) => {
  const fileInputRef = useRef(null);
  const { selectedPatientId } = useSelector(patientSelector);
  const [isUploadDisabled, setIsUploadDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [recordsArray, setRecordsArray] = useState([]);
  const currentRecordType = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setIsUploadDisabled(
      recordsArray.length === 0 ||
        recordsArray.some((record) => record.recordType === "")
    );
  }, [recordsArray]);

  const handleChange = (event, fileId) => {
    const index = recordsArray.findIndex((item) => item.file_id === fileId);
    if (index !== -1) {
      const updatedItem = {
        ...recordsArray[index],
        status: event.target.value,
      };
      const updatedArray = [
        ...recordsArray.slice(0, index),
        updatedItem,
        ...recordsArray.slice(index + 1),
      ];
      setRecordsArray([...updatedArray]);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const uploadFile = async (file) => {
    setIsLoading(true);
    var formData = new FormData();
    formData.append("files", file);
    try {
      const uploadResponse = await dispatch(uploadRecordsAction(formData));
      const response = uploadResponse?.payload;
      setIsLoading(false);
      if (response?.status === "success") {
        const transformedResponse = response.result.map((item) => ({
          ...item,
          recordType: "report",
          status: "AVAILABLE",
        }));
        setRecordsArray((prev) => [...prev, ...transformedResponse]);
      } else {
        alert("Something went wrong!");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error calling upload records:", error);
    }
  };

  const openFilePicker = () => {
    currentRecordType.current = "report";
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileSelect = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const newFiles = Array.from(files).map((file) => ({
        file,
        recordType: "report",
        file_id: Math.random().toString(36).substr(2, 9),
        file_name: file.name,
        status: "AVAILABLE",
      }));
      setRecordsArray((prev) => [...prev, ...newFiles]);
    }
    event.target.value = null;
  };

  const handleDrop = (event) => {
    event.preventDefault();
    if (event.dataTransfer.items) {
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        const fileItem = event.dataTransfer.items[i];
        if (fileItem.kind === "file") {
          const file = fileItem.getAsFile();
          if (file) {
            uploadFile(file);
          }
        }
      }
    }
  };

  const handleSend = async () => {
    if (isUploadDisabled) return;
    setIsLoading(true);
    setIsUploadDisabled(true);
    try {
      // Step 1: Collect promises for upload operations of each record type
      const uploadPromises = recordsArray.map(async (record) => {
        const formData = new FormData();
        formData.append("files", record.file);
        formData.append("file_type", record.recordType);

        // Attempt to upload the file
        const uploadResponse = await dispatch(
          uploadRecordsAction(formData)
        ).unwrap();
        if (uploadResponse?.status !== "success") {
          throw new Error("Upload failed for one or more files");
        }
        const transformedUploadResponse = uploadResponse.result.map((item) => ({
          ...item,
          status: record.status,
        }));
        return {
          ...uploadResponse,
          result: transformedUploadResponse,
          recordType: record.recordType,
        }; // Include recordType in response for later use
      });

      // Wait for all uploads to complete
      const uploadResults = await Promise.all(uploadPromises);
      // Step 2: Send the uploaded file info for each record type
      // Group upload results by recordType
      const groupedResults = uploadResults.reduce((acc, item) => {
        const { recordType, result } = item;
        if (!acc[recordType]) {
          acc[recordType] = [];
        }
        acc[recordType].push(...result); // Assuming result is an array of file info
        return acc;
      }, {});

      const sendPromises = Object.entries(groupedResults).map(
        async ([recordType, files]) => {
          const payload = {
            patient_id: selectedPatientId,
            scan_id: studyFhirId,
            report_info: files.map((file) => ({
              file_type: file.file_type,
              mimetype: file.mimetype,
              file_id: file.file_id,
              file_name: file.file_name,
              file_size: file.file_size,
              s3_file_url: file.s3_file_url,
              status: file.status,
            })),
          };
          return dispatch(sendRecordsAction({ type: recordType, payload }));
        }
      );
      await Promise.all(sendPromises);
      setIsAddedNewRecords(!isAddedNewRecords);
      setTimeout(() => {
        handleClose();
        setRecordsArray([]);
      }, 300);
    } catch (error) {
      console.error("Error  processing send records:", error);
    } finally {
      setIsLoading(false);
      setIsUploadDisabled(false);
    }
  };
  const handleCloseModal = () => {
    handleClose();
    setRecordsArray([]);
  }
  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <RecordsContainer>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginBottom: 1 }}
        >
          <RecordsHead>Upload Reports</RecordsHead>
          <IconButton aria-label="close" onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <AcceptedFormat>
          Accepted formats are PDF, JPG & PNG with less than 5 MB.
        </AcceptedFormat>
        <Section>
          <input
            type="file"
            style={{ display: "none" }}
            ref={fileInputRef}
            onChange={handleFileSelect}
            multiple
            accept=".pdf, .jpg, .jpeg, .png"
          />
          <UploadBoxWrapper>
            {!isLoading && recordsArray.length ? (
              <UploadBox
                onClick={openFilePicker}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                draggable
              >
                <img src={IAddIconLarge} alt="Add Icon" />
                <DragText>Drag or click to add report here.</DragText>
              </UploadBox>
            ) : null}
            {isLoading ? (
              <Loader />
            ) : recordsArray.length > 0 ? (
              recordsArray.map((row, index) => (
                <div key={row.file_id} style={{ width: "200px" }}>
                  <FileUploadBox>
                    <CrossIcon
                      onClick={() => {
                        setRecordsArray((prevArray) =>
                          prevArray.filter(
                            (item) => item.file_id !== row.file_id
                          )
                        );
                      }}
                    >
                      X
                    </CrossIcon>
                    <InnerUploadContainer>
                      <img src={IFileIcon} alt="File Icon" />
                      <PdfText>PDF</PdfText>
                    </InnerUploadContainer>
                    <FileName>{row.file_name}</FileName>
                  </FileUploadBox>
                    <Box mt={2} mb={2} style={{ width: "200px" }}>
                      <StatusDropdown
                        selectedStatus={row?.status}
                        handleChange={(e) => handleChange(e, row.file_id)}
                        dropdownOptions={dropdownOptions}
                      />
                    </Box>
                </div>
              ))
            ) :  <UploadFirstBox
            onClick={openFilePicker}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            draggable
          >
            <img src={IAddIconLarge} alt="Add Icon" />
            <DragText>Drag or click to add report here.</DragText>
          </UploadFirstBox>}
          </UploadBoxWrapper>
        </Section>
        <AddButton onClick={handleSend} disabled={isUploadDisabled}>
          Attach
        </AddButton>
        <SnackBar />
      </RecordsContainer>
    </Modal>
  );
};

export default UploadReportModal;
