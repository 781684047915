import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import styled from "styled-components";
import moment from "moment";
import { getTemplateAction, editTemplateAction } from "../../../store/actions";
import { useDispatch } from "react-redux";

const Button = styled.button`
  background-color: #7aba56;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  min-width: 200px;
  height: 40px;
  border: none;
  cursor: pointer;
  padding: 0 10px;

  &:disabled {
    background-color: #ddebed;
    color: #fff;
    cursor: not-allowed;
  }
`;

export default function TextEditor({
  isLoadingData=false,
}) {
  const [templateContent, setTemplateContent] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isEditorLoading, setEditorLoading] = useState(false);
  const [templateId, setTemplateId] = useState('');
  const editorRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchTemplate();
  }, []);

  const onSaveTemplate = () => {
    if (editorRef.current) {
      
      let data = editorRef.current.getContent();
      setTemplateContent(data);
      saveTemplate(data);
    }
  };

  const saveTemplate = async(content) => {
    setLoading(true);
    const payload = {
      id: templateId,
      template_content: content,
    };
    try {
      const response = await dispatch(editTemplateAction(payload)).unwrap();
      if (response && response?.status == "success") {
        setLoading(false);
        setEditorLoading(true)
      } else {
        setEditorLoading(false);
      }
    }
    catch(error) {
      console.log(error);
      setLoading(false);
      setEditorLoading(false);
    }
  };

  const fetchTemplate = async() => {
    try {
      const response = await dispatch(getTemplateAction("medical_letter")).unwrap();
      if (response && response?.status == "success") {
        const content = response?.result[0]?.html_content || "";
        const tbodyParts = content.split(/(<tbody[^>]*>)/);
        if (tbodyParts.length > 3) {
            tbodyParts[3] = tbodyParts[3].replace('<tbody', '<tbody class="mceNonEditable"');
        }
        const modifiedTemplateContent = tbodyParts.join('');
        
        setTemplateContent(modifiedTemplateContent);
        setTemplateId(response?.result[0]?.id);
      }
    }
    catch(error) {
      console.log(error);
    }
  };

function toDataUrl(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}
  return (
    <>
      <Snackbar
        open={isEditorLoading}
        autoHideDuration={3000}
        onClose={() => {
          setEditorLoading(false);
        }}
      >
        <Alert
          onClose={() => {
              setEditorLoading(false);
          }}
          severity={"success"}
          sx={{ width: "100%"}}
        >
          {
            "Template Saved Successfully"
          }
        </Alert>
      </Snackbar>
      <div style={{minHeight:'80vh'}}>
        <Editor
            apiKey="1lcuxds8d88qwvx54ut612dlywerd4kzoglczqjriehie6bb"
            onInit={(_evt, editor) => (editorRef.current = editor)}
            init={{
                height: "80vh",
                plugins: "preview anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
                toolbar: "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | preview | spellcheckdialog a11ycheck  | align lineheight |  numlist bullist indent outdent | emoticons charmap | removeformat",
                automatic_uploads: true,
                content_style: ".mceNonEditable { pointer-events: none; opacity: 0.7; }",
                noneditable_noneditable_class: "mceNonEditable",
                file_picker_types: "image file",
                file_picker_callback: (cb, value, meta) => {
                    if (meta.filetype === "image") {
                        const input = document.createElement("input");
                        input.setAttribute("type", "file");
                        input.setAttribute("accept", "image/*");
                        input.onchange = function () {
                            const file = input.files[0];
                            const reader = new FileReader();
                            reader.onload = function (e) {
                                cb(e.target.result, { alt: file.name });
                            };
                            reader.readAsDataURL(file);
                        };
                        input.click();
                    }
                },
                images_replace_blob_uris: false,
            }}
            initialValue={templateContent}
            onNodeChange={(e) => {
                if (e && e.element.nodeName.toLowerCase() === "img") {
                    toDataUrl(e.element.getAttribute("src"), (myBase64) => {
                        editorRef.current.dom.setAttribs(e.element, { src: myBase64 });
                    });
                }
            }}
        />
      </div>
      <div style={{ textAlign: "left", marginTop: "20px" }}>       
        <Button
          disabled={isLoading || isLoadingData}
          onClick={() => onSaveTemplate()}
        >
          {isLoading || isLoadingData ? <CircularProgress size={26} color="inherit" /> : "Save"}
        </Button>
      </div>
    </>
  );
}
