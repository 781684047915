import { API_ENDPOINT } from "../utilities/constants";
import { apiClient } from "./api-client";

const getFormTypes = async () => {
    try {
      const url = `${API_ENDPOINT.GET_FORM_TYPES}`;
      const response = await apiClient.get(url);
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
};

const getFormTemplate = async (form_id) => {
  try {
    const url = `${API_ENDPOINT.GET_FORM_TEMPLATE}/${form_id}`;
    const response = await apiClient.get(url);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const sendForm = async (data) => {
  try {
    const response = await apiClient.post(API_ENDPOINT.FORM, data, false);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const getFormFilled = async (form_id) => {
  try {
    const url = `${API_ENDPOINT.FORM}/${form_id}`;
    const response = await apiClient.get(url);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export {
    getFormTypes,
    getFormTemplate,
    sendForm,
    getFormFilled
};