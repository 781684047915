import React, { useEffect, useState } from "react";
import { Box, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { IUpArrow, IDownArrow } from "../../../assets";
import {
  getRecordsAction,
} from "../../../store/actions/records.action";
import { patientSelector } from "../../../store/slice/patient.slice";
import {
  ListWrapper,
  ListSection,
  ListDateTitle,
  ArrowImage,
  ListItemWrapper,
  ListItem,
  EmptyListMessage,
  ListItemBold,
  ThumbImage,
  ButtonWrapper,
  SaveButton,
  ModalBody,
  ModalHeader,
  CloseButtonWrapper,
  CloseButton,
  StyledBox,
  CheckboxLabel,
  HiddenCheckbox,
  LoaderWrapper,
  ThumbImageWrapper,
} from "./style.components";
import { format, parseISO } from 'date-fns';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { ICloseModal } from "../../../assets";
import { ShimmerContentBlock } from "react-shimmer-effects";

const style = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '90%',
    height: '90vh',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: '12px',
};
const AttachRecordsModal = ({ open, handleClose, handleSave, alreadySelectedFiles, recordTypesArray }) => {
    const dispatch = useDispatch();
    const { selectedPatientId } = useSelector(patientSelector);
    const [accordionData, setAccordionData] = useState({});
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [typesArray, setTypesArray] = useState([]);
    const initialState = typesArray?.reduce((acc, {record_type}) => {
        acc[record_type] = 'loading';
        return acc;
      }, {});
    const [loadedAccordions, setLoadedAccordions] = useState(initialState);

    const fetchRecords = async (recordType) => {
        if (loadedAccordions[recordType]) return; 
        setLoadedAccordions(prev => ({ ...prev, [recordType]: 'loading' }));
        if (selectedPatientId && recordType !== "questionnaire") {
          try {
            const action = await dispatch(getRecordsAction({
              record_type: recordType,
              patient_id: selectedPatientId,
            }));
            const fetchedData = action?.payload?.data;
            if (recordType === 'scans') {
              const transformedData = fetchedData?.map(({ study_id, modality, study_date, thumbnail, ...rest }) => ({
                ...rest,
                id: study_id,
                test_type: modality,
                upload_date: study_date,
                icon: thumbnail,
                record_type: "scans",
              }));
              setAccordionData(prev => ({ ...prev, [recordType]: transformedData }));
            } else if (recordType === 'image') {
              const transformedData = fetchedData?.map(({ note, ...rest }) => ({
                ...rest,
                out_of_bound_params: note,
              }));
              setAccordionData(prev => ({ ...prev, [recordType]: transformedData }));
            } else {
              setAccordionData(prev => ({ ...prev, [recordType]: fetchedData }));
            }
            setLoadedAccordions(prev => ({ ...prev, [recordType]: 'loaded' }));
          } catch (error) {
            console.error("Error fetching records:", error);
            setLoadedAccordions(prev => ({ ...prev, [recordType]: 'error' }));
          }
        }
    };

    useEffect(() => {
        setTypesArray(recordTypesArray);
    }, [recordTypesArray]);

    useEffect(() => {
        typesArray?.forEach(({ record_type }) => fetchRecords(record_type));
    }, [typesArray, selectedPatientId, dispatch]);


    const onSaveRecords = () => {
        handleSave(selectedRecords);
    };

    const handleCheckboxChange = (e, record) => {
        if (e.target.checked) {
            setSelectedRecords(prev => [...prev, {
                id: record.id,
                name: record.name,
                record_type: record.record_type,
            }]);
        } else {
            setSelectedRecords(prev => prev.filter(item => item.id !== record.id));
        }
    };    

    useEffect(() => {
        setSelectedRecords(alreadySelectedFiles);
    }, [open, alreadySelectedFiles]);

  return (

    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
            <CloseButtonWrapper>
                <CloseButton src={ICloseModal} onClick={handleClose} />
            </CloseButtonWrapper>
            <ModalHeader id="modal-modal-title">
                Attach Reports
            </ModalHeader>
            <ModalBody>
            {typesArray?.map(({ record_type, name }) => (
                <div key={record_type}>
                    <ListWrapper>
                        <ListSection>
                            <ListDateTitle>{name} ({accordionData[record_type]?.length || 0})</ListDateTitle>
                        </ListSection>
                        {(!loadedAccordions[record_type] || loadedAccordions[record_type] === 'loading') ? (
                                <LoaderWrapper>
                                    <ShimmerContentBlock title text cta thumbnailWidth={182} thumbnailHeight={182} />
                                </LoaderWrapper>
                            ) : accordionData[record_type] && accordionData[record_type].length > 0 ? (
                                accordionData[record_type].map((row) => (
                                    <ListItemWrapper key={row?.id}>
                                        <CheckboxLabel>
                                            <HiddenCheckbox 
                                                checked={selectedRecords.some(item => item.id === row?.id)}
                                                onChange={e => handleCheckboxChange(e, row)}
                                            />
                                            <StyledBox />
                                        </CheckboxLabel>
                                        <ListItem>
                                            {row?.icon && (
                                                <ThumbImageWrapper>
                                                    <ThumbImage
                                                        src={row?.icon}
                                                        alt="records-thumbnail"
                                                    />
                                                </ThumbImageWrapper>  
                                            )}
                                        </ListItem>
                                        <ListItem>
                                            {row?.upload_date ? format(parseISO(row?.upload_date), 'dd-MMM-yyyy  h:mm aa') : 'Invalid date'}
                                        </ListItem>
                                        <ListItem><ListItemBold></ListItemBold> {row?.test_type}</ListItem>
                                        <ListItem>{row?.out_of_bound_params}</ListItem>
                                    </ListItemWrapper>
                                ))
                            ) : (
                                <EmptyListMessage>No records found for {name}</EmptyListMessage>
                            )
                        }
                    </ListWrapper>
                </div>
            ))}
            </ModalBody>
            <ButtonWrapper>
                <SaveButton onClick={onSaveRecords}>Attach Selected</SaveButton>
            </ButtonWrapper>
        </Box>
    </Modal>
  );
};

export default AttachRecordsModal;
