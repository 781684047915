import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPatientProfile
} from "../../api";
import { setShowStatus, unsetShowStatus } from "../slice/status-slice";

export const getPatientProfileAction = createAsyncThunk(
    "get-patient-profile",
    async (id, thunkAPI) => {
      try {
        thunkAPI.dispatch(
          setShowStatus({
            message: "Fetching patient's profile",
            severity: "info",
            autoHide: "no",
          })
        );
        const response = await getPatientProfile(id);
        thunkAPI.dispatch(unsetShowStatus());
        return response;
      } catch (error) {
        console.error(error);
        thunkAPI.dispatch(
          setShowStatus({
            message: error || "Error while fetching patient's profile",
            severity: "error",
            autoHide: "no",
          })
        );
        return thunkAPI.rejectWithValue(
          error || "Error while fetching patient's profile"
        );
      }
    }
);