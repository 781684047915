import React,  { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IBackArrow } from "../../../assets";
import {
    Container,
    TopHeader,
    ArrowBackImage,
    RecordsContainer,
    RecordsHead,
    Section,
    AddButton
  } from "./style.components";
import { patientSelector } from "../../../store/slice/patient.slice";
import { sendScansAction } from "../../../store/actions";
import {UppyUploader} from "../../../../src/components/layout";
import { ShimmerPostDetails } from "react-shimmer-effects";
import { SnackBar } from "../../../components/shared";

const AddPacs = () => {
    const { selectedPatientId } = useSelector(patientSelector);
    const [isUploadDisabled, setIsUploadDisabled] = useState(true);
    const [uppyResponse, setUppyResponse] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setIsUploadDisabled(!uppyResponse);
    }, [uppyResponse]);

    const handleSend = async () => {
        if (isUploadDisabled) return;
        setIsLoading(true);
        try {
            const payload_scans = {
                "patient_id": selectedPatientId,
                "folder_id": uppyResponse,
            };
            const response = await dispatch(sendScansAction(payload_scans));
            if (response?.payload?.status === 'success') {
                setIsLoading(false);
                setTimeout(() => {
                    navigate(`/patient-records?patient_id=${selectedPatientId}`);
                }, 1000);
            }
        } catch (error) {
            console.error("Error sending scans:", error);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <ShimmerPostDetails card cta variant="SIMPLE" />;
    }

    return (
        <Container>
            <TopHeader>
                <ArrowBackImage src={IBackArrow} alt="back-arrow" 
                onClick={() => {
                    navigate(-1);
                }} 
                />
                Add Scans
            </TopHeader>
            <RecordsContainer>
                <RecordsHead>Upload Scans</RecordsHead>
                    <UppyUploader onUploadComplete={setUppyResponse} />
                <AddButton 
                    onClick={handleSend} 
                    disabled={isUploadDisabled}
                >
                    Send
                </AddButton>
            </RecordsContainer>
            <SnackBar />
        </Container>
    );
}

export default AddPacs;