import { API_ENDPOINT, DEV_URL } from "../utilities/constants";
import { apiClient } from "./api-client";

const getReminders = async (patient_id) => {
    try {
      const url = `${API_ENDPOINT.REMINDERS}?patient_id=${patient_id}&limit=10&page_number=1`;
      const response = await apiClient.get(url);
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
};

const addReminder = async (data) => {
    try {
      const response = await apiClient.post(API_ENDPOINT.REMINDERS, data, false);
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
};

const getObservationTypes = async () => {
    try {
      const url = `${API_ENDPOINT.REMINDERS}/types`;
      const response = await apiClient.get(url);
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
};

const getTodos = async (patient_id) => {
    try {
      const url = `${API_ENDPOINT.TODO}?patient_id=${patient_id}&limit=10&page_number=1`;
      const response = await apiClient.get(url);
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
};

const updateReminder = async ({ reminder_id, payload }) => {
    try {
      const url = `${API_ENDPOINT.REMINDERS}/${reminder_id}`;
      const response = await apiClient.patch(url, payload, false);
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
};

const updateTodo = async ({ todo_id, payload }) => {
    try {
      const url = `${API_ENDPOINT.TODO}/${todo_id}`;
      const response = await apiClient.patch(url, payload, false);
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
};

export {
    getReminders,
    addReminder,
    getObservationTypes,
    getTodos,
    updateReminder,
    updateTodo,
};