import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  margin: 40px;
  padding: 24px;
  border-radius: 12px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 32px #2477b314;
  opacity: 1;
  margin-bottom: 0;
  @media(max-width:768px){
    margin:20px 5px;
    padding: 10px;
  }
`;
export const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  font-size: 24px;
  color: #0099CC;
  @media(max-width:468px){
    font-size:16px;
  }
`;
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
`;

export const SubHeader = styled.div`
  color: #000000;
  font-size: 18px;
  font-weight: 600;
  margin-top: 28px;
  margin-bottom: 26px;
  flex: 1;
`;

export const RightSubHeader = styled.div`
  color: #0099CC;
  background-color: #EBF7FB;
  font-size: 16px;
  font-weight: 500;
  padding: 16px 24px;
`;

export const ArrowBackImage = styled.img`
  cursor: pointer;
`;

export const SyncUpdateImage = styled.img`
  width: 40px;
  height: 40px;
  margin-left: 20px;
`;

export const TemperatureDropdown = styled.select`
  position: absolute;
  right: 10px;
  margin-bottom: 16px;
  margin-top: 10px;
  font-size: 13px;
  border: none;
`;

export const MainContent = styled.div`
  display: flex;
  height: 100%;
`;

export const LeftSide = styled.div`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
`;

export const RightSide = styled.div`
  flex: 0.6;
  border-radius: 8px;
  height: 100%;
  overflow-y: auto;
  margin-top: 14px;
`;

export const HeadTitleSection = styled.div`
display:flex;
justify-content: space-between;
align-items: center;
width:100%;

`
export const RightSideContent = styled.div`
  padding: 24px;
  padding-top: 0;
  background-color: #F5FBFC;
  display: flex;
  flex-direction: column;
`;

export const TextArea = styled.textarea`
    width: 100%;
    box-sizing: border-box;
    border-radius: 12px;
    border: 1px solid #92D4E9;
    padding: 10px;
    height: 88px;
    resize: none;
    margin-top: 10px;
`;

export const FileSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const FileButton = styled.button`
    margin-left: 10px;
    padding: 5px 10px;
    cursor: pointer;
`;

export const InputGroup = styled.div`
    display: flex;
    justify-content: space-between;

    & > div {
        width: 40%;
    }
`;
export const ButtonBox = styled.div`
width: 100%;
text-align: right;
margin: 10px 0 19px;
padding: 0 25px;
`
export const SubmitButton = styled.button`
  width: 180px;
  border-radius: 8px;
  background-color: #7ABA56;
  color: #FFFFFF;
  padding: 10px 38px;
  border: none;
  cursor: pointer;
  @media(max-width:768px){
    width: auto;
    padding: 10px 15px;
  }
`;


export const ReminderCard = styled.div`
width:50%; 
@media(max-width:1100px){
  width:100%;
}
`
export const ReminderCardBox = styled.div`
display: flex;
justify-content: space-between;
background-color: #F5FBFC;
padding: 16px 24px;
border-bottom-left-radius: 8px;
border-bottom-right-radius: 8px;
flex-wrap: wrap;
`

export const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #F5FBFC;
  padding: 16px 12px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  gap:32px 0; 
  flex-wrap:wrap;
  
`;

export const TextAreaWrapper = styled.div`
  width: calc(50% - 12px);

  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const TextAreaWrapperThree = styled(TextAreaWrapper)`
  width: calc(33.333% - 16px);
`;

export const LabelAndInput = styled.div`
  width: calc(50% - 12px);
  display: flex;
  flex-direction: column;
  @media(max-width:991px){
    width:100%;
  }
`;

export const LabelAndInputVitals = styled(LabelAndInput)`
  width: calc(33.333% - 7px);
  @media(max-width:991px){
    width:calc(50% - 12px);
  }
  @media(max-width:468px){
    width:calc(100% - 12px);
  }
`;

export const Label = styled.label`
    font-size: 14px;
    color: #808080;
`;

export const LabelAttachReports = styled.label`
    font-size: 14px;
    color: #808080;
    visibility: hidden;
`;

const sharedInputStyles = `
    border-radius: 12px;
    border: 1px solid #92D4E9;
    padding: 10px;
    margin-bottom: 16px;
    margin-top: 10px;
    font-size: 13px;
`;

export const Input = styled.input`
    flex: 1;
    ${sharedInputStyles}
`;

export const InputVitals = styled.input`
  width:100%;
  padding: 10px 2px !important;
  padding-left: 8px !important;
  flex: 1;
  ${sharedInputStyles}
`;

export const InputUnitWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const UnitLabel = styled.span`
  position: absolute;
  right: 10px;
  margin-bottom: 16px;
  margin-top: 10px;
  font-size: 13px;
`;

export const InputRight = styled.input`
    ${sharedInputStyles}
`;

export const Dropdown = styled.select`
    ${sharedInputStyles}
    appearance: none;
    background-color: white;
    margin-right: 10px;
`;

export const TextAreaAISummary = styled.div`
    font-size: 13px;
    color: #000000;
    height: 80px;
    overflow-x: hidden;
    margin-top: 10px;
    white-space: pre-line; 
    word-wrap: break-word;
    border-radius: 12px;
    background-color: #D7EFF7;
    padding: 6px 10px;
    color: #92D4E9;
`;

export const DisplayGroup = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const DisplayWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #EBF7FB;
    padding-top: 20px;

    &:last-child {
        margin-right: 0;
    }
`;

const LabelTextAreaWrapperBase = ({ noBorder, ...props }) => <div {...props} />;

export const LabelTextAreaWrapper = styled(LabelTextAreaWrapperBase)`
  padding-top: 10px;
  border-bottom: ${props => props.noBorder ? 'none' : '2px solid #EBF7FB'};
  display: flex;
  flex-direction: column;

  &:last-child {
    border-bottom: none;
  }
`;

export const LabelTextAreaReports = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  @media(max-width:991px){
    width: 100%;
  }
`;

export const SaveButton = styled.button`
  width: 288px;
  height: 54px;
  background-color: #7aba56;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 48px;
`;
export const SendButton = styled.button`
  min-width: 24%;
  padding: 10px;
  height: 40px;
  background-color: #7aba56;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
`;
export const RecordButton = styled.button`
  width: 320px;
  height: 54px;
  padding: 24px;
  background-color: #7aba56;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
  @media(max-width:991px){
    width:100%;
  }
  @media(max-width:768px){
    height: 46px;
  }
`;
export const GenerateButton = styled.button`
  width: 288px;
  height: 54px;
  margin-left: 20px;
  background-color: #7aba56;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  justify-content: center;
`;
export const SendButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;
export const AudioButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const ReportContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    border-radius: 12px;
    border: 1px solid #92D4E9;
    height: 88px;
    overflow-y: auto;
    width: 100%;
    box-sizing: border-box;
`;

export const ReportDetailsContainer = styled.div`
   display: flex;
   align-items: center;
   flex-wrap: nowrap;
   width: 100%;
   margin-bottom: 8px;
   overflow: hidden; 
`;


export const ReportList = styled.div`
    width: 85%;
    max-height: 200px;
    overflow-y: auto;
    padding: 10px;
    background-color: #FFFFFF;
`;

export const AttachButton = styled.button`
    width: 15%;
    height: 100%;
`;

export const ReportNumber = styled.span`
    font-size: 14px;
    color: #000000;
    white-space: nowrap;
    flex-shrink: 0;
`;

export const ReportName = styled.span`
    font-size: 14px;
    color: #0099CC;
    margin-left: 15px;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
`;

export const AttachIconImgContainer = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    background-color: #EBF7FB;
    border-radius: 0px 11px 11px 0px;
    cursor: pointer;
`

export const AttachIconImg = styled.img`
    height: 19px;
    width: 18px;
`;

export const ReportNumberRight = styled.div`
    font-size: 14px;
    color: #000000;
    margin-bottom: 5px;
`;

export const AssessmentHeader = styled.div`
  display: flex;
  align-items: center;
  flex: 0.4;
  font-weight: bold;
  color: #0099cc;
  font-size: 16px;
  height: 60px;
  background-color: #ebf7fb;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
  border-radius: 8px;
`;

export const AssessmentTextArea = styled.textarea`
    width: 97%;
    box-sizing: border-box;
    border-radius: 12px;
    border: 2px solid #7ABA56;
    padding: 5px 10px;
    min-height: 88px;
    resize: none;
    margin-bottom: 16px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    display: block;
`;

export const ChatBox = styled.div`
  position: fixed;
  bottom: 0;
  right: 15px;
  width: 300px;
  height: 400px;
  background-color: #FFFFFF;
  border-radius: 15px 15px 0 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  overflow-y: auto;
  display: ${props => props.isOpen ? 'block' : 'none'};
`;

export const ListSection = styled.div`
  padding: 16px 24px;
  background-color: #ebf7fb;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const ListDateTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const MainInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  @media(max-width:1300px){
    // flex-wrap:wrap;
    display:block;
  }
`;

export const PersonalInfoContainer = styled.div`
  flex: 1;
  border-radius: 8px;
  margin-bottom: 24px;
`;

export const SideIcon = styled.img`
  margin-right: 10px;
`;

export const HeadingText = styled.div`
  font-weight: bold;
  color: #0099cc;
  font-size: 16px;
`;

export const LabelTextArea = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 18px; 
  right: 8px; 
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 10;
`;

export const ExpandButton = styled.img`
  cursor: pointer;
  width: 32px;
  height: 32px;
`;

export const CopyButton = styled.img`
  cursor: pointer;
  width: 16px;
  height: 16px;
  padding: 8px;
  background-color: #D7EFF7;
  border-radius: 8px;
`;

export const TextAreaContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const TranscriptionextArea = styled.textarea`
  width: 100%;
  box-sizing: border-box;
  border-radius: 12px;
  border: 2px solid #7aba56;
  padding: 10px;
  min-height: 160px;
  resize: none;
  margin-bottom: 15px;
  padding-right: 40px;
`;

export const ExpandButtonTextArea = styled.img`
  position: absolute;
  right: 10px;
  top: 32px;
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

export const CopyButtonTextArea = styled.img`
  position: absolute;
  right: 10px;
  top: 80px;
  width: 16px;
  height: 16px;
  padding: 8px;
  background-color: #D7EFF7;
  border-radius: 8px;
  cursor: pointer;
`;