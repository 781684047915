import { useEffect ,useState} from "react";
import { Outlet } from "react-router-dom";
import { Footer, Header, SideNav } from "../../components/shared";
import { MainBody, ParentContainer } from "./style.components";

const PatientRoot = () => {
  const [open,setOpen]=useState(false)

  const HamburgerMenuToggle=(isOpen)=>{
    setOpen(isOpen)
  }
  return (
    <>
      <Header  HamburgerMenuToggle={HamburgerMenuToggle}  />
      <ParentContainer>
        <SideNav open={open} HamburgerMenuToggle={HamburgerMenuToggle} />
        <MainBody>
          <Outlet />
        </MainBody>
      </ParentContainer>
    </>
  );
};

export default PatientRoot;
