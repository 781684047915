import React, { useEffect, useState } from "react";
import {
  Box,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Typography,
} from "@mui/material";
import { formatTimestamp } from "../../../utilities/common";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { getReportURL } from "../../../store/actions/records.action";
import { Spinner } from "@react-pdf-viewer/core";

const PhotoRecordItem = ({ row, index }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const thumbnails = useSelector((state) => state?.thumbnail?.thumbnails);

  const handleBoxClick = () => {
    setModalContent(row);
    setModalOpen(true);
  };

  return (
    <div key={`photos-${index}`}>
      <Box onClick={handleBoxClick}>
        <Box
          sx={{
            width: {xs: 240 , sm: 280},
            height: {xs: 120, sm: 150},
            background: "#EBF7FB",
            padding: "10px",
            borderRadius: "10px 10px 0 0",
            display: "flex",
            flexDirection: "row",
            overflow: "hidden",
            cursor: "pointer",
            justifyContent: !row?.note ?"center" : "start",
            gap: 2,
          }}
        >
          <CardMedia
            component="img"
            image={(thumbnails && thumbnails[row?.id]) || row?.icon}
            alt={"records-thumbnail"}
            sx={{
              boxSizing: "border-box",
              borderRadius: "5px",
              width: !row?.note ? "50%" : "auto",
            }}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = row?.icon;
            }}
          />
          {row?.note && (
            <Box
              component="span"
              sx={{
                display: "block",
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 6,
                lineHeight: "1.5em",
                maxHeight: "9em",
                wordBreak: "break-word",
              }}
            >
              {row?.note}
            </Box>
          )}
        </Box>
        <Box
          sx={{
            width: {xs: 240 , sm: 280},
            fontSize: "12px",
            background: "#2FAED7",
            padding: "8px 10px",
            color: "#FFF",
            borderRadius: "0 0 10px 10px",
          }}
        >
          {row?.upload_date && formatTimestamp(row.upload_date)}
        </Box>
      </Box>
      <CustomModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        content={modalContent}
      />
    </div>
  );
};

const CustomModal = ({ open, onClose, content }) => {
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchReportUrl = async (filePath) => {
    try {
      const payload = {
        file_path: filePath,
      };
      setLoading(true);
      const response = await dispatch(
        getReportURL({ payload: payload })
      ).unwrap();

      if (response?.status === "success") {
        setImage(response?.data);
      }
    } catch (error) {
      console.log("error occured while fetching url");
      return "";
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchReportUrl(content?.file_path);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={"xl"}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span>Photo</span>
        <IconButton onClick={onClose} sx={{ color: "gray" }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ background: "#ebf7fb", padding: "40px 24px"}}>
        {!loading ? (
          <Box m={2}>
            <Grid
              container
              spacing={2}
              display="flex"
              sx={{ flexDirection: { xs: "column", sm: "row" } }}
            >
              <Grid item xs={!content.note ? 12 : 6}>
                <CardMedia
                  component="img"
                  image={image}
                  sx={{
                    minWidth: { xs: "250px", sm: "100%" },
                    objectFit: "contain",
                    borderRadius: "5px",
                  }}
                />
              </Grid>
              {content?.note && (
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      wordWrap: "break-word",
                      border: "1px solid #FFCF66",
                      borderRadius: "5px",
                      background: "#FFF5C2",
                      padding: "20px",
                      width: { xs: 210, sm: "90%" },
                      minHeight: { xs: 200, sm: "95%" },
                    }}
                  >
                    {content?.note}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            width={{ xs: 210, sm: 500 }}
            height={200}
          >
            <Spinner />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PhotoRecordItem;
