import React, { useState, useEffect, useRef } from 'react';
import { ParentContainer, UploadMainContainer, AddDocsContainer, PlusText, AddText, NextButton } from './style.components';
import UppyUploader from '../../components/layout/UppyUploadBox/UppyUploadBox';
import UppyUploaderEncounter from '../../components/layout/UppyUploadBoxEncounter/UppyUploadBox';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getUploadRecordsAction } from '../../store/actions';
import { Loader, SnackBar } from '../../components/shared';
import { setRecords, setUppyResponse } from '../../store/slice';
import Breadcrumb from '../../components/layout/Breadcrumb';
import { patientSelector } from '../../store/slice/patient.slice';

const AddRecords = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [uppyResponse, setUppyLibResponse] = useState();
  const [loading, setLoading] = useState(false);
  const [isUppyReady, setIsUppyReady] = useState(false);
  const uppyInstance = useRef(null);
  const { selectedPatientId } = useSelector(patientSelector);
  const fileTypes = ['.pdf', '.jpg', '.jpeg', '.png', '.rtf', '.doc'];
  const isNewEncounter = location.pathname === "/new-encounter/add-records";
  const [generatedFiles, setGeneratedFiles] = useState([]);
  const [encounterId, setEncounterId] = useState(null);
  const [consultationId, setConsultationId] = useState(null);
  const [fhirId, setFhirId] = useState(null);

  useEffect(() => {
    setGeneratedFiles(location.state?.generatedFiles);
    setEncounterId(location.state?.encounterID);
    setConsultationId(location.state?.consultationID);
    setFhirId(location.state?.studyFhirId);
  }, [location]);

  useEffect(() => {
    if (generatedFiles && isUppyReady && uppyInstance.current) {
      generatedFiles.forEach((file) => {
        uppyInstance.current.addFile({
          name: file.name,
          type: file.type,
          data: file.blob,
        });
      });
    }
  }, [generatedFiles, isUppyReady]);

  const handleHomeNavigation = (event) => {
    event.preventDefault();
    navigate('/');
  };

  const breadCrumbLinks = [
    {
      label: "Home",
      onClick: handleHomeNavigation,
    },
    {
      label: isNewEncounter ? `Encounters` : `Health Data`,
      onClick: () => {
        if (isNewEncounter) {
          navigate(`/new-encounter`, {
            state: { id: encounterId, consultationId: consultationId },
          });
        } else {
          navigate(`/patient-records?patient_id=${selectedPatientId}`);
        }
      },
    },
    ...(fhirId
      ? [
          {
            label: "Scan Info",
            onClick: () => navigate(`/patient-records/scan-info`, {
              state: { studyFhirId: fhirId }
            }),
          },
        ]
      : []),
    {
      label: "Add Records",
    },
  ];

  const handleNextButton = async () => {
    setLoading(true);
    try {
      dispatch(setUppyResponse(uppyResponse));
      const response = await dispatch(getUploadRecordsAction(uppyResponse?.assemblyID));
      dispatch(setRecords(response.payload));
      
      navigate('send-records', { state: {records: response?.payload, uppyResponse: uppyResponse, encounterId: encounterId, consultationId: consultationId, fhirId: fhirId}});
    } catch (error) {
      console.error("Error sending records:", error);
    } finally {
      setLoading(false);
    }
  };  

  return (
    <>
      <ParentContainer>
        <Breadcrumb links={breadCrumbLinks} />
        <UploadMainContainer>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: '1' }}>
              <div style={{ width: '100%' }}>
                {isNewEncounter ? (
                  <UppyUploaderEncounter 
                    onUploadComplete={setUppyLibResponse} 
                    allowedFileTypes={fileTypes} 
                    uppyRef={uppyInstance}
                    setIsUppyReady={setIsUppyReady}
                  />
                ) : (
                  <UppyUploader
                    onUploadComplete={setUppyLibResponse}
                    allowedFileTypes={fileTypes} 
                  />
                )}
              </div>
              {uppyResponse?.uploadedFiles && (
                <div style={{ width: '100%' }}>      
                  <NextButton onClick={handleNextButton}>
                    {loading ? <Loader size={24} /> : "Next"}
                  </NextButton> 
                </div>
              )}
            </div>
        </UploadMainContainer>
      </ParentContainer>
      <SnackBar />
    </>
  );
};

export default AddRecords;
