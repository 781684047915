import { createSlice } from "@reduxjs/toolkit";
import {
  getRecordsAction
} from "../actions";

const initialState = {
  isLoading: null,
  records: null,
};

const recordsSlice = createSlice({
  name: "record",
  initialState,
  reducers: {
    resetState: () => initialState,
  },
  extraReducers: (builder) => {

    //get records
    builder.addCase(getRecordsAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getRecordsAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload && payload?.record_type) {
        const { record_type, data, patient_id, total } = payload;
          state.records = {
            ...state.records,
            [record_type] : {
              data : data || [],
              total: total || 0,
            },
            patient_id: patient_id,
          };
      }
    });
    builder.addCase(getRecordsAction.rejected, (state) => {
      state.isLoading = false;
      state.records = null;
    });
  },
});

export const recordsSelector = (state) => state.record?.records || {};
export default recordsSlice.reducer;
export const { resetState } = recordsSlice.actions;
