import { API_ENDPOINT } from "../utilities/constants";
import { apiClient } from "./api-client";

const addObservation = async (data) => {
    try {
      const response = await apiClient.post(API_ENDPOINT.ADD_OBSERVATION, data, false);
      return response;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
};

const getObservationList = async () => {
  try {
    const url = `${
      API_ENDPOINT.OBSERVATION_LIST
    }`;
    const response = await apiClient.get(url);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export {
    addObservation,
    getObservationList
};