import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Modal } from "@mui/material";
import { ICloseModal } from "../../../assets";
import { ShimmerPostDetails } from "react-shimmer-effects";
import {
  Container,
  Header,
  HeaderLeft,
  HeaderWrapper,
  Title,
  DateText,
  Edit,
  TitleContainer,
  DetailsContainer,
  SugarIcon,
  ContentContainer,
  ContentText,
  ScheduleText,
  Delete,
  ButtonContainer,
  TodoContainer,
  SugarLevelContainerTodo, EmptyListMessage,
  CloseButton, CloseButtonWrapper, ModalBody, ModalHeader, ButtonWrapper, SaveButton, NoButton, DeleteText
} from "./style.components";
import { patientSelector } from "../../../store/slice/patient.slice";
import { getTodosAction, updateTodoAction } from "../../../store/actions";
import '../../../utilities/customCalendar.css'
import { ISugarIcon } from "../../../assets";
import { SnackBar } from "../../../components/shared";

const style = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '40%',
    height: '25vh',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: '12px',
};

const styleTodo = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '40%',
    height: '25vh',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: '12px',
};

const formatDateTodo = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', options).format(date);
};

const PatientTodos = () => {
    const dispatch = useDispatch();
    const { selectedPatientId } = useSelector(patientSelector);
    const [open, setOpen] = useState(false);
    const [openAddData, setOpenAddData] = useState(false);
    const [todosArray, setTodosArray] = useState([]);
    const [selectedTodoId, setSelectedTodoId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchTodos();
    }, [dispatch]);

    const fetchTodos = async () => {
        setIsLoading(true);
        try {
            const response = await dispatch(getTodosAction(selectedPatientId)).unwrap();
            const newTodos = response.data;
            setTodosArray(newTodos);
            setIsLoading(false);
        } catch (error) {
            console.error("Failed to fetch observation types:", error);
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    const handleNotComplete = async () => {
        setOpen(false);
        setIsLoading(true);
        const payload = {
            "is_completed": false
        };
    
        try {
            const actionResult = await dispatch(updateTodoAction({ todo_id: selectedTodoId, payload }));
            const response = actionResult.payload;
            if (response && response.status === "success") {
                await fetchTodos();
                setIsLoading(false);
            } else {
                console.error("Failed to update todo:", actionResult.error);
            }
        } catch (error) {
            console.error("Error updating todo:", error);
            setIsLoading(false);
        }
    }
    
    const handleComplete = async () => {
        setOpenAddData(false);
        setIsLoading(true);
        const payload = {
            "is_completed": true
        };
    
        try {
            const actionResult = await dispatch(updateTodoAction({ todo_id: selectedTodoId, payload }));
            const response = actionResult.payload;
            if (response && response.status === "success") {
                await fetchTodos();
            } else {
                console.error("Failed to update todo:", actionResult.error);
            }
        } catch (error) {
            console.error("Error updating todo:", error);
            setIsLoading(false);
        }
    }

    if (isLoading) {
        return <Container><ShimmerPostDetails card cta variant="SIMPLE" /></Container>;
    }

    return (
        <Container>
            <HeaderWrapper>
                <HeaderLeft>
                    <Header>To-do</Header>
                </HeaderLeft>
            </HeaderWrapper>
            <ScheduleText>Today</ScheduleText>
                <TodoContainer>
                    {todosArray?.today_notifications?.length > 0 ? (
                    todosArray.today_notifications.map((todo, index) => (
                    <SugarLevelContainerTodo key={index+1}>
                        <TitleContainer>
                            <Title>{todo?.reminder_type_display_name}</Title>
                        </TitleContainer>     
                        <DetailsContainer>
                            <SugarIcon src={ISugarIcon}></SugarIcon>
                            <ContentContainer>
                                <DateText>{formatDateTodo(todo?.scheduled_at)}</DateText>
                                <ContentText>{todo?.description}</ContentText>
                                <ButtonContainer>
                                    {todo?.is_completed === "true" && (
                                        <Delete onClick={() => { setOpen(true); setSelectedTodoId(todo.id); }}>Mark as not completed</Delete>
                                    )}
                                    {todo?.is_completed === "false" && (
                                        <Edit onClick={() => { setOpenAddData(true); setSelectedTodoId(todo.id); }}>Mark as completed</Edit>
                                    )}
                                </ButtonContainer>
                            </ContentContainer>
                        </DetailsContainer>
                    </SugarLevelContainerTodo>
                    ))
                    )
                    :
                    (<EmptyListMessage>No to-do scheduled for today</EmptyListMessage>)}
                </TodoContainer>
                
            <ScheduleText>Upcoming</ScheduleText>
                <TodoContainer>
                {todosArray?.upcoming_notifications?.length > 0 ? (
                todosArray.upcoming_notifications.map((todo, index) => (
                    <SugarLevelContainerTodo key={index+1}>
                        <TitleContainer>
                            <Title>{todo?.reminder_type_display_name}</Title>
                        </TitleContainer>     
                        <DetailsContainer>
                            <SugarIcon src={ISugarIcon}></SugarIcon>
                            <ContentContainer>
                                <DateText>{formatDateTodo(todo?.scheduled_at)}</DateText>
                                <ContentText>{todo?.description}</ContentText>
                                <ButtonContainer>
                                    {!todo?.is_completed ? (
                                        <Delete onClick={() => { setOpen(true); setSelectedTodoId(todo.id); }}>Mark as not completed</Delete>
                                    ) : (
                                        <Edit onClick={() => { setOpenAddData(true); setSelectedTodoId(todo.id); }}>Mark as completed</Edit>
                                    )}
                                </ButtonContainer>
                            </ContentContainer>
                        </DetailsContainer>
                    </SugarLevelContainerTodo>
                )))
                : (<EmptyListMessage>No upcoming todos found</EmptyListMessage>)}
                
            </TodoContainer>
                <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <CloseButtonWrapper>
                        <CloseButton src={ICloseModal} onClick={() => setOpen(false)} />
                    </CloseButtonWrapper>
                    <ModalHeader id="modal-modal-title">
                        Delete ToDo
                    </ModalHeader>
                    <ModalBody>
                        <DeleteText>Are you sure want to delete this entry</DeleteText>
                    </ModalBody>
                    <ButtonWrapper>
                        <SaveButton onClick={handleNotComplete}> Yes </SaveButton>
                        <NoButton onClick={() => setOpen(false)}> No </NoButton>
                    </ButtonWrapper>
                </Box>
            </Modal>
            <Modal
                open={openAddData}
                onClose={() => setOpenAddData(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleTodo}>
                    <CloseButtonWrapper>
                        <CloseButton src={ICloseModal} onClick={() => setOpenAddData(false)} />
                    </CloseButtonWrapper>
                    {/* <ModalHeader id="modal-modal-title">
                        Sugar Level
                    </ModalHeader>
                    <ModalBody>
                        <Styled.SugarLevelFor>
                            <Styled.SugarLevelForText>
                                Sugar Level For
                            </Styled.SugarLevelForText>
                            <Styled.RadioMainContainer>
                                <Styled.RadioButtonContainer 
                                $isSelected={reminder.sugarLevelTiming === 'Before Meal'}
                                >
                                <Styled.RadioButtonLabel>
                                    <Styled.RadioButton
                                    id="beforeMeal"
                                    type="radio"
                                    name="sugarLevelTiming"
                                    value="Before Meal"
                                    checked={reminder.sugarLevelTiming === 'Before Meal'}
                                    onChange={handleInputChange}
                                    />
                                    Before Meal
                                </Styled.RadioButtonLabel>
                                </Styled.RadioButtonContainer>
                                <Styled.RadioButtonContainer 
                                $isSelected={reminder.sugarLevelTiming === 'After Meal'}
                                >
                                <Styled.RadioButtonLabel>
                                    <Styled.RadioButton
                                    id="afterMeal"
                                    type="radio"
                                    name="sugarLevelTiming"
                                    value="After Meal"
                                    checked={reminder.sugarLevelTiming === 'After Meal'}
                                    onChange={handleInputChange}
                                    />
                                    After Meal
                                </Styled.RadioButtonLabel>
                                </Styled.RadioButtonContainer>
                            </Styled.RadioMainContainer>
                        </Styled.SugarLevelFor>
                        <Styled.SugarLevelFor>
                            <Styled.SugarLevelForText>
                                Sugar Level
                            </Styled.SugarLevelForText>
                            <Styled.InputUnitWrapper>
                                <Styled.Input 
                                    value="" 
                                />
                                <Styled.UnitLabel>mg/dL</Styled.UnitLabel>
                            </Styled.InputUnitWrapper>
                        </Styled.SugarLevelFor>
                        <Styled.SugarLevelFor>
                            <Styled.SugarLevelForText>
                                Date
                            </Styled.SugarLevelForText>
                                <Styled.Input disabled
                                    value="21-Apr-2023 1:33 pm" 
                                />
                        </Styled.SugarLevelFor>
                    </ModalBody>
                    <Styled.ButtonWrapperTodo>
                        <SaveButton> Mark Complete </SaveButton>
                    </Styled.ButtonWrapperTodo> */}
                    <ModalHeader id="modal-modal-title">
                        Mark Complete
                    </ModalHeader>
                    <ModalBody>
                        <DeleteText>Are you sure want to mark as complete</DeleteText>
                    </ModalBody>
                    <ButtonWrapper>
                        <SaveButton onClick={handleComplete}> Yes </SaveButton>
                        <NoButton onClick={() => setOpenAddData(false)}> No </NoButton>
                    </ButtonWrapper>
                </Box>
            </Modal>
            <SnackBar />
        </Container>
    )
};

export default PatientTodos;