import { Box } from "@mui/material";
import styled from "styled-components";
import { removeSpecialCharacters } from "../../utilities/common";

const InfoName = styled.div`
  font-weight: 600;
`;

const HeaderTitle = {
  color: { xs: "#808080", sm: "#000" },
};

const PatientInfo = ({ recordInfo }) => {
  return (
    <>
      <Box>
        <Box sx={HeaderTitle}>Owner / Patient Name</Box>
        <InfoName>{removeSpecialCharacters(recordInfo.patient_name) || "-"}</InfoName>
      </Box>
      <Box display="flex" justifyContent="space-between" mt={1}>
        <Box>
          <Box sx={HeaderTitle}>Gender</Box>
          <InfoName>{recordInfo.patient_gender || "-"}</InfoName>
        </Box>
        <Box>
          <Box sx={HeaderTitle}>Age</Box>
          <InfoName>{recordInfo.patient_age || "-"}</InfoName>
        </Box>
      </Box>
    </>
  );
};

export default PatientInfo;
