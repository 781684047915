import { createSlice } from "@reduxjs/toolkit";
import {
  getConsultationsAction
} from "../actions";

const initialState = {
  isLoading: null,
  consultations: null,
};

const consultationSlice = createSlice({
  name: "consultation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    //get consultations
    builder.addCase(getConsultationsAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getConsultationsAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.consultations = payload?.data?.care_episodes;
    });
    builder.addCase(getConsultationsAction.rejected, (state) => {
      state.isLoading = false;
      state.consultations = null;
    });
  },
});

export const consultationSelector = (state) => state.consultation;
export default consultationSlice.reducer;
