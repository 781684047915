import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { useLocation, useNavigate } from "react-router-dom";
  import { useDispatch, useSelector } from "react-redux";
  import { IBlueArrowRight, ILabReportThumb } from "../../../assets";
  import { Loader, SnackBar } from "../../../components/shared";
  import {
    getConsultationsAction, addConsultationsAction
  } from "../../../store/actions/consultations.actions";
  import { patientSelector } from "../../../store/slice/patient.slice";
  import {
    ActionImage,
    AddButton,
    Container,
    EditButton,
    EditDeleteWrapper,
    EmptyListMessage,
    Header,
    HeaderLeft,
    HeaderRight,
    HeaderWrapper,
    FullNameWrapper,
    ProfileImage,
    CaseNumber,
    UpdatedDate,
    StatusText,
    EncountersText,
    EncountersCount,
    CustomTableRow,
  } from "./style.components";
  import { format, parseISO } from 'date-fns';
  import { ShimmerTable } from "react-shimmer-effects";
  import Breadcrumb from "../../../components/layout/Breadcrumb";

const PatientConsultations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [consultationData, setConsultationData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddLoading, setIsAddLoading] = useState(false);
  const { selectedPatientId } = useSelector(patientSelector);

  const handleHomeNavigation = (event) => {
    event.preventDefault();
    navigate('/');
  };

  const breadCrubLinks = [
    {
      label: "Home",
      onClick: handleHomeNavigation,
    },
    {
      label: "Consulatations",
    },
  ];

  const fetchAllConsultations = async () => {
    if(selectedPatientId){
      setIsLoading(true);
      const payload = {
        "patient_id": selectedPatientId,
        "page_number": 0,
        "limit": 5,
        "only_logged_in_doctor":true
      }
      try {
        const response = await dispatch(getConsultationsAction(payload));
        if(response?.payload.status === "success"){
          setConsultationData(response?.payload.data.care_episodes);
        }
      } catch (error) {
        console.error("Error fetching consultations:", error);
      } finally {
          setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (selectedPatientId) {
      fetchAllConsultations();
    }
  }, [selectedPatientId, dispatch, location]);

  const addConsultation = async() => {
    if(selectedPatientId){
      setIsAddLoading(true);
      const payload = {
        "patient_id": selectedPatientId
      }
      try {
        const response = await dispatch(addConsultationsAction(payload));
        if (response?.payload.status === "success"){
          setIsAddLoading(false)
          navigate("/encounters", {
            state: { id: response?.payload.data.id }
          })
        }
      } catch (error) {
        console.error("Error adding consultation:", error);
      } finally {
          setIsAddLoading(false);
      }
    }
  }

  return (
    <Container>
      <Breadcrumb links={breadCrubLinks} />
      <HeaderWrapper>
        <HeaderLeft>
          <Header>Consultations</Header>
        </HeaderLeft>
        <HeaderRight>
            <AddButton
                onClick={addConsultation}
            >
                {isAddLoading ? <Loader height={'0px'} /> : 'New Consultation'}
            </AddButton>
        </HeaderRight>
      </HeaderWrapper>
      {isLoading ? (
        <ShimmerTable row={5} />
      ) : (
        <>
          {consultationData?.length > 0 ? (
            <TableContainer>
              <Table aria-label="simple table">
                <TableBody>
                  {consultationData.map((row) => {
                    const { id, updated_date, status, encounter_count } = row;
                    return (
                      <CustomTableRow key={id} onClick={() =>
                        navigate("/encounters", {
                          state: { id: id, status: status },
                        })
                      }>
                        <TableCell align="left">
                          <FullNameWrapper>
                            <ProfileImage src={ILabReportThumb} alt="lab-report-thumb" />
                            <CaseNumber>Consultation {id}</CaseNumber>
                          </FullNameWrapper>
                        </TableCell>
                        <TableCell align="left">
                          <UpdatedDate>Updated: {format(parseISO(updated_date), 'dd-MMM-yyyy')}</UpdatedDate>
                        </TableCell>
                        <TableCell align="left">Status: <StatusText status={status}>{status}</StatusText></TableCell>
                        <TableCell align="left"><EncountersCount>{encounter_count || 0}</EncountersCount> <EncountersText>Encounters</EncountersText></TableCell>
                        <TableCell>
                          <EditDeleteWrapper>
                            <EditButton>
                              <ActionImage src={IBlueArrowRight} alt="edit" />
                            </EditButton>
                          </EditDeleteWrapper>
                        </TableCell>
                      </CustomTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <EmptyListMessage>No consultations data found</EmptyListMessage>
          )}
        </>
      )}
      <SnackBar />
    </Container>
  );
  
};
export default PatientConsultations;
