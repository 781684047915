import styled from "styled-components";
import {
  HEADER_HEIGHT, DEVICE
} from "../../../utilities/constants"; 

export const Container = styled.div`
  border-radius: 12px;
  background-color: #F5FBFC;
  opacity: 1;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);
  margin-bottom: 0;
  overflow-y: auto;

  @media ${DEVICE.tablet} {
    margin: 0;
    margin-top: 24px;
  }
`;

export const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 24px;
  color: #000000;
`;

export const ArrowBackImage = styled.img`
  cursor: pointer;
`;

export const RecordsContainer = styled.div`
  background-color: #FFFFFF;
  border-radius: 12px;
  margin-top: 24px;
  padding: 24px;
  min-height: 590px;
  width: auto;
`;

export const RecordsHead = styled.div`
  color: #0099CC;
  font-size: 16px;
  margin-bottom: 24px;
`;

export const AcceptedFormat = styled.div`
  color: #808080;
  font-size: 13px;
  margin-bottom: 24px;
`;

export const Section = styled.div`
    padding: 24px;
    margin-bottom: 30px;
    background-color: #F5FBFC;
    border-radius: 8px;
`;

export const UploadBoxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    gap: 24px;
`

export const UploadBox = styled.div`
    min-width: 214px;
    max-width: 214px;
    height: 272px;
    border: 1px solid #D7EFF7;
    background-color: #EBF7FB;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: #A6B8BB;
    margin: 0 24px;
    cursor: pointer;
`;

export const UploadBoxIcon = styled.div`
  padding: 72px 406px;
  cursor: pointer;

  @media ${DEVICE.tablet} {
    padding: 72px 250px;
  }

  @media ${DEVICE.mobileL} {
    padding: 72px 50px !important;
  }
`;

export const FileUploadBox = styled.div`
    position: relative;
    width: 214px;
    height: 272px;
    border: 1px solid #D7EFF7;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: 13px;
    color: #000000;
`;

export const CrossIcon = styled.div`
    width: 26px;
    height: 26px;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    background-color: #CC0000;
    color: #ffffff;
    font-weight: 600;
    text-transform: lowercase;
    font-size: 16px;
    text-align: center;
    border-radius: 12px;
`;

export const DragText = styled.div`
    font-size: 13px;
    color: #0099CC;
    text-align: center;
`

export const InnerUploadContainer = styled.div`
    width: 182px;
    height: 182px;
    border: 1px solid #D7EFF7;
    background-color: #EBF7FB;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const PdfText = styled.div`
    font-size: 20px;
    color: #0099CC;
`

export const FileName = styled.div`
    font-size: 16px;
    color: #000000;
    text-align: left;
    padding: 0 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 85%;
`

export const AddButton = styled.button`
  width: 148px;
  height: 44px;
  background: ${props => props.disabled ? '#A6B8BB' : '#7ABA56'};
  cursor: ${props => props.disabled ? 'inherit' : 'pointer'};
  border-radius: 8px;
  color: #ffffff;
  outline: none;
  border-style: hidden;
  font-size: 16px;
  margin-right: 20px;
`;