import { createSlice } from "@reduxjs/toolkit";
import {
  runAssessmentAction,
} from "../actions";

const initialState = {
  isLoading: null,
  assessments: null,
};

const assessmentSlice = createSlice({
  name: "assessment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    //get consultations
    builder.addCase(runAssessmentAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(runAssessmentAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.assessments = payload?.result;
    });
    builder.addCase(runAssessmentAction.rejected, (state) => {
      state.isLoading = false;
      state.assessments = null;
    });
  },
});

export const assesmentSelector = (state) => state.assessment;
export default assessmentSlice.reducer;
