import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  callHemeBot
} from "../../api";
import { setShowStatus, unsetShowStatus } from "../slice/status-slice";

export const callHemeBotAction = createAsyncThunk(
  "call-hemebot",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: `Getting response from Hemebot Chat`,
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await callHemeBot(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while calling hemebot",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while calling hemebot");
    }
  }
);

