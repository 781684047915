import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getRecordsAction
} from "../../../store/actions";
import * as Styled from "./style.components";
import { 
    IBackArrow
} from "../../../assets";
import moment from "moment";
import { patientSelector } from "../../../store/slice/patient.slice";
import { ShimmerPostDetails } from "react-shimmer-effects";
import { SnackBar } from "../../../components/shared";

const formatToLocalTime = (utcDate) => {
    return moment(utcDate).local().format("DD-MMM-YYYY h:mm A");
};

const QuestionnaireList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [filledFormsData, setFilledFormsData] = useState([]);
    const { selectedPatientId } = useSelector(patientSelector);
    const [total, setTotal] = useState(0);
    
    useEffect(() => {
        fetchFilledForms();
    }, [dispatch]);

    const fetchFilledForms = async () => {
        setIsLoading(true);
        try {
          const action = await  dispatch(
            getRecordsAction({
              record_type: "questionnaire",
              patient_id: selectedPatientId,
              limit: 10,
            })
          );
          const response = action.payload;
          if (response && response.status === "success") {
            setIsLoading(false);
            const response = action?.payload?.data || [];
            setFilledFormsData(response);
            setTotal(action?.payload?.total);
          }
          else {
              console.error("Failed to fetch questionnaire:", action.error);
          }
        } catch (error) {
            console.error("Failed to fetch questionnaire:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleListItemClick = async record => {
        navigate("questionnaire-type/questionnaire-form", {
            state: { filled_form_id: record?.id }
        });
    }

    if (isLoading) {
        return <ShimmerPostDetails card cta variant="SIMPLE" />;
    }

    const convertedData = filledFormsData.length && filledFormsData.flatMap(item => 
        item.questionnaire_response_ids.map(response => ({
          ...response,
          parent_id: item.id,
          title: item.title,
          last_modified: response.last_updated
        }))
      );

    return (
        <Styled.Container>
          <Styled.TopHeader>
            <Styled.LeftHeaderSection>
                <Styled.ArrowBackImage 
                src={IBackArrow} 
                alt="back-arrow" 
                onClick={() => navigate(-1)}
                />
                Questionnaire {total > 0 ? `(${total})` : ""}
            </Styled.LeftHeaderSection>
            <Styled.AddButton onClick={() => navigate("questionnaire-type")}>Fill a Questionnaire</Styled.AddButton>
          </Styled.TopHeader>
            <Styled.CardsContainerForms>
                {convertedData?.length > 0 ? (
                    convertedData.map((row, formIndex) => (
                    <Styled.ListItemWrapper
                        key={formIndex}
                        onClick={() => {
                        const rowWithRecordType = {
                            ...row,
                            record_type: "questionnaire"
                        };
                        handleListItemClick(rowWithRecordType);
                        }}
                    >
                        <Styled.ThumbImageForms src={row?.icon} alt="forms-thumbnail" />
                        <Styled.CardDetailsWrapper key={formIndex}>
                            <Styled.ListItemDateForms>{formatToLocalTime(row?.last_modified)}</Styled.ListItemDateForms>
                            <Styled.ListItemForms>{row?.title}</Styled.ListItemForms>
                        </Styled.CardDetailsWrapper>
                    </Styled.ListItemWrapper>
                    )))
                    : (
                    <Styled.EmptyListMessage>No records found for questionnaire</Styled.EmptyListMessage>
                )}
            </Styled.CardsContainerForms>
            <SnackBar />
        </Styled.Container>
    )
}

export default QuestionnaireList;