import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getReminders, addReminder, updateReminder, getTodos, updateTodo, getObservationTypes
} from "../../api";
import { setShowStatus, unsetShowStatus } from "../slice/status-slice";

export const getRemindersAction = createAsyncThunk(
  "get-reminders",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: `Fetching reminders`,
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getReminders(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching reminders",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while fetching reminders");
    }
  }
);

export const getObservationTypesAction = createAsyncThunk(
  "get-observation-types",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: `Fetching observation types`,
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getObservationTypes();
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || `Error while fetching observation types`,
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || `Error while fetching observation types`);
    }
  }
);

export const getTodosAction = createAsyncThunk(
    "get-todos",
    async (data, thunkAPI) => {
      try {
        thunkAPI.dispatch(
          setShowStatus({
            message: `Fetching to-do`,
            severity: "info",
            autoHide: "no",
          })
        );
        const response = await getTodos(data);
        thunkAPI.dispatch(unsetShowStatus());
        return response;
      } catch (error) {
        console.error(error);
        thunkAPI.dispatch(
          setShowStatus({
            message: error || "Error while fetching to-do",
            severity: "error",
            autoHide: "no",
          })
        );
        return thunkAPI.rejectWithValue(error || "Error while fetching to-do");
      }
    }
);

  export const addReminderAction = createAsyncThunk(
    "add-reminder",
    async (data, thunkAPI) => {
      try {
        thunkAPI.dispatch(
          setShowStatus({
            message: `Adding Reminder`,
            severity: "info",
            autoHide: "no",
          })
        );
        const response = await addReminder(data);
        thunkAPI.dispatch(
          setShowStatus({
            message: "Reminder added successfully.",
            severity: "success",
            autoHide: "no",
          })
        );
        return response;
      } catch (error) {
        console.error(error);
        thunkAPI.dispatch(
          setShowStatus({
            message: error || "Error while adding reminder",
            severity: "error",
            autoHide: "no",
          })
        );
        return thunkAPI.rejectWithValue(error || "Error while adding reminder");
      }
    }
);

export const updateReminderAction = createAsyncThunk(
    "update-reminder",
    async (data, thunkAPI) => {
      try {
        thunkAPI.dispatch(
          setShowStatus({
            message: `Updating a reminder`,
            severity: "info",
            autoHide: "no",
          })
        );
        const response = await updateReminder(data);
        thunkAPI.dispatch(unsetShowStatus());
        return response;
      } catch (error) {
        console.error(error);
        thunkAPI.dispatch(
          setShowStatus({
            message: error || "Error while updating reminder",
            severity: "error",
            autoHide: "no",
          })
        );
        return thunkAPI.rejectWithValue(error || "Error while updating reminder");
      }
    }
);

export const updateTodoAction = createAsyncThunk(
    "update-todo",
    async (data, thunkAPI) => {
      try {
        thunkAPI.dispatch(
          setShowStatus({
            message: `Updating a todo`,
            severity: "info",
            autoHide: "no",
          })
        );
        const response = await updateTodo(data);
        thunkAPI.dispatch(unsetShowStatus());
        return response;
      } catch (error) {
        console.error(error);
        thunkAPI.dispatch(
          setShowStatus({
            message: error || "Error while updating todo",
            severity: "error",
            autoHide: "no",
          })
        );
        return thunkAPI.rejectWithValue(error || "Error while updating todo");
      }
    }
);
