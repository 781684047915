import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Tabs,
  Tab,
  Grid,
  Button,
  Box,
  Typography,
  CircularProgress,
  Modal,
} from "@mui/material";
import { ICloseModal } from "../../assets";
import {
  ModalBody,
  ModalHeader,
  CloseButtonWrapper,
  CloseButton,
  SaveButton,
  CopySummaryButton,
  FinishButton,
  LoaderWrapper,
  TextAreaWrapper,
  Label,
  TextArea,
  LabelTextArea,
  ModalBodySecond,
  StyledMinimizeIcon,
  StyledSelect,
  StartTranscriptionContainer,
  FromText,
  EHRContainer,
  SideIcon,
  TranscriptionextArea,
  AddToEhrButton,
  PreviewAudioSection,
  Transcribe,
  CustomRecordBox,
} from "./style.components";
import StopIcon from "@mui/icons-material/Stop";
import MicIcon from "@mui/icons-material/Mic";
import { LiveAudioVisualizer } from "react-audio-visualize";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { Bars } from "react-loader-spinner";
import "./styles.css";
import { API_ENDPOINT } from "../../utilities/constants";
import { apiClient } from "../../api/api-client";
import { patientSelector } from "../../store/slice/patient.slice";
import { resetState } from "../../store/slice/hemebot.slice";
import { resetAiSummaries } from "../../store/slice/single-record.slice";

var mediaRecorder; // set mediaRecorder as  an globally accessible
var audioText;
const DG_KEY = "a0cfd002c33bb168ce45a0e9e5e05c6da88802fb";
let socket; // Declare socket globally

const DeepgramLiveTranscription = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let currentText = ""; // if you want record all audio even if you stoped and restart MediaRecorder, so you should set it as a global variable
  const [activeTab, setActiveTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [record, setRecord] = useState(false);
  const [text, setText] = useState("");
  const [blobURL, setBlobURL] = useState("");
  const audioRef = useRef(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [file, setFile] = useState(null);
  const { selectedPatientId } = useSelector(patientSelector);
  const [patientID, setPatientID] = useState();
  const [patientName, setPatientName] = useState("");
  const [patientAge, setPatientAge] = useState("");
  const [patientHeight, setPatientHeight] = useState("");
  const [patientWeight, setPatientWeight] = useState("");
  const [bloodPressure, setBloodPressure] = useState("");
  const [heartRate, setHeartRate] = useState("");
  const [spo2, setSpo2] = useState("");
  const [respiratoryRate, setRespiratoryRate] = useState("");
  const [temperature, setTemperature] = useState("");
  const [randomBloodSugar, setRandomBloodSugar] = useState("");
  const [isGenerateEnabled, setIsGenerateEnabled] = useState(false);
  const [gender, setGender] = useState("");
  const [patientHistory, setPatientHistory] = useState("");
  const [symptoms, setSymptoms] = useState("");
  const [physicalExamination, setPhysicalExamination] = useState("");
  const [otherNotes, setOtherNotes] = useState("");
  const [patientDetails, setPatientDetails] = useState({});
  const [singleRecordResponse, setSingleRecordResponse] = useState([]);
  const [transcriptionResponse, setTranscriptionResponse] = useState("");
  const [localreminders, setLocalReminders] = useState([]);
  const [nextSteps, setNextSteps] = useState("");
  const [differentialDiagnosis, setDifferentialDiagnosis] = useState("");
  const location = useLocation();
  const [addToEhrLoading, setAddToEhrLoading] = useState(false);
  const recorder = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err) // onNotAllowedOrFound
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    setPatientID(location.state?.id);
  }, [location, dispatch]);

  const startRec = () => {
    setRecord(true);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2500); // Simulate starting recording after 2 seconds
    recorder.startRecording();
    setText("");
    setBlobURL("");
    setAudioChunks([]);
  };

  const stopRec = async () => {
    if (record && mediaRecorder && mediaRecorder.state !== "inactive") {
      mediaRecorder.stop();

      // Stop all tracks of the media stream
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());

      mediaRecorder = null;
      socket = null;
    }
    // Close the WebSocket connection if it exists
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.close();
    }
    setRecord(false)
    const blob = new Blob(audioChunks, { type: "audio/mp4" });
    const url = URL.createObjectURL(blob);
    setBlobURL(url);
    setAudioChunks([]); // Reset audioChunks here as well
  };

  const onData = (recordedBlob) => {
    console.log("chunk of real-time data is: ", recordedBlob);
  };

  const onStop = (recordedBlob) => {
    setBlobURL(URL.createObjectURL(recordedBlob.blob));
  };

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const pauseAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  };

  const deepGramAudio2text = () => {
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      let supportedMimeType = null;
      const mimeTypes = [
        "audio/webm",
        "audio/ogg",
        "audio/wav",
        "audio/mp4",
        "audio/mpeg",
      ];

      for (let mimeType of mimeTypes) {
        if (MediaRecorder.isTypeSupported(mimeType)) {
          supportedMimeType = mimeType;
          break;
        }
      }

      if (!supportedMimeType) {
        return alert("No supported MIME type found.");
      }

      // Proceed with the recording setup
      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: supportedMimeType,
      });

      const url = `wss://api.deepgram.com/v1/listen?model=nova-2-general&smart_format=true&language=hi`;
      socket = new WebSocket(url, ["token", DG_KEY]);

      socket.onopen = () => {
        if (!mediaRecorder) {
          console.error("MediaRecorder not initialized");
          return;
        }

        mediaRecorder.addEventListener("dataavailable", async (event) => {
          if (event.data.size > 0 && socket.readyState === 1) {
            socket.send(event.data);
            setAudioChunks((prevChunks) => [...prevChunks, event.data]);
          }
        });

        mediaRecorder.start(1100);
        console.log("Recording started");
      };

      socket.onmessage = async (message) => {
        try {
          const received = JSON.parse(message.data);

          if (
            received.channel &&
            received.channel.alternatives &&
            received.channel.alternatives.length > 0
          ) {
            const transcript = received.channel.alternatives[0].transcript;

            if (transcript && received.is_final) {
              currentText = currentText.concat(" " + transcript);
              audioText = currentText;
              console.log("Current text:", audioText);
              setText(audioText);
            }
          }
        } catch (error) {
          console.error("Error processing message:", error);
        }
      };
    });
  };

  useEffect(() => {
    if (record) {
      deepGramAudio2text();
    }
  }, [record]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setBlobURL(URL.createObjectURL(selectedFile));
    }
  };

  const createDiarization = (text) => {
    const encodedText = encodeURIComponent(text);
    navigate(`/diarization?text=${encodedText}`);
  };

  const sendTranscript = async (data) => {
    try {
      setAddToEhrLoading(true);
      const response = await apiClient.post(
        API_ENDPOINT.POST_TRANSCRIPT_SUMMARY,
        data,
        false
      );
      if (response) {
        console.log("response noted", response);
        postTranscriptCallback({
          infoResponse: response,
          transcript: text,
        });
        // stopRecording();
        setAddToEhrLoading(false);

        handleClose();
      }
      return response;
    } catch (error) {
      setAddToEhrLoading(false);
      handleClose();
      return Promise.reject(error);
    }
  };

  const postTranscriptCallback = (data) => {
    console.log("hi1");
    if (data?.infoResponse?.status === "success") {
      console.log("hi2", data);
      setPatientDetails(data?.infoResponse?.result);
      const { patient_info, reminders, summary } = data?.infoResponse?.result;
      const allReminders = [...localreminders, ...reminders];
      setLocalReminders(allReminders);

      if (!!data?.transcript?.length) {
        setTranscriptionResponse(data?.transcript || text || "");
      }
      if (!!patient_info?.patient_diagnosis?.other_notes?.length) {
        setOtherNotes(
          patient_info?.patient_diagnosis?.other_notes || otherNotes || ""
        );
      }
      if (!!patient_info?.patient_diagnosis?.patient_history?.length) {
        setPatientHistory(
          patient_info?.patient_diagnosis?.patient_history ||
            patientHistory ||
            ""
        );
      }
      if (!!patient_info?.patient_diagnosis?.physical_examination?.length) {
        setPhysicalExamination(
          patient_info?.patient_diagnosis?.physical_examination ||
            physicalExamination ||
            ""
        );
      }
      if (!!patient_info?.patient_diagnosis?.symptoms?.length) {
        setSymptoms(
          patient_info?.patient_diagnosis?.symptoms || symptoms || ""
        );
      }
      if (!!patient_info?.personal_information?.age?.length) {
        setPatientAge(
          patient_info?.personal_information?.age || patientAge || ""
        );
      }
      if (!!patient_info?.personal_information?.gender?.length) {
        setGender(patient_info?.personal_information?.gender || gender || "");
      }
      if (!!patient_info?.personal_information?.height?.length) {
        setPatientHeight(
          patient_info?.personal_information?.height || patientHeight || ""
        );
      }
      if (!!patient_info?.personal_information?.weight?.length) {
        setPatientWeight(
          patient_info?.personal_information?.weight || patientWeight || ""
        );
      }
      if (!!patient_info?.recommended_next_steps?.tests_recommended?.length) {
        setNextSteps(
          patient_info?.recommended_next_steps?.tests_recommended ||
            nextSteps ||
            ""
        );
      }
      if (!!summary?.length) {
        setDifferentialDiagnosis(summary || differentialDiagnosis || "");
      }
      if (!!patient_info?.vitals?.blood_pressure?.length) {
        setBloodPressure(
          patient_info?.vitals?.blood_pressure || bloodPressure || ""
        );
      }
      if (!!patient_info?.vitals?.heart_rate?.length) {
        setHeartRate(patient_info?.vitals?.heart_rate || heartRate || "");
      }
      if (!!patient_info?.vitals?.random_bloodSugar?.length) {
        setRandomBloodSugar(
          patient_info?.vitals?.random_bloodSugar || randomBloodSugar || ""
        );
      }
      if (!!patient_info?.vitals?.respiratory_rate?.length) {
        setRespiratoryRate(
          patient_info?.vitals?.respiratory_rate || respiratoryRate || ""
        );
      }
      if (!!patient_info?.vitals?.spo2?.length) {
        setSpo2(patient_info?.vitals?.spo2 || spo2 || "");
      }
      if (!!patient_info?.vitals?.temperature?.length) {
        setTemperature(patient_info?.vitals?.temperature || temperature || "");
      }
    }
  };

  const handleClose = () => {
    navigate(-1);
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="App"
        sx={{
          padding: 2,
          maxWidth: "600px", // Set a max width
          maxHeight: "80vh", // Set a max height
          margin: "auto", // Center the modal
          overflowY: "auto", // Allow scrolling if content overflows
          position: "relative", // Position for close button
        }}
      >
        <CloseButtonWrapper>
          <CloseButton
            src={ICloseModal}
            onClick={() => {
              handleClose();
            }}
          />
        </CloseButtonWrapper>
        <header>
          <h1 className="header__title">Audio-scribe</h1>
        </header>

        <Grid item xs={12}>
          <Box
            sx={{
              border: "1px solid #ccc",
              backgroundColor: "#eef2f6",
              overflowY: "auto", // Scroll if content overflows
            }}
          >
            <Box className="container">
              {recorder.mediaRecorder && !loading && record && (
                <LiveAudioVisualizer
                  mediaRecorder={recorder.mediaRecorder}
                  width={200}
                  height={75}
                />
              )}
              <div className="real-time-interface">
                <p id="real-time-title" className="real-time-interface__title">
                  Click the button below and start speaking into your microphone
                  to see your live transcription.
                </p>

                {loading ? (
                  <div
                    style={{
                      textAlign: "center",
                      position: "absolute",
                      marginTop: "3px",
                      display: "flex", // Use flexbox
                      alignItems: "center", // Align items vertically
                      justifyContent: "center", // Center content horizontally
                      gap: "10px", // Space between loader and text
                    }}
                  >
                    <Bars
                      height="30"
                      width="80"
                      color="#4fa94d"
                      ariaLabel="bars-loading"
                      visible={true}
                    />
                    <p
                      style={{
                        color: "#000",
                        marginTop: "0px", // Space between loader and text
                        fontSize: "14px",
                      }}
                    >
                      Speak after loading stops...
                    </p>
                  </div>
                ) : (
                  <Button
                    style={{
                      backgroundColor: "#7ABA56",
                      color: "white",
                      height: "45px",
                    }}
                    sx={{ color: "#FFF" }}
                    variant="contained"
                    onClick={record ? stopRec : startRec}
                    startIcon={record ? <StopIcon /> : <MicIcon />}
                  >
                    {record ? "Stop recording" : "Start Talking"}
                  </Button>
                )}
              </div>
              <br></br>
              {blobURL && (
                <div>
                  <audio src={blobURL} controls preload="metadata" />
                </div>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              border: "1px solid #ccc",
              padding: 2,
              backgroundColor: "#F8FAFC",
              height: "300px", // Fixed height

              overflowY: "auto", // Scroll if content overflows
            }}
            className="container"
          >
            <div className="real-time-interface__message">{text}</div>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: 2, // Space between text and buttons
              }}
            >
              <Button
                style={{
                  backgroundColor: blobURL ? "#7ABA56" : "#ccc", // Green when active, grey when disabled
                  color: blobURL ? "white" : "#666", // Light grey text when disabled
                  marginRight: "10px",
                }}
                sx={{ color: "#FFF", height: "45px" }}
                variant="contained"
                disabled={!blobURL}
                onClick={() =>
                  sendTranscript({
                    transcript: text,
                    patient_id: selectedPatientId,
                  })
                }
              >
                {addToEhrLoading ? (
                  <CircularProgress size={24} color="inherit" /> // Show loader
                ) : (
                  "Add to EHR"
                )}
              </Button>
              <Button
                style={{ backgroundColor: "#7ABA56", color: "white" }}
                sx={{ color: "#FFF", height: "45px" }}
                variant="contained"
                onClick={() => {
                  window.location.reload();
                  navigate(-1);
                }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DeepgramLiveTranscription;
