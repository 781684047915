import styled from "styled-components";
import {
  COLOR_PRIMARY_LIGHT,
  FOOTER_HEIGHT,
} from "../../../utilities/constants";

export const FooterRow = styled.div`
  color: #808080;
  font-size: 14px;
  height: ${FOOTER_HEIGHT};
  background-color: ${COLOR_PRIMARY_LIGHT};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-top: 10px;
`;
