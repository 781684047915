import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  statusSelector,
  unsetShowStatus,
} from "../../../store/slice/status-slice";

const SnackBar = () => {
  const dispatch = useDispatch();
  const status = useSelector(statusSelector);
  return (
    <Snackbar
      open={status?.showStatus}
      autoHideDuration={status?.autoHideIn}
      onClose={() => {
        if (status?.autoHide) {
          dispatch(unsetShowStatus());
        }
      }}
    >
      <Alert
        onClose={() => {
          dispatch(unsetShowStatus());
        }}
        severity={status?.severity}
        sx={{ width: "100%" }}
      >
        {status?.message}
      </Alert>
    </Snackbar>
  );
};
export default SnackBar;
