import { API_ENDPOINT } from "../utilities/constants";
import { apiClient } from "./api-client";

const runAssessment = async (payload) => {
  try {
    const url = `${API_ENDPOINT.PATIENT}/encounter/assessment`;
    const response = await apiClient.post(url, payload, false);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const getSingleRecord = async ({ patientId, recordType, recordId }) => {
  try {
    const url = `${API_ENDPOINT.PATIENT}/${patientId}/type/${recordType}/${recordId}`;
    const response = await apiClient.get(url);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const generateDoc = async (payload) => {
  try {
    const url = `${API_ENDPOINT.PATIENT}/encounter/summary`;
    const response = await apiClient.post(url, payload, false, 'arraybuffer'); 
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const uploadAudio = async (payload) => {
  try {
    const url = `${API_ENDPOINT.UPLOAD_AUDIO}`;
    const response = await apiClient.post(url, payload, true);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const startTranscriptionJob = async (payload) => {
  try {
    const url = `${API_ENDPOINT.START_TRANSCRIPT}`;
    const response = await apiClient.post(url, payload, false);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const getNotesAudio = async ({ jobId }) => {
  try {
    const url = `${API_ENDPOINT.START_TRANSCRIPT}?job_id=${jobId}`;
    const response = await apiClient.get(url);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const uploadRecords = async (payload) => {
  try {
    const url = `${API_ENDPOINT.MEDIA_UPLOAD}`;
    const response = await apiClient.post(url, payload, true);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const getOpenAITranscription = async (payload) => {
  try {
    const url = `${API_ENDPOINT.START_OPENAI_TRANSCRIPT}`;
    const response = await apiClient.post(url, payload, true);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const sendRecords = async ({ type, payload }) => {
  try {
    const url = `${API_ENDPOINT.SEND_RECORDS}/${type}/add`;
    const response = await apiClient.post(url, payload, false);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const sendScans = async (payload) => {
  try {
    const url = `${API_ENDPOINT.SEND_SCANS}`;
    const response = await apiClient.post(url, payload, false);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export {
  runAssessment,
  getSingleRecord,
  generateDoc,
  uploadAudio,
  startTranscriptionJob,
  getNotesAudio,
  uploadRecords,
  getOpenAITranscription,
  sendRecords,
  sendScans,
};
