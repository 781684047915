import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getConsultations,
  addConsultation,
  closeConsultation,
  getEncounters,
  addEncounter,
  closeEncounter,
  getConsultationInfo,
  getTemplate,
  editTemplate
} from "../../api";
import { setShowStatus, unsetShowStatus } from "../slice/status-slice";

export const getConsultationsAction = createAsyncThunk(
  "get-consultations",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching consultations",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getConsultations(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching consultations",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while fetching consultations");
    }
  }
);

export const addConsultationsAction = createAsyncThunk(
  "add-consultation",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Adding new consultation",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await addConsultation(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while adding consultation",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while adding consultation");
    }
  }
);

export const closeConsultationsAction = createAsyncThunk(
  "close-consultation",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Closing consultation",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await closeConsultation(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while closing consultation",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while closing consultation");
    }
  }
);

export const getEncountersAction = createAsyncThunk(
  "get-encounters",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching encounters",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getEncounters(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching encounters",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while fetching encounters");
    }
  }
);

export const addEncounterAction = createAsyncThunk(
  "add-encounter",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Adding new encounter",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await addEncounter(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while adding encounter",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while adding encounter");
    }
  }
);

export const closeEncounterAction = createAsyncThunk(
  "close-encounter",
  async (data, thunkAPI) => {
    try {
      const isClosing = data.set_to_close; // Check if encounter is closing

      // Show status message based on the action (opening/closing)
      thunkAPI.dispatch(
        setShowStatus({
          message: isClosing ? "Closing encounter" : "Opening encounter",
          severity: "info",
          autoHide: "no",
        })
      );

      const response = await closeEncounter(data);

      // Show success message based on whether encounter was closed or opened
      thunkAPI.dispatch(
        setShowStatus({
          message: isClosing
            ? "Encounter closed successfully"
            : "Encounter opened successfully",
          severity: "success",
          autoHide: "yes",
        })
      );

      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);

      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while closing/opening encounter",
          severity: "error",
          autoHide: "no",
        })
      );

      return thunkAPI.rejectWithValue(error || "Error while closing/opening encounter");
    }
  }
);

export const getConsultationInfoAction = createAsyncThunk(
  "get consultation info",
  async (data, thunkAPI) => {
    try {
      const response = await getConsultationInfo(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching consultation info",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while Fetching consultation info"
      );
    }
  }
);

export const getTemplateAction = createAsyncThunk(
  "get-template",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching template",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getTemplate(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching template",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while fetching template");
    }
  }
);

export const editTemplateAction = createAsyncThunk(
  "edit-template",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Updating template",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await editTemplate(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while updating template",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while updating template");
    }
  }
);
