import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import RootRouter from "../navigation";
import { persistor, store } from "../store/store";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const THEME = createTheme({
  typography: {
    fontFamily: `"Noto Sans Display", "Roboto", "Helvetica", "Arial", sans-serif`,
    button: {
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      main: "#7ABA57",
    },
    secondary: {
      main: "#f50057",
    },
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={THEME}>
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={null}>
            <RootRouter />
            <ToastContainer />
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;
