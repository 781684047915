import { createSlice } from "@reduxjs/toolkit";
import {
  addPatientAction,
  getPatientsByNumberAction,
  getPatientsAction,
} from "../actions";

const initialState = {
  isPatientAdded: null,
  isLoading: null,
  patients: null,
  searchedPatients: null,
  selectedPatientId: null,
  mobileNumber: null,
  firstName: null,
  gender: null,
  dob: null,
};

const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    setSelectedPatient: (state, action) => {
      state.selectedPatientId = action.payload.id;
      state.mobileNumber = action.payload.phone_number;
      state.firstName = action.payload.first_name;
      state.gender = action.payload.gender;
      state.dob = action.payload.dob;
    },
  },
  extraReducers: (builder) => {
    //add patients
    builder.addCase(addPatientAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addPatientAction.fulfilled, (state) => {
      state.isLoading = false;
      state.isPatientAdded = true;
    });
    builder.addCase(addPatientAction.rejected, (state) => {
      state.isLoading = false;
      state.isPatientAdded = false;
    });

    //get patients
    builder.addCase(getPatientsAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPatientsAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.patients = payload?.data?.patients;
    });
    builder.addCase(getPatientsAction.rejected, (state) => {
      state.isLoading = false;
      state.patients = null;
    });
    //get patients by number
    builder.addCase(getPatientsByNumberAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getPatientsByNumberAction.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;
        state.searchedPatients = payload?.result || null;
      }
    );
    builder.addCase(getPatientsByNumberAction.rejected, (state) => {
      state.isLoading = false;
      state.searchedPatients = null;
    });
  },
});

export const patientSelector = (state) => state.patient;
export const { setSelectedPatient } = patientSlice.actions;
export default patientSlice.reducer;
