import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addObservation, getObservationList
} from "../../api";
import { setShowStatus, unsetShowStatus } from "../slice/status-slice";

export const addObservationAction = createAsyncThunk(
  "add-observation",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Adding observation...",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await addObservation(data);
      thunkAPI.dispatch(
        setShowStatus({
          message: "Observation added successfully.",
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while adding observation",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while adding observation");
    }
  }
);

export const getObservationsListAction = createAsyncThunk(
  "get-observations-list",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching observations list",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getObservationList();
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching observations",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while fetching observations"
      );
    }
  }
);