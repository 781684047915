import {
  Button,
  Dialog,
  DialogActions,
  CircularProgress,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import closeIcon from "../../assets/close-icon.svg";

const ConfirmationBox = ({
  title,
  description,
  open,
  onConfirm,
  status,
  isLoding,
  closeHandler,
}) => {
  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      PaperProps={{ sx: { borderRadius: "20px", padding: "30px" } }}
    >
      <DialogTitle
        style={{
          justifyContent: "justify-between",
          color: "#0099CC",
          margin: "20px 30px 0",
          fontWeight: "600",
        }}
      >
        {title}
        <img
          onClick={closeHandler}
          height={50}
          width={50}
          style={{
            cursor: "pointer",
            position: "absolute",
            right: " 0",
            top: "2px",
          }}
          src={closeIcon}
        />
      </DialogTitle>
      <DialogContent
        sx={{
          margin: "0px 30px 0",
        }}
      >
        {description}
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: "flex-start",
          padding: "0px 20px 20px 20px",
          margin: "20px 30px",
        }}
      >
        <>
          <Button
            onClick={() => onConfirm(status)}
            sx={{
              color: "#FFF",
              minWidth: {xs: "80px" , sm: "150px"},
              borderRadius: "8px",
              height: "44px",
            }}
            disabled={isLoding}
            variant="contained"
          >
            {isLoding ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              "Confirm"
            )}
          </Button>
          <Button
            onClick={closeHandler}
            sx={{ minWidth: {xs: "80px" , sm: "150px"}, borderRadius: "8px", height: "44px" }}
            variant="outlined"
          >
            Cancel
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationBox;
