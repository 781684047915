import styled from "styled-components";
import { Modal } from '@mui/material';
import {
  DEVICE,
  HEADER_HEIGHT,
} from "../../../utilities/constants";

export const Container = styled.div`
  border-radius: 12px;
  background-color: #F5FBFC;
  opacity: 1;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);
  margin-bottom: 0;
  overflow-y: auto;
`;
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  align-items: center;
  margin-top: 1rem;
  justify-content: space-between;
`;
export const Header = styled.div`
  font-size: 24px;
  color: #000000;
  font-weight: 600;
`;
export const HeaderLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const EmptyListMessage = styled.div`
  margin-top: 100px;
  padding: 20px;
  color: red;
  text-align: center;
`;
export const ListWrapper = styled.div`
  padding: 25px;
  background-color: #ffffff;
  border-radius: 12px;
  margin: 20px 0;
`;
export const ListSection = styled.div`
  height: 56px;
  background-color: #FFFFFF;
  padding-left: 20px;
  margin-top: 20px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: row;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  @media ${DEVICE.mobileL} {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 15px;
  }
`;
export const ListSectionQuestionnaire = styled.div`
  background-color: #FFFFFF;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const ListDateTitle = styled.div`
  font-weight: bold;
  color: #0099CC;
  font-size: 18px;
`;
export const ListDateTitlQuestionnaire = styled.div`
  font-weight: bold;
  color: #0099CC;
  font-size: 18px;
  display: flex;
  flex: 1;
`;
export const CardsContainer = styled.div`
  display: grid;
  grid-gap: 24px;
  background-color: #FFFFFF;
  grid-template-columns: repeat(6, 1fr);

  @media ${DEVICE.tablet} {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 12px;
  }

  @media ${DEVICE.mobileL} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
  }
`;
export const CardsContainerForms = styled.div`
  display: grid;
  grid-gap: 24px;
  background-color: #FFFFFF;
  grid-template-columns: repeat(6, 1fr);

  @media ${DEVICE.tablet} {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 12px;
  }

  @media ${DEVICE.mobileL} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
  }
`;
export const ListItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  cursor: pointer;
  border: none;
  max-width: 100px;
  height: auto;
`;

export const ListItem = styled.div`
  display: flex;
  flex: 0.2;
  flex-direction: column;
  font-size: 13px;
  color: #404040;
  margin-top: 4px;
  margin-bottom: 25px;

  &:first-child {
    flex: 0.4;
    align-items: center;
  }

  &:nth-child(4),
  &:last-child {
    justify-content: center;
    flex-direction: column;
  }
`;
export const ListItemBold = styled.span`
  color: #000000;
  font-size: 16px;
`;
export const ListItemDate = styled.div`
  color: #808080;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 20px;
  flex: 1 1 calc(20% - 24px);
  max-width: 140px;
  padding-left: 10px;
`;
export const ListItemTop = styled.div`
  color: #404040;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 30px;
  max-width: 140px;
`;
export const ListItemDateForms = styled.div`
  color: #808080;
  font-size: 11px;
`;
export const ListItemForms = styled.div`
  color: #404040;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 12px;
  min-height: 15px;
  flex: 1 1 calc(20% - 24px);
  max-width: 140px;
`;
export const ArrowImage = styled.img``;
export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledModalContent = styled.div`
  margin: 20px;
  background-color: #fff;
  outline: none;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  overflow-y: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const StyledCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ffffff;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1000;
  padding: 5px;
`;
export const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
`;
export const ThumbImage = styled.img`
  width: 117px;
  height: 116px;
  object-fit: cover;
  margin-top: 5px;
  background-color: #FFFFFF;
`;
export const ThumbImageScans = styled.img`
  width: 117px;
  height: auto;
  object-fit: cover;
  margin-top: 5px;
  background-color: #FFFFFF;
`;
export const ThumbImageForms = styled.img`
  width: 66px;
  height: 66px;
  object-fit: cover;
  padding: 37px;
  background-color: #EBF7FB;
  border-radius: 8px;
`;
export const ThumbImageWrapper = styled.div`
  background-color: #EBF7FB;
  border-radius: 5px;
  max-width: 140px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const ReportTypeWrapper = styled.div`
  display: flex;
  max-width: 140px;
  justify-content: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;
export const ReportType = styled.div`
  background-color: #2FAED7;
  color: #FFFFFF;
  font-size: 11px;
  padding: 5px 8px;
  border-bottom-left-radius: 5px;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 91px;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const ContentType = styled.div`
  background-color: #0099CC;
  font-size: 9px;
  color: #FFFFFF;
  min-width: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 5px;
`;
export const ContentDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;
export const CardDetailsWrapper = styled.div`
  margin-top: 12px;
`;
export const LoaderWrapper = styled.div`
  margin: 15px;
`;
export const AddButton = styled.button`
  padding: 10px 20px;
  height: 44px;
  background: #7aba56 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #ffffff;
  outline: none;
  border-style: hidden;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 790px) {
    padding: 10px;
  }
  @media ${DEVICE.tablet} {
    display: none;
  }
`;

export const AddButtonQuestionnaire = styled.button`
  padding: 10px;
  min-width: 135px;
  height: 35px;
  background: #EBF7FB 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #0099CC;
  outline: none;
  border-style: hidden;
  font-size: 13px;
  cursor: pointer;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChartWrapper = styled.div`
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const ChartTitle = styled.div`
  padding: 17px;
  border-radius: 8px;
  width: 97%;
  background-color: #ebf7fb;
  font-weight: bold;
  color: #0099cc;
  font-size: 16px;
  margin-bottom: 20px;
`;

export const ChartContainer = styled.div`
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
`;

export const ListAddTempWrapper = styled.div`
  width: 240px;
  height: 25px;
  border-radius: 17px;
  padding: 5px;
  background-color: #EBF7FB;
  margin-left: 35px;
  display: flex;
  justify-content: space-around;

  @media ${DEVICE.mobileL} {
    margin-left: 0px;
    margin-top: 12px;
  }
`;

export const ListReviewButtonContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    background-color: #FFFFFF;
`;

export const ListButtonBase = styled.button`
  font-size: 11px;
  background-color: ${({ $active }) => ($active ? '#FFFFFF' : '#EBF7FB')};
  color: ${({ $active }) => ($active ? '#0099CC' : '#808080')};
  padding:  ${({ $active }) => ($active ? '5px 32px' : '0')};
  border-radius: 13px;
  cursor: pointer;
  border: none;
`;

export const ReviewButtonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    gap: 10px;
`;

export const ViewerButton = styled.button`
    background-color: #7ABA56;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 13px;
    min-width: 110px;
    height: 25px;
    border: none;
    cursor: pointer;

    &:disabled {
        background-color: #CCCCCC;
        color: #666666;
        cursor: not-allowed;
    }
`;

export const AddIcon = styled.img`
  height: 16px;
  width: 16px;
  margin-right: 8px;
`;

export const AddText = styled.div`
  font-size: 16px;
  color: #FFFFFF;
`;

export const SeeMoreWrapper = styled.div`
  background-color: #EBF7FB;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  width: 125px;
  height: 150px;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
`;

export const SeeMoreText = styled.span`
  font-size: 11px;
  color: #0099CC;
`;

export const NextArrow = styled.img`
    margin-left: 4px;
`;
