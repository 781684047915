import styled from "styled-components";
import {
  COLOR_PRIMARY_LIGHT,
  DEVICE,
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
} from "../../utilities/constants";

export const ParentContainer = styled.div`
  display: flex;
  padding: 40px;
  padding-bottom: 20px;
  gap: 40px;
  background-color: ${COLOR_PRIMARY_LIGHT};
  min-height: calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT});
  @media(max-width:780px){
    padding: 20px;
  }
`;

export const MainBody = styled.div`
  width: 80%;

  @media ${DEVICE.laptop} {
    padding: 0px 20px;
  }
  @media(max-width:1024px){
    width: 70%;
  }
  @media (max-width: 912px) {
    width: 60%;
  }
  @media(max-width:780px){
    width: 90%;
  }
  @media(min-width:1600px){
    width: 85%;
}
  @media(min-width:1930px){
      width: 90%;
  }
`;
