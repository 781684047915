import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  BackButtonContainer,
  BackArrowImg,
  BackText,
  HeadText,
  RecordsListContainer,
  PreviewImage,
  StyledTableCellHead,
  CategoryDropdownStyled,
  StyledTable,
  StyledTableBody,
  StyledTableCell,
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
  StyledTableCellImage,
  DeleteIcon,
  NextButton,
  ParentContainer,
  StyleTableName,
  ValidationError,
  EmptyListMessage
} from "./style.components";
import { Select, MenuItem, Box } from "@mui/material";
import { IBackArrow, IDeleteIcon } from "../../assets";
import StatusDropdown from "../../components/shared/StatusDropdown/StatusDropdown";
import { pollRecordsAction, sendRecordsAction, getRecordsAction, closeEncounterAction } from "../../store/actions";
import { patientSelector } from "../../store/slice/patient.slice";
import { Loader, SnackBar } from "../../components/shared";
import { splitPdfAction } from "../../store/actions";
import Breadcrumb from "../../components/layout/Breadcrumb";

const categoryOptions = [
  { label: "Report", value: "report" },
  { label: "Prescription", value: "prescription" },
  { label: "Medical Letter", value: "medical letter" },
];

const dropdownOptions = [
  { label: "Available", value: "AVAILABLE" },
  { label: "Restricted", value: "RESTRICTED" },
];

const SendRecords = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedPatientId } = useSelector(patientSelector);
  const [records, setRecords] = useState(null);
  const [recordList, setRecordList] = useState(records || []);
  const [splitCandidates, setSplitCandidates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [polling, setPolling] = useState(!(location.pathname === "/new-encounter/add-records/send-records"));
  const [validationMessages, setValidationMessages] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState(
    records ? records?.map(() => dropdownOptions[0].value) : []
  );
  const [selectedCategories, setSelectedCategories] = useState(records ? records?.map(() => "") : []);
  const [splittingRecords, setSplittingRecords] = useState({});
  const [loadingCategories, setLoadingCategories] = useState(records ? records.map(() => true) : []);
  const isNewEncounter = location.pathname === "/new-encounter/add-records/send-records";
  const isScanReport = location.pathname === "/patient-records/scan-info/add-records/send-records";
  const isAddRecords = location.pathname === "/patient-records/add-records/send-records";
  const [uppyResponse, setUppyResponse] = useState(null);
  const [encounterId, setEncounterId] = useState(null);
  const [consultationId, setConsultationId] = useState(null);
  const [fhirId, setFhirId] = useState(null);

  const eligibleForSplit = recordList.some(
    (record) => record?.document_ranges?.length > 1
  );
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);

  const handleHomeNavigation = (event) => {
    event.preventDefault();
    navigate('/');
  };

  const breadCrumbLinks = [
    {
      label: "Home",
      onClick: handleHomeNavigation,
    },
    {
      label: isNewEncounter ? `Encounters` : `Health Data`,
      onClick: () => {
        if (isNewEncounter) {
          navigate(`/new-encounter`, {
            state: { id: encounterId, consultationId: consultationId },
          });
        } else {
          navigate(`/patient-records?patient_id=${selectedPatientId}`);
        }
      },
    },
    ...(fhirId
      ? [
          {
            label: "Scan Info",
            onClick: () => navigate(`/patient-records/scan-info`, {
              state: { studyFhirId: fhirId }
            }),
          },
        ]
      : []),
    {
      label: "Add Records",
      href: `/patient-records/add-records`,
    },
    {
      label: "Send Records",
    },
  ];

  useEffect(() => {
    setRecords(location?.state?.records);
    setUppyResponse(location?.state?.uppyResponse);
    setEncounterId(location?.state?.encounterId);
    setConsultationId(location?.state?.consultationId);
    setFhirId(location.state?.fhirId);
  }, [location]);

  useEffect(() => {
    if (records && uppyResponse?.uploadedFiles) {
      const mergedRecordList = records.map((record) => {
        const matchedFile = uppyResponse.uploadedFiles.find(
          (file) => file.file_name === record.original_file_name
        );
        if (matchedFile) {
          return {
            ...record,
            file_id: matchedFile.file_id,
            file_name: matchedFile.file_name,
            mimetype: matchedFile.mimetype,
            file_size: matchedFile.file_size,
          };
        }
        return record;
      });
  
      if (isNewEncounter) {
        mergedRecordList.forEach((record) => {
          if (record.file_name === "summary.pdf") {
            record.category = "medical letter";
          } else if (record.file_name === "transcription.pdf") {
            record.category = "medical letter";
          } else if (record.file_name === "prescription.pdf") {
            record.category = "prescription";
          }
        });
  
        setLoadingCategories(mergedRecordList.map(() => false));
        setSelectedCategories(mergedRecordList.map((record) => record.category || ""));
      }

      if (isScanReport) {
        mergedRecordList.forEach((record) => {
          record.category = "report";
        });
        setLoadingCategories(mergedRecordList.map(() => false));
        setSelectedCategories(mergedRecordList.map((record) => record.category || ""));
      }
  
      if(isAddRecords){
        setSelectedCategories(mergedRecordList.map(() => ""));
        setLoadingCategories(mergedRecordList.map(() => true));
      }

      setRecordList(mergedRecordList);
      setSelectedStatuses(mergedRecordList.map(() => dropdownOptions[0].value));
    }
  }, [records, uppyResponse]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPolling(false);
  
      setLoadingCategories((prevLoadingCategories) => {
        const updatedLoadingCategories = [...prevLoadingCategories];
  
        setValidationMessages((prevValidationMessages) => {
          const updatedValidationMessages = [...prevValidationMessages];
  
          recordList.forEach((record, index) => {
            if (prevLoadingCategories[index]) {
              updatedValidationMessages[index] = "Cannot find category, please select";
              updatedLoadingCategories[index] = false;
            }
          });
  
          return updatedValidationMessages;
        });
  
        return updatedLoadingCategories;
      });
    }, 32000);
  
    return () => clearTimeout(timer);
  }, [recordList]);
  

  useEffect(() => {
    if (polling && uppyResponse?.assemblyID && loadingCategories) {
      const pollingInterval = setInterval(() => {
        getPollRecords();
      }, 4000);
      return () => {
        clearInterval(pollingInterval);
      };
    }
  }, [polling, uppyResponse?.assemblyID, loadingCategories]);
  

  useEffect(() => {
    const hasValidationErrors = validationMessages.some((msg) => msg !== "");
    const areAllCategoriesSelected = selectedCategories.every((category) => category !== "");
    setIsAddButtonDisabled(hasValidationErrors || !areAllCategoriesSelected);
  }, [validationMessages, selectedCategories]);

  const getPollRecords = async () => {
    try {
      if (!polling) return;
      const response = await dispatch(pollRecordsAction(uppyResponse?.assemblyID));
      if (response?.payload?.files.length > 0) {
        const updatedCategories = [...selectedCategories];
        const updatedValidationMessages = [...validationMessages];
        const updatedRecordList = [...recordList];
        const updatedLoadingCategories = [...loadingCategories];
        let newSplitCandidates = [...splitCandidates];
  
        response?.payload?.files.forEach((file) => {
          const recordIndex = recordList.findIndex((record) => record?.file_id === file.file_id);
  
          if (recordIndex !== -1) {
            const { contains_multiple_types, document_ranges } = file.classification_result;
  
            if (contains_multiple_types) {
              updatedCategories[recordIndex] = "";
              updatedValidationMessages[recordIndex] = "Multiple categories detected";
  
              const updatedSplitRecord = {
                ...recordList[recordIndex],
                document_ranges,
                contains_multiple_types,
              };
  
              if (!newSplitCandidates.find(record => record.file_id === updatedSplitRecord.file_id)) {
                newSplitCandidates.push(updatedSplitRecord);
              }
            } else {
              const recordType = document_ranges[0]?.record_type.toLowerCase();
              if (recordType === "other") {
                updatedCategories[recordIndex] = ""
                updatedValidationMessages[recordIndex] = "Could not detect category";
              } else {
                updatedCategories[recordIndex] = recordType.toLowerCase();
                updatedValidationMessages[recordIndex] = "";
              }
            }
  
            updatedRecordList[recordIndex] = {
              ...updatedRecordList[recordIndex],
              document_ranges,
              contains_multiple_types,
            };
  
            updatedLoadingCategories[recordIndex] = "";
          }
        });
  
        setSplitCandidates(newSplitCandidates);
        setSelectedCategories(updatedCategories);
        setValidationMessages(updatedValidationMessages);
        setRecordList(updatedRecordList);
        setLoadingCategories(updatedLoadingCategories);
  
        const allFilesProcessed = updatedLoadingCategories.every((loading) => !loading);
        if (allFilesProcessed) {
          setPolling(false);
        }
      }
    } catch (error) {
      console.error("Error fetching records:", error);
    }
  };   

  const handleStatusChange = (index) => (event) => {
    const newStatuses = [...selectedStatuses];
    newStatuses[index] = event.target.value;
    setSelectedStatuses(newStatuses);
  };

  const handleCategoryChange = (index) => (event) => {
    const newCategories = [...selectedCategories];
    const newValidationMessages = [...validationMessages];
    newCategories[index] = event.target.value;
    if (newCategories[index] !== "") {
      newValidationMessages[index] = "";
    }
    setSelectedCategories(newCategories);
    setValidationMessages(newValidationMessages);
  };

  const handleRemoveRecord = (index) => {
    setRecordList((prevList) => prevList.filter((_, i) => i !== index));
  };

  const handleSplitAll = async () => {
    const splittingState = splitCandidates.reduce((acc, record) => {
      acc[record.file_id] = true;
      return acc;
    }, {});
    setSplittingRecords(splittingState);
  
    const splitPromises = splitCandidates?.map((record) => {
      const { document_ranges, file_gcp_location, file_id } = record;
      const splitDetails = document_ranges?.map((range) => ({
        page_range: `${range.start_page}-${range.end_page}`,
        type: range.record_type.toLowerCase(),
        record_name: range.record_name,
      }));
  
      const payload = {
        intended_split_pdf_path: file_gcp_location,
        split_details: splitDetails,
        file_id: file_id,
      };
  
      return dispatch(splitPdfAction(payload)).unwrap();
    });
  
    try {
      const splitResponses = await Promise.all(splitPromises);
      const newSplitFiles = splitResponses.flatMap((response) => response.split_files);
  
      const splitFileIds = splitCandidates?.map((record) => record.file_id);
      const updatedRecordList = recordList.filter(
        (record) => !splitFileIds.includes(record.file_id)
      );
  
      const newRecords = newSplitFiles?.map((file, index) => {
        const recordType = file.file_type.toLowerCase();
  
        return {
          file_id: file.file_id,
          converted_file_name: file.file_name,
          file_name: file.file_name,
          file_size: file.file_size,
          mimetype: file.mimetype,
          thumbnail_gcp_location: file.thumbnail_url,
          file_gcp_location: file.uploaded_file_url,
          file_type: recordType,
          status: "AVAILABLE",
        };
      });
  
      // Preserve existing selected categories and append new categories for split files
      const splitCategories = newSplitFiles.map((file) => file.file_type.toLowerCase() || "");
  
      const finalRecordList = [...updatedRecordList, ...newRecords];
      setRecordList(finalRecordList);
  
      // Update validation messages and loading state based on the "other" record type
      setLoadingCategories((prevLoadingCategories) => {
        const updatedLoadingCategories = [...prevLoadingCategories];
        setValidationMessages((prevValidationMessages) => {
          const updatedValidationMessages = [...prevValidationMessages];
  
          newSplitFiles.forEach((file, index) => {
            const recordType = file.file_type.toLowerCase();
            if (recordType === "other") {
              updatedValidationMessages[index] = "Cannot find category, please select";
              updatedLoadingCategories[index] = false;
            }
          });
  
          return updatedValidationMessages;
        });
  
        return updatedLoadingCategories;
      });
  
      const newCategories = [
        ...selectedCategories.filter((category, index) =>
          updatedRecordList.some(record => record.file_id === recordList[index]?.file_id)
        ),
        ...splitCategories
      ];
  
      const newStatuses = [
        ...selectedStatuses.filter((status, index) =>
          updatedRecordList.some(record => record.file_id === recordList[index]?.file_id)
        ),
        ...newRecords.map(() => "AVAILABLE")
      ];
  
      const newValidationMessages = [
        ...validationMessages.filter((message, index) =>
          updatedRecordList.some(record => record.file_id === recordList[index]?.file_id)
        ),
        ...newRecords.map((record, index) => {
          return record.file_type === "other" ? "Cannot find category, please select" : "";
        }),
      ];
  
      // Update the state to reflect new values
      setSelectedCategories(newCategories);
      setSelectedStatuses(newStatuses);
      setValidationMessages(newValidationMessages);
      setSplitCandidates([]);
    } catch (error) {
      console.error("Error splitting files:", error);
    } finally {
      setSplittingRecords({});
    }
  };  

  const handleAddRecords = async () => {
    try {
      setIsLoading(true);
      const groupedRecords = recordList.reduce((acc, record, index) => {
        const fileType = selectedCategories[index]?.toLowerCase().replace(" ", "_");
  
        if (!acc[fileType]) {
          acc[fileType] = [];
        }
        acc[fileType].push({
          file_id: record?.file_id,
          mimetype: 'application/pdf',
          file_size: record?.file_size,
          file_name: record?.converted_file_name,
          file_type: fileType,
          uploaded_file_url: record?.uploaded_file_url || record?.file_gcp_location,
          status: selectedStatuses[index],
        });
  
        return acc;
      }, {});
  
      const sendRecordPromises = Object.keys(groupedRecords).map((fileType) => {
        let payload;
        if(isNewEncounter){
          payload = {
            patient_id: selectedPatientId,
            report_info: groupedRecords[fileType],
            encounter_id: encounterId
          };
        }
        else if(isScanReport){
          payload = {
            patient_id: selectedPatientId,
            report_info: groupedRecords[fileType],
            scan_id: fhirId
          };
        }
        else {
          payload = {
            patient_id: selectedPatientId,
            report_info: groupedRecords[fileType],
          };
        }
        return dispatch(sendRecordsAction({ type: fileType, payload })).unwrap();
      });
      const responses = await Promise.all(sendRecordPromises);
  
      const allSuccess = responses.every((response) => response?.status === "success");
      if (allSuccess) {
        if (isNewEncounter) {
          const closeEncounterPayload = {
            patient_id: selectedPatientId,
            encounter_id: encounterId,
            set_to_close: true,
          };
          try {
            const response = await dispatch(closeEncounterAction(closeEncounterPayload)).unwrap();
            if(response.status === "success"){
              navigate(`/encounters`, { state: {consultationId: consultationId}});
            }
          } catch (error) {
            console.error("Error updating encounter status:", error);
          }
        } else {
          const callUpdatedData = Object.entries(groupedRecords).map(
            async ([recordType]) =>
              dispatch(
                getRecordsAction({
                  record_type: recordType,
                  patient_id: selectedPatientId,
                  limit: 5,
                })
              )
          );
          await Promise.all(callUpdatedData);
          if(isScanReport){
            navigate(`/patient-records/scan-info`, {
              state: { studyFhirId: fhirId }
            })
          }
          else{
            navigate(`/patient-records?patient_id=${selectedPatientId}`);
          }
        }
      }
    } catch (error) {
      console.error("Error adding records:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ParentContainer>
      <Breadcrumb links={breadCrumbLinks} />
      <HeadText>Here is the system detected details, please confirm and edit as required</HeadText>
      {!isLoading ? (
        <RecordsListContainer>
          <StyledTableContainer>
            <StyledTable>
              <StyledTableHead>
                <StyledTableRow>
                  <StyledTableCellHead>Preview</StyledTableCellHead>
                  <StyledTableCellHead>File Name</StyledTableCellHead>
                  <StyledTableCellHead>Category</StyledTableCellHead>
                  <StyledTableCellHead>Status</StyledTableCellHead>
                  <StyledTableCellHead>Remove</StyledTableCellHead>
                </StyledTableRow>
              </StyledTableHead>
              <StyledTableBody>
                {recordList.map((record, index) => (
                  <StyledTableRow key={index}>
                    {splittingRecords[record.file_id] ? (
                      <StyledTableCell colSpan={6} style={{ textAlign: "center" }}>
                        <Loader text={"Splitting record..."} />
                      </StyledTableCell>
                    ) : (
                      <>
                        <StyledTableCellImage>
                          <PreviewImage src={record?.thumbnail_gcp_location} alt={record?.converted_file_name || record?.file_name} />
                        </StyledTableCellImage>
                        <StyledTableCell>
                          <StyleTableName>{record?.converted_file_name || record?.file_name}</StyleTableName>
                        </StyledTableCell>
                        {isAddRecords && (
                          <StyledTableCell>
                            {loadingCategories[index] ? (
                              <Loader height={"20px"} />
                            ) : (
                              <CategoryDropdownStyled
                                value={selectedCategories[index]}
                                onChange={handleCategoryChange(index)}
                                displayEmpty
                                input={<Select variant="outlined" fullWidth />}
                                sx={{ maxHeight: '45px' }}
                                disabled={validationMessages[index] === "Multiple categories detected"}
                              >
                                <MenuItem value="" disabled>Select Category</MenuItem>
                                {categoryOptions.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </CategoryDropdownStyled>
                            )}
                            {validationMessages[index] && <ValidationError>{validationMessages[index]}</ValidationError>}
                          </StyledTableCell>
                        )}
                        {isScanReport && (
                        <StyledTableCell>
                          <StyleTableName>Report</StyleTableName>
                        </StyledTableCell>
                        )}
                        {isNewEncounter && (
                        <StyledTableCell>
                          <StyleTableName>
                            {record?.converted_file_name.includes('summary')
                              ? 'Medical Letter'
                              : record?.converted_file_name.includes('transcription')
                              ? 'Medical Letter'
                              : 'Prescription'}
                          </StyleTableName>
                        </StyledTableCell>
                        )}
                        <StyledTableCell>
                          <Box mt={1} mb={1}>
                            <StatusDropdown
                              selectedStatus={selectedStatuses[index]}
                              handleChange={handleStatusChange(index)}
                              dropdownOptions={dropdownOptions}
                            />
                          </Box>
                        </StyledTableCell>
                        <StyledTableCell>
                          <DeleteIcon
                            src={IDeleteIcon}
                            onClick={() => handleRemoveRecord(index)}
                            style={{
                              cursor: loadingCategories[index] ? 'not-allowed' : 'pointer',
                              opacity: loadingCategories[index] ? 0.5 : 1,
                            }}
                            disabled={loadingCategories[index]}
                          />
                        </StyledTableCell>
                      </>
                    )}
                  </StyledTableRow>
                ))}
              </StyledTableBody>
            </StyledTable>
            {recordList.length === 0 && (
              <EmptyListMessage>No records</EmptyListMessage>
            )}
          </StyledTableContainer>
          <div style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
            {isAddRecords && (
              <NextButton onClick={handleSplitAll} disabled={!eligibleForSplit || recordList?.length === 0}>
                {Object.keys(splittingRecords).length > 0 ? <Loader height={"0"} /> : "Split All"}
              </NextButton>
            )}
            <NextButton disabled={isAddButtonDisabled} onClick={handleAddRecords}>
              Add Records
            </NextButton>
          </div>
        </RecordsListContainer>
      ) : (
        <Loader text={"Adding record..."} />
      )}
      <SnackBar />
    </ParentContainer>
  );
};

export default SendRecords;