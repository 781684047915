import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  runAssessment,
  getSingleRecord,
  generateDoc,
  uploadAudio,
  startTranscriptionJob,
  getNotesAudio,
  uploadRecords,
  getOpenAITranscription,
  sendRecords,
  sendScans,
} from "../../api";
import { setShowStatus, unsetShowStatus } from "../slice/status-slice";

export const runAssessmentAction = createAsyncThunk(
  "run-assessment",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: `Running AI assessment`,
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await runAssessment(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while running AI assessment",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while running AI assessment");
    }
  }
);

export const getSingleRecordAction = createAsyncThunk(
  "get-single-record",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: `Fetching a record`,
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getSingleRecord(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching a record",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while fetching a record");
    }
  }
);

export const generateDocAction = createAsyncThunk(
  "generate-doc",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: `Generating Document`,
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await generateDoc(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while generatig doc",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while generating doc");
    }
  }
);

export const uploadAudioAction = createAsyncThunk(
  "upload-audio",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: `Upload Audio`,
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await uploadAudio(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while uploading audio",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while uploading audio");
    }
  }
);

export const startTranscriptionJobAction = createAsyncThunk(
  "start-transcript",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: `Start Transcript`,
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await startTranscriptionJob(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while starting transcript",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while starting transcript");
    }
  }
);

export const getNotesAudioAction = createAsyncThunk(
  "get-notes-audio",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: `Fetching notes`,
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getNotesAudio(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching notes",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while fetching notes");
    }
  }
);

export const uploadRecordsAction = createAsyncThunk(
  "upload-records",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: `Upload Records`,
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await uploadRecords(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while uploading records",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while uploading records");
    }
  }
);

export const getOpenAITranscriptionAction = createAsyncThunk(
  "get-transcription-openAI",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: `Fetching Transcription`,
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getOpenAITranscription(data);
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching transcription",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while fetching transcription");
    }
  }
);

export const sendRecordsAction = createAsyncThunk(
  "send-records",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: `Sending Records`,
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await sendRecords(data);
      thunkAPI.dispatch(
        setShowStatus({
          message: "Records added successfully.",
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while sending records",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while sending records");
    }
  }
);

export const sendScansAction = createAsyncThunk(
  "send-scans",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: `Sending Scans`,
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await sendScans(data);
      thunkAPI.dispatch(
        setShowStatus({
          message: "Scans added successfully.",
          severity: "success",
          autoHide: "no",
        })
      );
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while sending scans",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while sending scans");
    }
  }
);