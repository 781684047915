import { Box, Modal, Select, MenuItem, InputAdornment } from "@mui/material";
import React, {useState} from "react";
import {
  ButtonWrapper,
  SaveButton,
  CloseButton,
  CloseButtonWrapper,
  StyledTextField,
  StyledLabel,
  ModalHeader,
  ModalBody,
  CustomSelect,
  MobileNumWrapper,
  StyledFormControl,
} from "./style.components";
import { ICloseModal } from "../../../assets";
import { PhoneNumberValidation } from "../../shared";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pl: 4,
  pb: 4,
};

const AddPatientModal = ({ open, handleClose, handleSave }) => {
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [gender, setGender] = useState(null);
  const [dob, setDob] = useState(null);
  const [email, setEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const onSavePatient = () => {
    const payload = {};
    if (firstName?.trim()) payload.first_name = firstName;
    if (lastName?.trim()) payload.last_name = lastName;
    if (phoneNumber?.trim()) payload.phone_number = "+" + phoneNumber;
    if (email?.trim()) payload.email = email;
    if (gender) payload.gender = gender;
    if (dob) payload.date_of_birth = dob;
    const isEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);
    const isNumber = phoneNumber && phoneNumber.toString().length >= 12;
      if (phoneNumber && !isNumber) {
        setErrorMsg("Please enter a valid  mobile number");
        return;
      }

      if (email && !isEmail) {
        setErrorMsg("Please enter a valid email");
        return;
      }
      if (!email && !phoneNumber) {
        setErrorMsg("*Required a valid mobile number or Email");
        return;
      }

    handleSave(payload);
    setFirstName(null);
    setLastName(null);
    setPhoneNumber(null);
    setDob(null);
    setEmail("");
    setGender(null);
  };

  const handleCloseBtn = () => {
    handleClose();
    setFirstName(null);
    setLastName(null);
    setPhoneNumber(null);
    setDob(null);
    setEmail("");
    setGender(null);
  }

  const isSaveEnabled = firstName?.trim() && (phoneNumber?.trim() || email?.trim());

  const handlePhoneNumberChange = (newPhoneNumber) => {
    setPhoneNumber(newPhoneNumber)
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseBtn}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <CloseButtonWrapper>
          <CloseButton src={ICloseModal} onClick={handleCloseBtn} />
        </CloseButtonWrapper>
        <ModalHeader id="modal-modal-title" variant="h6" component="h2">
          Add Patient
        </ModalHeader>
        <ModalBody>
          <StyledLabel>Mobile Number</StyledLabel>
          <PhoneNumberValidation onPhoneNumberChange={handlePhoneNumberChange} />
          <StyledLabel>Email</StyledLabel>
          <StyledTextField
            variant="outlined"
            size="small"
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
          <StyledLabel>Patient Name</StyledLabel>
          <Box display="flex" justifyContent="space-between">
            <StyledTextField style={{ width: "49%", marginRight: "2%" }}
              variant="outlined" size="small" placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
            <StyledTextField style={{ width: "49%" }}
              variant="outlined" size="small" placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
          </Box>

          <Box display="flex" justifyContent="space-between" marginTop="16px"  alignItems={"end"}>
            <Box style={{ width: "49%" }}>
              <StyledLabel>Gender (Optional)</StyledLabel>
              <StyledFormControl variant="outlined" size="small" fullWidth  >
                <Select
                  displayEmpty
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  renderValue={(selected) => {
                    if (!selected) {
                      return 'Select Gender' 
                    }
                    return selected; 
                  }}
                >
                  <MenuItem value="" disabled>
                    Select Gender
                  </MenuItem>
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                </Select>
              </StyledFormControl>
            </Box>
            <Box style={{ width: "49%" }}>
              <StyledLabel>Date of Birth (Optional)</StyledLabel>
              <StyledTextField
                variant="outlined"
                size="small"
                type="date"
                title={dob}
                InputLabelProps={{
                  shrink: true,
                }}
                value={dob}
                onChange={(e) => setDob(e.target.value)}
              />
            </Box>
          </Box>
          {errorMsg && <Box sx={{ fontSize: "10px", color: 'red' }} mt={2}>
            {errorMsg}
          </Box>}
          <ButtonWrapper>
            <SaveButton onClick={onSavePatient} disabled={!isSaveEnabled}>Save</SaveButton>
          </ButtonWrapper>
        </ModalBody>
      </Box>
    </Modal>
  );
};
export default AddPatientModal;
