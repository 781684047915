import { createSlice } from "@reduxjs/toolkit";
import {
  getSingleRecordAction,
} from "../actions";

const initialState = {
  isLoading: null,
  singleRecordObj: null,
};

const singleRecordSlice = createSlice({
    name: "single-record",
    initialState,
    reducers: {
      resetAiSummaries: (state) => {
          if (state.singleRecordObj) {
              state.singleRecordObj.aiSummaries = [];
          }
      }
    },
    extraReducers: (builder) => {
  
      //get single record
      builder.addCase(getSingleRecordAction.pending, (state) => {
        state.isLoading = true;
      });
      builder.addCase(getSingleRecordAction.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        if (!state.singleRecordObj) {
            state.singleRecordObj = {};
        }
        if (!state.singleRecordObj.aiSummaries) {
            state.singleRecordObj.aiSummaries = [];
        }
        state.singleRecordObj.aiSummaries.push(payload?.result?.ai_summary);
      });  
      builder.addCase(getSingleRecordAction.rejected, (state) => {
        state.isLoading = false;
        state.singleRecordObj = {};
      });
    },
});
  
export const singleRecordSelector = (state) => state?.singleRecord?.singleRecordObj;
export default singleRecordSlice.reducer;
export const { resetAiSummaries } = singleRecordSlice.actions;