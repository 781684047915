import styled from "styled-components";
import {
  COLOR_PRIMARY,
  COLOR_SECONDARY,
  COLOR_VARIANT,
} from "../../utilities/constants";

export const VerticalSep = styled.div`
  height: ${props => props.size ? `${props.size}px` : "30px"};
`;
export const VerticalLineSep = styled.div((margin) => ({
  margin: margin ? `${margin}px 0px` : "10px 0px",
  height: "1px",
  backgroundColor: "#D7EFF7",
}));

export const HorizontalSep = styled.div`
width: ${props => props.size ? `${props.size}px` : "30px"};
`;
export const HorizontalLineSep = styled.div((margin) => ({
  margin: margin ? `0px ${margin}px` : "0px 10px",
  width: "1px",
  backgroundColor: "#D7EFF7",
}));

export const DetailsTile = styled.div(() => ({
  backgroundColor: COLOR_SECONDARY,
  padding: "32px 48px",
  borderRadius: "12px",
  boxShadow: "0 4px 6px -6px #2477B3",
}));

export const ParamList = styled.div(() => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
}));