import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addPatient,
  getPatientsByNumber,
  getPatients,
} from "../../api";
import { setShowStatus, unsetShowStatus } from "../slice/status-slice";

export const addPatientAction = createAsyncThunk(
  "add-patient",
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Adding patient...",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await addPatient(data);
      thunkAPI.dispatch(
        setShowStatus({
          message: "Patient added successfully.",
          severity: "success",
        })
      );
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while adding patient",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(error || "Error while adding patient");
    }
  }
);

export const getPatientsAction = createAsyncThunk(
  "get-patients",
  async (_, thunkAPI) => {
    try {
      thunkAPI.dispatch(
        setShowStatus({
          message: "Fetching patients",
          severity: "info",
          autoHide: "no",
        })
      );
      const response = await getPatients();
      thunkAPI.dispatch(unsetShowStatus());
      return response;
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        setShowStatus({
          message: error || "Error while fetching patients",
          severity: "error",
          autoHide: "no",
        })
      );
      return thunkAPI.rejectWithValue(
        error || "Error while fetching patients"
      );
    }
  }
);

export const getPatientsByNumberAction = createAsyncThunk(
  "get-patients-by-number",
  async (payload, thunkAPI) => {
    try {
      const response = await getPatientsByNumber(payload);
      return response;
    } catch (error) {
      console.error(error);
      return thunkAPI.rejectWithValue(error || "Error while fetching patients");
    }
  }
);
