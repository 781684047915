import styled from "styled-components";
import {
  HEADER_HEIGHT, DEVICE
} from "../../../utilities/constants"; 

export const Container = styled.div`
  border-radius: 12px;
  background-color: #F5FBFC;
  opacity: 1;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);
  margin-bottom: 0;
  overflow-y: auto;

  @media ${DEVICE.tablet} {
    margin: 0;
    margin-top: 24px;
  }
`;

export const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 24px;
  color: #000000;
`;

export const ArrowBackImage = styled.img`
  cursor: pointer;
`;

export const ObservationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  margin-top: 24px;
  @media ${DEVICE.mobileL} {
    flex-direction: column;
  }
`;
export const ObservationSelector = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media ${DEVICE.mobileL} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
`;
export const ObservationTile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 16px;
  width: 311px;
  height: 52px;
  background-color: ${props => props.isSelected ? '#D7EFF7' : '#FFFFFF'};
  border-radius: 12px;
  margin-bottom: 16px;

  @media ${DEVICE.tablet} {
    width: 200px;
  }

  @media ${DEVICE.mobileL} {
    width: 160px;
    height: 22px;
    padding: 13px 0;
    justify-content: center;
    margin-bottom: 0;
  }
`;

export const ObservationDetail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;

  @media ${DEVICE.mobileL} {
    gap: 5px;
  }
`;
export const ObservationThumb = styled.img`
  width: 40px;
  height: 40px;

  @media ${DEVICE.mobileL} {
    width: 24px;
    height: 24px;
  }
`;
export const ObservationName = styled.div`
  font-size: 16px;
  color: #0099CC

  @media ${DEVICE.mobileL} {
    color: #000000;
  }
`;
export const ObservationEntry = styled.div`
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 30px 48px;
  width: 100%;
  min-height: 724px;

  @media ${DEVICE.mobileL} {
    padding: 20px 12px;
    width: 92%;
    height: auto;
  }
`;
export const NextArrowIcon = styled.img`
  cursor: pointer;
  width: 24px;
  height: 24px;

  @media ${DEVICE.mobileL} {
    display: none
  }
`;
export const ObservationInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  margin-bottom: 40px;
`;
export const ObservationLogoSmall = styled.img`
  width: 24px;
  height: 24px;
`;
export const ObservationNameSmall = styled.div`
  font-size: 16px;
  color: #000000;
`;
export const SaveButton = styled.button`
    border-radius: 8px;
    font-size: 16px;
    color: #FFFFFF;
    padding: 12px 50px;
    background-color: #7ABA56;
    border: none;
    width: 365px;
    cursor: pointer;
    margin-top: 40px;

    @media ${DEVICE.mobileL} {
      width: 216px;
    }
`;

export const DateContainer = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
`;