import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Footer, Header } from "../../components/shared";
import useIsAuthenticated from "../../hooks/useIsAuthenticated";
import { MainBody, ParentContainer } from "./style.components";

const Root = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    } else {
      if (location.pathname === "/") {
        navigate("/home");
      }
    }
  }, [location, isAuthenticated, navigate]);

  return (
    <>
      <Header />
      <ParentContainer>
        <MainBody>
          <Outlet />
        </MainBody>
      </ParentContainer>
    </>
  );
};

export default Root;
