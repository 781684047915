import styled from "styled-components";
import { DEVICE, HEADER_HEIGHT } from "../../../utilities/constants";
export const StickyHeader = styled.div`
  background-color: #F5FBFC;
  color: #333;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 48px;
  @media ${DEVICE.mobileL} {
    padding: 18px 10px !important;
  }
  @media ${DEVICE.tablet} {
    padding: 12px 24px;
  }
`;

export const MobileHeader = styled.div`
  background-color: #fff;
  color: #333;
  z-index: 1;
  height: ${HEADER_HEIGHT + 1}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 52px;
  @media ${DEVICE.mobileL} {
    padding: 18px 16px;
  }
  @media ${DEVICE.tablet} {
    padding: 12px 24px;
  }
`;

export const LogoImageCont = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const HamburgerMenu = styled.img`
`
export const LogoImage = styled.img`
  height: 48px;
  width: auto;
  @media (max-width: 600px) {
    height: 35px;
  }
  @media ${DEVICE.mobileL} {
    height: 30px;
  }
  cursor: pointer;
`;

export const SmallTabs = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MobileNav = styled.div`
  display: none;
  @media ${DEVICE.laptop} {
    display: flex;
  }
`;

export const MobileTabs = styled.div`
  @media ${DEVICE.laptop} {
    position: absolute;
    display: block;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    top: 0px;
    left: 0px;
    z-index: 1000;
  }
`;

export const HamburgerIconCont = styled.div`
  display: none;
  @media ${DEVICE.tablet} { 
    display: block;
    margin-left: 20px;
    padding-top: 5px;
  }
  @media ${DEVICE.mobileL} { 
    margin-left: 18px;
  }
`;

export const FlagIcon = styled.img`
  display: none;
  @media ${DEVICE.tablet } { 
    display: block;
  }
`;

export const NavTabs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  
   @media ${DEVICE.laptopL} {
  gap:0px;
  }
  @media ${DEVICE.laptop} {
    display: none;
  }
`;
