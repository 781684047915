import styled from "styled-components";
import {
  FONT_FAMILY_GENERAL,
  DEVICE
} from "../../utilities/constants"; 

export const Container = styled.div`
  padding: 25px;
  background-color: #F5FBFC;
  overflow-y: auto;
  overflow-x: hidden;
  @media ${DEVICE.tablet} {
    padding: 20px 4px;
  }
`;
export const Wrapper = styled.div`
  display: flex;
`;
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 48px;
  align-items: center;
  justify-content: center;
  flex-wrap:wrap;

  @media ${DEVICE.tablet} {
    margin-bottom: 24px;
  }
  @media ${DEVICE.mobileL} {
    flex-direction: column;
  }
`;

export const HeaderRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: auto;

  @media ${DEVICE.tablet} {
    width: 100%; /* Full width on smaller screens */
    justify-content: center; /* Center search bar on smaller screens */
    margin-top: 20px;
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 8px;
  border: 1px solid #C5DDE0;
  padding: 10px 20px;
  width: 658px;
  height: 40px; /* Adjusted for better visibility */

  @media ${DEVICE.tablet} {
    width: 94%; /* Full width on smaller screens */
    margin-bottom: 24px;
  }
  @media ${DEVICE.mobileL} {
    width: 87%;
    margin-bottom: 24px;
  }
`;
export const Header = styled.div`
  font-size: 24px;
  color: #0099CC;
  font-weight: 600;
  // margin-right: 150px;
  display: flex;
  width: 100%;
  @media ${DEVICE.tablet} {
    margin-right: 0;
  }
`;
export const SearchInput = styled.input`
  font-size: 16px;
  outline: none;
  flex: 1;
  background: transparent;
  color: #808080;
  border: none;
`;
export const HeaderLeft = styled.div`
 @media ${DEVICE.tablet} {
    padding-bottom: 12px;
  }
`;
export const AddButton = styled.button`
  width: 216px;
  height: 44px;
  background: #7aba56;
  border-radius: 8px;
  color: #ffffff;
  outline: none;
  border-style: hidden;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const AddButtonMobile = styled.button`
  width: 50px;
  height: 44px;
  background: #7aba56;
  border-radius: 8px;
  color: #ffffff;
  outline: none;
  border-style: hidden;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const EditButton = styled.button`
  color: #7aba57;
  font-size: 14px;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 15px;
`;
export const AddText = styled.div`
  font-size: 16px;
  color: #FFFFFF;
  margin-left: 5px;
`;
export const SearchButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`;
export const SearchImage = styled.img`
  width: 25px;
  height: 25px;
`;
export const ActionImage = styled.img`
  margin-right: 5px;
`;
export const ProfileImage = styled.img`
  height: 48px;
  width: 48px;
`;
export const EmptyListMessage = styled.div`
  margin-top: 100px;
  color: red;
  display: flex;
  justify-content: center;
`;
export const WrappedListValue = styled.div`
  width: 40px;
  height: 25px;
  border: 1px solid #d7eff7;
  border-radius: 5px;
  color: #0099cc;
  font-weight: bold;
  background-color: #ebf7fb;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 5px;
`;
export const EditDeleteWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
`;
export const FullNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  font-size: 14px;
`;
export const FullName = styled.span`
    color: #0099CC;
    font-weight: 600;
    font-size: 16px;
    margin-right: 10px;
`;
export const PhoneNo = styled.span`
    color: #000000;
    font-size: 14px;
`;
export const DOBLabel = styled.span`
    color: #808080;
    font-size: 14px;
    margin-right: 10px;
    font-weight: 600;
`;
export const DOBVal = styled.span`
    color: #808080;
    font-size: 14px;
`;
export const PatientContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
`;
export const PatientCard = styled.div`
  width: 330px;
  min-height: 129px;
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;

    @media ${DEVICE.tablet} {
   width: 300px;
  }

`;
export const ProfileSection = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 24px;
`;
export const AddIcon = styled.img`
  height: 16px;
  width: 16px;
`;
export const ProfileImageIcon = styled.img`
`;
export const PatientInfo = styled.div`
`;
export const PhoneNumber = styled.div`
  color: #000000;
  font-family: ${FONT_FAMILY_GENERAL};
  font-size: 14px;
`;
export const Name = styled.div`
  color: #0099CC;
  margin-bottom: 4px;
  font-family: ${FONT_FAMILY_GENERAL};
  font-weight: 500;
  font-size: 16px;
`;
export const ArrowImage = styled.img`
  cursor: pointer;
  float: right;
`;
export const DetailSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  justify-content: space-around;
`;
export const DetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
`;
export const EmailSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
`;
export const EmailIcon = styled.img`
`;
export const Email = styled.div`
  font-size: 16px;
  color: #808080;
  font-family: ${FONT_FAMILY_GENERAL};
`;
export const GenderImage = styled.img`
`;
export const DobImage = styled.img`
`;
export const GenderText = styled.div`
  color:#808080;
  font-size: 16px;
  font-family: ${FONT_FAMILY_GENERAL};
`;
export const DobText = styled.div`
  color:#808080;
  font-size: 16px;
  font-family: ${FONT_FAMILY_GENERAL};
`;
export const DobDate = styled.span`
`;
export const DobYears = styled.span`
  color: #808080;
  font-family: ${FONT_FAMILY_GENERAL};
`;

export const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
`;
export const EmailText = styled.div`
    font-size: 14px !important;
    color: #000000;
    max-width: 210px;   
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;    
`;

export const EmailItem = styled.div`
display: flex;
align-items: start;
gap: 8px;
margin-top: 3px;
`;

export const EmailImage = styled.img`
  height: 14px;
  margin-top: 4px;
`;

export const Tabs = styled.span`
 display: flex; 
 justify-content: center;
 align-items: center;
  background: #ddebed;
  width: 118px;
  height: 49px;
  border-radius:8px;
  margin-right:20px;
  padding:1px 2px;
`;

export const TabButton = styled.span`
padding-top:3px;
 display: flex;
 align-items: center;
 justify-content: center;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  transition: background-color 0.5s;
  width: 53px;
  height: 42px;
  border-radius:5px;
  text-align: center;
  &.active {
     background-color: #FFFFFF;
  }

`;
export const UserName = styled.p`
   color: #0099CC; 
    overflow: hidden;
    text-transform:capitalize;
  margin: 0px;
  font-size: 16px;
  text-overflow: ellipsis;
  max-width: 300px;
  white-space: nowrap;
  font-weigth: 400px;
  cursor:pointer;
`;