import styled from "styled-components";

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 24px;
  align-items: center;
  justify-content: flex-start;
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const Header = styled.div`
  font-size: 24px;
  color: #0099cc;
  flex: 1;
  margin-left: 20px;
`;

export const AddButton = styled.button`
  width: 148px;
  height: 44px;
  background: ${props => props.disabled ? '#A6B8BB' : '#7ABA56'};
  cursor: ${props => props.disabled ? 'inherit' : 'pointer'};
  border-radius: 8px;
  color: #ffffff;
  outline: none;
  border-style: hidden;
  font-size: 16px;
  margin-right: 20px;
`;

export const ModalBody = styled.div`
  margin: 24px;
  margin-bottom: 0;
  position: relative;
  flex: 1;
  overflow-y: auto;
`;

export const Section = styled.div`
    padding: 24px;
    margin-bottom: 30px;
    background-color: #F5FBFC;
    border-radius: 8px;
`;

export const UploadBoxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
`

export const DragText = styled.div`
    font-size: 13px;
    color: #A6B8BB;
`

export const PdfText = styled.div`
    font-size: 20px;
    color: #0099CC;
`

export const FileName = styled.div`
    font-size: 16px;
    color: #000000;
    text-align: left;
    padding: 0 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 85%;
`

export const SectionTitle = styled.div`
    font-size: 20px;
    color: #000000;
    margin-bottom: 26px;
    flex: 1;
`;

export const UploadBox = styled.div`
    min-width: 214px;
    max-width: 214px;
    height: 272px;
    border: 1px solid #D7EFF7;
    background-color: #EBF7FB;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: #A6B8BB;
    margin: 0 24px;
    cursor: pointer;
`;

export const InnerUploadContainer = styled.div`
    width: 182px;
    height: 182px;
    border: 1px solid #D7EFF7;
    background-color: #EBF7FB;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const FileUploadBox = styled.div`
    position: relative;
    width: 214px;
    height: 272px;
    border: 1px solid #D7EFF7;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    font-size: 13px;
    margin-right: 24px;
    color: #000000;
`;

export const CrossIcon = styled.div`
    width: 26px;
    height: 26px;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    background-color: #CC0000;
    color: #ffffff;
    font-weight: 600;
    text-transform: lowercase;
    font-size: 16px;
    text-align: center;
    border-radius: 12px;
`;

export const UploadButton = styled.button`
    width: 162px;
    height: 35px;
    background-color: #7ABA56;
    border-radius: 8px;
    color: #ffffff;
    outline: none;
    border-style: hidden;
    font-size: 14px;
    cursor: pointer;
`;

export const CloseButton = styled.img`
  cursor: pointer;
  display: block;
  max-width: 100%;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
`;