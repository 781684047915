import { createSlice } from "@reduxjs/toolkit";
import {
  getRemindersAction
} from "../actions";

const initialState = {
  isLoading: null,
  reminders: null,
};

const remindersSlice = createSlice({
  name: "reminders",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    //get reminders
    builder.addCase(getRemindersAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getRemindersAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.reminders = payload?.data;
    });
    builder.addCase(getRemindersAction.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const remindersSelector = (state) => state.reminders;
export default remindersSlice.reducer;
