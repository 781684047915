import styled from "styled-components";
import { styled as muiStyled } from '@mui/material/styles';
import {
  COLOR_PRIMARY,
  COLOR_VARIANT,
  DEVICE,
  FONT_FAMILY_GENERAL,
} from "../../utilities/constants";

export const LoginContainer = styled.div({
  backgroundColor: "#0099CC",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: FONT_FAMILY_GENERAL,
});

export const LoginLogo = styled.div`
  margin-bottom: 60px;
  @media ${DEVICE.laptopL} {
    margin-bottom: 30px;
    img {
      width: 90%;
    }
  }
`;

export const LoginForm = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  overflow: hidden;
  justify-content: center;

  @media ${DEVICE.laptopL} {
    justify-content: center;
    width: 90%;
  }
`;

export const LoginFormRight = styled.div`
  background-color: #ffffff;
  padding: 60px 30px;
  @media ${DEVICE.tablet} {
    border-radius: 12px;
  }
  @media ${DEVICE.tablet} {
    width: 50%;
    padding: 20px;
  }
  @media ${DEVICE.mobileL} {
    width: 70%;
    padding: 20px;
  }
  @media ${DEVICE.mobileM} {
    width: 80%;
    padding: 20px;
  }
  @media ${DEVICE.mobileS} {
    width: 80%;
    padding: 20px;
  }
`;

export const FormMessage = styled.div({
  color: "#0099CC",
  display: "flex",
  alignItems: "center",
  marginBottom: "30px",
  fontSize: "28px",
  fontFamily: FONT_FAMILY_GENERAL,
});

export const FormFieldSection = styled.div({
  display: "flex",
  flexDirection: "column",
  marginBottom: "30px",
});

export const FormLabel = styled.div({
  color: "#808080",
  fontSize: "16px",
  textAlign: "left",
  marginBottom: "10px",
});

export const FormFieldRow = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

export const CustomSelect = styled.select({
  fontFamily: FONT_FAMILY_GENERAL,
  backgroundColor: "#EBF7FB",
  fontSize: "16px",
  color: "#000000",
  border: "none",
  borderRadius: "8px",
  height: "44px",
  flex: 1,
  width: "100%",
  maxWidth: "80px",
  minWidth: "30px",
  paddingLeft: "10px",
  marginRight: "10px",
  option: {
    width: "30px",
  },
  [`@media ${DEVICE.mobileL}`]: {
    fontSize: "12px",
  },
});

export const CustomInput = styled.input({
  fontFamily: FONT_FAMILY_GENERAL,
  backgroundColor: "#EBF7FB",
  fontSize: "16px",
  color: "#000000",
  border: "none",
  borderRadius: "8px",
  height: "44px",
  flex: 1,
  width: "100%",
  minWidth: "20px",
  paddingLeft: "10px",
  [`@media ${DEVICE.mobileL}`]: {
    fontSize: "12px",
  },
});

export const CustomButton = styled.input({
  backgroundColor: COLOR_VARIANT,
  border: "none",
  textDecoration: "none",
  height: "53px",
  width: "100%",
  cursor: "pointer",
  color: "#FFF",
  borderRadius: "8px",
  fontSize: "16px",
});

export const ActionText = styled.div({
  color: "#7ABA56",
  fontSize: "14px",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  paddingLeft: "10px",
  ":hover": {
    textDecoration: "underline",
  },
});

export const TCText = styled.div({
  textAlign: "left",
  fontSize: "13px",
  color: "#808080",
  marginBottom: "20px",
});

export const TCLink = styled.a({
  textDecoration: "none",
  fontSize: "13px",
  color: COLOR_PRIMARY,
  marginLeft: "5px",
});

export const LoginFormLeft = styled.div`
flex-basis: 55%;
background-color: #ebf7fb;
display: flex;
flex-direction: column;
padding: 50px;
align-items: flex-start;
@media ${DEVICE.tablet} {
  display: none;
}
`;

export const ImageBanner = styled.div`
  background-color: #EBF7FB;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TextDescription = styled.div`
  margin-top: 40px;
  color: #808080;
  font-size: 16px;
  text-align: left;
`;

export const CtaLink = styled.a`
  color: #0099CC;
  font-size: 16px;
  text-decoration: none;
  margin-top: 40px;
`;

export const WelcomeMessage = styled.div`
  margin-left: 30px;
  color: #0099CC;
  font-size: 20px;
  text-align: left;
`;

export const InfoContainer = muiStyled('div')({
  backgroundColor: '#EBF7FB',
  border: '2px solid #0099CC',
  borderRadius: '8px',
  padding: '18px 16px',
  margin: '12px 0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  maxWidth: '520px',
  height: '32px'
});

export const IconTextContainer = muiStyled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '10px'
});

export const LoginByContainer = styled.div`
  display: flex;
  flex-direction: column;

`;

export const LoginByText = styled.div`
  font-size: 16px;
  color: #000000;
  margin-bottom: 40px;
`;

export const BlackRightArrowImg = styled.img`
`;

export const MobileIconImg = styled.img`
`;

export const EmailIconImg = styled.img`
`;

export const LoginFormContainer = styled.div`
`;
export const BackButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
  cursor: pointer;
`;
export const BackArrowImg = styled.img`
  width: 32px;
  height: 32px;
`;
export const BackText = styled.div`
  color: #000000;
  font-size: 16px;
`;