import styled from "styled-components";
import {
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
} from "../../../utilities/constants";

export const Container = styled.div`
    margin: 40px;
    padding: 24px;
    border-radius: 12px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 32px #2477b314;
    opacity: 1;
    min-height: calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT});
    margin-bottom: 0;
`;
export const TopHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 24px;
    color: #0099CC;
    margin-bottom: 28px;
`;
export const ArrowBackImage = styled.img`
    cursor: pointer;
`;
export const TypeContainer = styled.div`
    background-color: #F5FBFC;
    border-radius: 8px;
    padding: 28px 24px;
`;
export const TypeText = styled.div`
    color: #000000;
    font-size: 20px;
    margin-bottom: 28px;
`;
export const TypeList = styled.div`
    border-radius: 8px;
    background-color: #FFFFFF;
`;
export const TypeListText = styled.div`
    color: #000000;
    font-size: 14px;
    padding: 28px 24px;
    border-bottom: 1px solid #D7EFF7;
    cursor: pointer;
`;
export const NextArrowIcon = styled.img`
    cursor: pointer;
    width: 24px;
    height: 24px;
    float:right
`;