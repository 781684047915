import styled from "styled-components";
import {
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
} from "../../../../utilities/constants"; 
import { Typography } from "@mui/material";
export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
`;
export const CloseButton = styled.img`
  cursor: pointer;
  display: block;
  max-width: 100%;
`;
export const ModalHeader = styled(Typography)`
  color: #0099CC;
  font-size: 24px !important;
  margin: 28px 0 0 30px !important;
  font-weight: 800;
`;

export const ModalBody = styled.div`
  margin: 25px 0 0 35px;
  position: relative;
  flex: 1;
  overflow-y: auto;
`;

export const DeleteText = styled.div`
  font-size: 16px;
  color: #000000;
  font-weight: 400;
`


export const SaveButton = styled.button`
  background-color: #7aba56;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
  padding: 11px 56px;
  margin: 18px 0px;
  @media(max-width:600px){
    padding: 11px 25px;
  }
`;

export const NoButton = styled.button`
  background-color: transparent;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  border:1px solid #7aba56;
  color: #7aba56;
  font-size: 14px;
  cursor: pointer;
  padding: 11px 56px;
  margin: 18px 10px;
  @media(max-width:600px){
    padding: 11px 25px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
`;