import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { hemebotSelector } from "../../../store/slice/hemebot.slice";
import { Widget, addResponseMessage } from '@ryaneewx/react-chat-widget';

import '@ryaneewx/react-chat-widget/lib/styles.css';
import '../../../index.css';

const ChatWidgetComponent = ({ handleNewUserMessage, userSentMessage, assessmentRun }) => {
    const answer = useSelector(hemebotSelector) || {};
    const lastAddedResponseRef = useRef(null);

    useEffect(() => {
        if ((userSentMessage || assessmentRun) && answer?.chatbot?.content && answer?.chatbot?.content !== lastAddedResponseRef.current) {
            addResponseMessage(answer?.chatbot?.content);
            lastAddedResponseRef.current = answer?.chatbot?.content;
        }
    }, [answer?.chatbot?.content, userSentMessage, assessmentRun]);

    return (
        <Widget
            handleNewUserMessage={handleNewUserMessage}
            title="Hi. I am HemeBot"
            subtitle="A virtual assistant to help you answer your health questions"
        />
    );
}

export default ChatWidgetComponent;