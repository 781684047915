import { Breadcrumbs, Link, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const Breadcrumb = ({ links }) => {
  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
      {links.map((link, index) => {
        const isLast = index === links.length - 1;
        return isLast ? (
          <Typography
            key={`breadcrumb-link-${index}`}
            sx={{ fontSize: "13px" }}
            color="textPrimary"
          >
            {link.label}
          </Typography>
        ) : (
          <Link
            key={`breadcrumb-link-${index}`}
            color="info.main"
            href={link.href}
            onClick={link.onClick}
            sx={{ textDecoration: "none", fontSize: "13px", cursor: "pointer"}}
          >
            {link.label}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
