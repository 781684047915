import { Box, FormControl, MenuItem, Select } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const StatusDropdown = ({
  handleChange,
  selectedStatus,
  dropdownOptions=[
    { label: "Available", value: "AVAILABLE" },
    { label: "Restricted", value: "RESTRICTED" },
  ],
  disabled = false,
}) => {
  return (
    <>
      <FormControl style={{ width: "inherit"}}>
        <Select
          value={selectedStatus}
          onChange={handleChange}
          IconComponent={KeyboardArrowDownIcon}
          sx={{
            paddingRight: 1,
            paddingTop: 0,
            pointerEvents: disabled ? "none" : "auto",
            height: "45px",
            color: selectedStatus !== "RESTRICTED" ? "#7ABA56" : "#CC8552",
            "& .MuiOutlinedInput-notchedOutline": {
              border: `2px solid  ${
                selectedStatus !== "RESTRICTED" ? "#7ABA56" : "#CC8552"
              }`,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: selectedStatus !== 'RESTRICTED' ? '#7ABA56' : '#CC8552',
            },
            "& .MuiSvgIcon-root": {
              background:
                selectedStatus !== "RESTRICTED" ? "#7ABA56" : "#CC8552",
              borderRadius: 1,
              color: "#FFF",
            },
          }}
        >
          {dropdownOptions.map((option) => (
            <MenuItem key={`dropdown-${option.value}`} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default StatusDropdown;
