import styled from "styled-components";

export const Container = styled.div`
`;
export const TypeContainer = styled.div`
`;
export const TopHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 24px;
    color: #0099CC;
    margin-bottom: 28px;
`;
export const ArrowBackImage = styled.img`
    cursor: pointer;
`;
export const TypeText = styled.div`
    color: #000000;
    font-size: 16px;
    margin-bottom: 28px;
`;
export const TypeList = styled.div`
    border-radius: 8px;
    border: 1px solid #0099CC;
    border-radius: 12px;
    background-color: #FFFFFF;
`;
export const TypeListText = styled.div`
    color: #000000;
    font-size: 14px;
    padding: 28px 24px;
    border-bottom: 1px solid #D7EFF7;
    cursor: pointer;
`;
export const NextArrowIcon = styled.img`
    cursor: pointer;
    width: 24px;
    height: 24px;
    float:right
`;