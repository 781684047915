import { createSlice } from "@reduxjs/toolkit";
import { getUploadRecordsAction } from "../actions";

const initialState = {
  records: [],
  uppyResponse: null,
  splitRecords: {}, // Initialize splitRecords as an empty object
  isLoading: false,
};

const recordsUppySlice = createSlice({
  name: "records",
  initialState,
  reducers: {
    setRecords(state, action) {
      const { records, uppyResponse } = state; // Get current state
      const updatedRecords = action.payload.map((record) => {
        // Find the matching file in uppyResponse.uploadedFiles by file_name and converted_file_name
        const matchedFile = uppyResponse?.uploadedFiles?.find(
          (file) => file.file_name === record.converted_file_name
        );

        // If a match is found, merge the record with the uploaded file's details
        if (matchedFile) {
          return {
            ...record,
            file_id: matchedFile.file_id,
            file_name: matchedFile.file_name,
            mimetype: matchedFile.mimetype,
            file_size: matchedFile.file_size,
          };
        }

        // If no match is found, return the record as-is
        return record;
      });

      // Update the state with the newly merged records
      state.records = updatedRecords;
    },
    setUppyResponse(state, action) {
      state.uppyResponse = action.payload;
    },
    updateSplitRecords(state, action) {
      const { originalFileName, splitFiles } = action.payload;

      if (!state.splitRecords) {
        state.splitRecords = {};
      }

      if (!state.splitRecords[originalFileName]) {
        state.splitRecords[originalFileName] = [];
      }

      state.splitRecords[originalFileName] = [
        ...state.splitRecords[originalFileName],
        ...splitFiles,
      ];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUploadRecordsAction.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUploadRecordsAction.fulfilled, (state, { payload }) => {
      state.records = payload;
      state.isLoading = false;
    });
    builder.addCase(getUploadRecordsAction.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { setRecords, setUppyResponse, updateSplitRecords } = recordsUppySlice.actions;

export const recordsUppySelector = (state) => ({
  records: state.records.records,
  splitRecords: state.records.splitRecords,
  uppyResponse: state.records.uppyResponse,
});

export default recordsUppySlice.reducer;
