import { API_ENDPOINT } from "../utilities/constants";
import { apiClient } from "./api-client";

const getPatientProfile = async (id) => {
  try {
    const url = `${API_ENDPOINT.PATIENT}/profile/${id}`;
    const response = await apiClient.get(url);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export {
    getPatientProfile,
};