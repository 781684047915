import styled from "styled-components";
import { FONT_FAMILY_GENERAL } from "../../utilities/constants";

export const Container = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "flexStart",
  justifyContent: "center",
  fontFamily: FONT_FAMILY_GENERAL,
});

export const TitleText = styled.div`
  font-size: 16px;
  color: #808080;
  margin-bottom: 16px;
`;

export const SubTitleText = styled.div`
  font-size: 14px;
  color: #fff;
  margin-top: 100px;
`;

export const CountryPicker = styled.div`
  background-color: #fff;
  height: auto;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

export const CountryListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  padding: 9px 6px;
  &:last-child {
    border-bottom: none;
  }
`;
export const LeftSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
export const RightSection = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
`;
export const CountryImage = styled.img`
  width: 20px;
  height: 20px;
`;
export const CountryName = styled.div`
  font-size: 14px;
  color: #333;
  margin-left: 10px;
`;
export const BlackRightArrowImg = styled.img`
`;
export const BackButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
  cursor: pointer;
`;
export const BackArrowImg = styled.img`
  width: 32px;
  height: 32px;
`;
export const BackText = styled.div`
  color: #000000;
  font-size: 16px;
`;