import { API_ENDPOINT } from "../utilities/constants";
import { apiClient } from "./api-client";

const addPatient = async (data) => {
  try {
    const response = await apiClient.post(API_ENDPOINT.PATIENT, data, false);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

const getPatients = async () => {
  try {
    const url = `${
      API_ENDPOINT.PATIENTS
    }?page_number=1&limit=50`;
    const response = await apiClient.get(url);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};
const getPatientsByNumber = async ({ payload }) => {
  try {
    const response = await apiClient.post(
      `${API_ENDPOINT.PATIENT}/search`,
      payload,
      false
    );
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export {
  addPatient,
  getPatientsByNumber,
  getPatients,
};
